import { t } from 'i18next';
import { CommonFlex, CommonText, TableText } from 'styledComponents/common';
import { TableItem } from 'styledComponents/invoice';
import {
  UpdateRequestChangeLogContainer,
  UpdateRequestChangeLogTableContainer,
} from 'styledComponents/members';

const UpdateRequestUserData = ({ title, type, newData, oldData }) => {
  return (
    <CommonFlex direction="column" alignItems="left" width="80%">
      <CommonText color="#000000" fontWeight="600" fontSize={'16px'}>
        {t(title)}
      </CommonText>
      <UpdateRequestChangeLogTableContainer>
        <CommonFlex direction="column" alignItems="left" gap="0px">
          <UpdateRequestChangeLogContainer
            type={type}
            hasChanged={
              `${newData?.first_name} ${newData.last_name}`.trim() !==
              `${oldData.first_name} ${oldData.last_name}`.trim()
            }
          >
            <TableItem buttonPadding>
              <TableText fontSize={'11px'}>{t('member_name')}</TableText>
            </TableItem>{' '}
            <TableItem buttonPadding>
              <TableText color="#434343" fontSize={'11px'}>
                {type === 'new'
                  ? `${newData?.first_name} ${newData?.last_name}`.trim()
                  : `${oldData?.first_name} ${oldData?.last_name}`.trim()}
              </TableText>
            </TableItem>
          </UpdateRequestChangeLogContainer>
          <UpdateRequestChangeLogContainer
            type={type}
            hasChanged={newData?.user_code !== oldData?.user_code}
          >
            <TableItem buttonPadding>
              <TableText fontSize={'11px'}>{t('user_id')}</TableText>
            </TableItem>{' '}
            <TableItem buttonPadding>
              <TableText color="#434343" fontSize={'11px'} fontWeight="400">
                {type === 'new' ? newData?.user_code : oldData?.user_code}
              </TableText>
            </TableItem>
          </UpdateRequestChangeLogContainer>
          <UpdateRequestChangeLogContainer
            type={type}
            hasChanged={newData?.office?.id !== oldData?.office?.id}
          >
            <TableItem buttonPadding>
              <TableText fontSize={'11px'}>{t('office')}</TableText>
            </TableItem>{' '}
            <TableItem buttonPadding>
              <TableText color="#434343" fontSize={'11px'} fontWeight="400">
                {type === 'new' ? newData?.office?.name : oldData?.office?.name}
              </TableText>
            </TableItem>
          </UpdateRequestChangeLogContainer>
          <UpdateRequestChangeLogContainer
            type={type}
            hasChanged={newData?.email !== oldData?.email}
          >
            <TableItem buttonPadding>
              <TableText fontSize={'11px'}>{t('email')}</TableText>
            </TableItem>{' '}
            <TableItem buttonPadding>
              <TableText color="#434343" fontSize={'11px'} fontWeight="400">
                {type === 'new' ? newData?.email : oldData?.email}
              </TableText>
            </TableItem>
          </UpdateRequestChangeLogContainer>
          <UpdateRequestChangeLogContainer
            type={type}
            hasChanged={newData?.role !== oldData?.role}
          >
            <TableItem buttonPadding>
              <TableText fontSize={'11px'}>{t('role')}</TableText>
            </TableItem>{' '}
            <TableItem buttonPadding>
              <TableText color="#434343" fontSize={'11px'} fontWeight="400">
                {type === 'new' ? newData?.role : oldData?.role}
              </TableText>
            </TableItem>
          </UpdateRequestChangeLogContainer>
          <UpdateRequestChangeLogContainer
            type={type}
            hasChanged={newData?.phone !== oldData?.phone}
          >
            <TableItem buttonPadding>
              <TableText fontSize={'11px'}>{t('contact_number')}</TableText>
            </TableItem>{' '}
            <TableItem buttonPadding>
              <TableText color="#434343" fontSize={'11px'} fontWeight="400">
                {type === 'new' ? newData?.phone : oldData?.phone}
              </TableText>
            </TableItem>
          </UpdateRequestChangeLogContainer>
        </CommonFlex>
      </UpdateRequestChangeLogTableContainer>
    </CommonFlex>
  );
};

export default UpdateRequestUserData;
