import { t } from 'i18next';
import { TaskStatusOptions } from 'modules/constants';
import { CommonFlex } from 'styledComponents/common';
import { DashboardCardText } from 'styledComponents/dashboard';

import { SmallCardContainer } from './cardStyles';

const TaskSummaryCard = ({ title, value, history }) => {
  const statusNames = TaskStatusOptions?.filter(status => status.value)?.map(
    status => status.key,
  );
  return (
    <SmallCardContainer
      alignContent="center"
      cardType={title}
      cursor={
        !(
          title === 'not_working_today' ||
          title === 'working_today' ||
          title === 'total_employees'
        ) && 'pointer'
      }
      onClick={() => {
        if (title === 'all_tasks') {
          history.push(`/user/tasks?status=all_status`);
        } else if (statusNames?.includes(title)) {
          history.push(`/user/tasks?status=${title}`);
        } else {
          if (title === 'pending_approval')
            history.push(`/user/task-approval?tab=submitted`);
          else if (title === 'approved' || title === 'rejected')
            history.push(`/user/task-approval?tab=${title}`);
        }
      }}
    >
      <CommonFlex direction="column" margin="auto">
        <DashboardCardText fontSize={'40px'} fontWeight={600}>
          {value}
        </DashboardCardText>
        <DashboardCardText fontSize={'20px'} fontWeight={600}>
          {t(title)}
        </DashboardCardText>
      </CommonFlex>
    </SmallCardContainer>
  );
};

export default TaskSummaryCard;
