import { Link } from 'react-router-dom';
import { Card, Progress } from 'reactstrap';
import styled from 'styled-components';

export const DashboardModeSelect = styled.div`
  width: 185px;
  display: flex;
  justify-content: space-between;
  height: 34px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 6px;
  background: #fff;
  margin-left: 15px;
  padding: 3px;
`;

export const DashboardCardText = styled.div`
  margin: ${({ margin }) => margin || `auto 0`};
  font-size: ${({ fontSize, $label, label, theme }) =>
    fontSize
      ? fontSize
      : $label || label
      ? theme.font.otherLabel.size
      : theme.font.pageText.size};
  font-weight: ${({ fontWeight, $title, title, $label, label, theme }) =>
    fontWeight
      ? fontWeight
      : $label || label
      ? theme.font.otherLabel.weight
      : $title || title
      ? theme.fontWeights.semibold
      : theme.font.pageText.weight};
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: ${({ tabFontSize }) => tabFontSize};
  }
  @media screen and (max-width: 500px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

//dashboard card
export const ReportCard = styled(Card)`
  border: 1px solid #ccebda;
  border-radius: 10px;
  background: #fff;
  box-shadow: none;
  margin-bottom: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap ?? '25px'};
`;
//dashboard card
export const DashboardCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 10px;
  background: #fff;
  box-shadow: none;
  margin-bottom: 0;
`;
export const DashboardCardTitle = styled.span`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.textMain};
`;
export const DashboardCardSubTitle = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.main.labelText};
  margin-left: 15px;
`;
export const LinkToReport = styled(Link)`
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ type, theme }) =>
    type && type === 'success'
      ? theme.colors.main.success
      : type && type === 'error'
      ? theme.colors.main.error
      : type && type === 'blue'
      ? theme.colors.main.blue
      : type && type === 'warning'
      ? theme.colors.main.warning
      : theme.colors.main.primary};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.hover.primary};
    text-decoration: none;
  }
`;

//small card
export const DashboardSmallCard = styled(Card)`
  height: 13.5rem;
  overflow: hidden !important;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 10px;
  background: #fff;
  margin-bottom: 0;
  box-shadow: none;
`;
export const SmallCardLabel = styled.span`
  font-size: ${({ fontSize }) => fontSize || `14px`};
  color: ${({ theme }) => theme.colors.main.labelText};
`;
export const SmallCardPercentage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 5px;
  font-size: 11px;
  background: ${({ status, theme }) =>
    status === 'increase'
      ? theme.colors.main.success
      : status === 'decrease'
      ? theme.colors.main.error
      : `#e8eaee`};
  border-radius: 10px;
  color: #fff;
`;
export const SmallCardContent = styled.span`
  font-size: 22px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.textMain};
  margin-top: ${({ marginTop }) => marginTop || '0'};
`;

//activity card
export const ActivityCardDate = styled.div`
  background: ${({ theme, bgColor }) => bgColor || theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 12px;
  padding: 12px 10px;
  color: ${({ color, theme }) => color || theme.colors.main.labelText};
  text-align: center;
  font-size: 12px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.main.primary};
    border-color: ${({ theme }) => theme.colors.main.primary};
    color: ${({ theme }) => theme.colors.main.white};
  }
  @media (max-width: 1400px) {
    padding: 8px 8px;
  }
`;

//top projects card
export const PieChartContainer = styled.div`
  width: 100%;
  // background: #f7f8f9;
  // border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 6px;
  // padding: 15px 15px;
  margin-top: 15px;
`;

export const ColorCircle = styled.div`
  width: ${({ size }) => size || '14px'};
  height: ${({ size }) => size || '14px'};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  background: ${({ background, theme }) =>
    background || theme.colors.main.primary};
`;

export const ColorSquare = styled.div`
  width: ${({ size }) => size || '14px'};
  height: ${({ size }) => size || '14px'};
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.borderRadii.square};
  background: ${({ background, theme }) =>
    background || theme.colors.main.primary};
`;

export const ImageCircle = styled.div`
  width: ${({ size }) => size || '40px'};
  height: ${({ size }) => size || '40px'};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  flex-shrink: 0;
  background: ${({ background, theme }) =>
    background || theme.colors.main.primary};
  position: relative;
  img {
    position: absolute;
    inset: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadii.circle};
  }
`;

//timesheet card
export const TimesheetTime = styled.div`
  width: 100%;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ borderColor, theme }) => borderColor || theme.colors.border.secondary};
  margin-top: 10px;
  background: ${({ background }) => background || '#fff'};
  border-radius: 8px;
`;

export const EmployeeActivity = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: ${({ direction }) => direction || `column`};
  align-items: ${({ alignItems }) => alignItems || `center`};
  text-align: ${({ textAlign }) => textAlign ?? 'initial'};
  justify-content: ${({ justifyContent }) => justifyContent || `center`};
  // background: ${({ theme }) => theme.colors.main.successLight};
  background: ${({ active }) => (active ? `#CCEBDA` : `#F3F5F9`)};
  border: 1px solid ${({ active }) => (active ? `#41CB8F` : `#CDE2EA`)};
  padding: ${({ padding }) => padding || `50px 10px`};
  border-radius: ${({ borderRadius }) => borderRadius || `6px`};
  font-size: ${({ fontSize }) => fontSize || `14px`};
  color: ${({ theme }) => theme.colors.main.textMain};
  position: relative;
  &:before {
    display: inline-flex;
    content: '';
    width: 16px;
    height: 16px;
    background: ${({ active }) => (active ? `#41CB8F` : `#8DADBA`)};
    border: 2px solid #ffffff;
    border-radius: 50%;
    margin-bottom: 15px;
  }
`;
