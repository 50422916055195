import styled from 'styled-components';

import NoDataArtwork from '../../assets/img/common/no_data_artwork.svg';

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding || '10vh 0'};
`;
const ArtworkImage = styled.img`
  height: ${({ imageHeight }) => imageHeight || '194px'};
  width: ${({ imageWidth }) => imageWidth || '214px'};
`;
const NoDataTitle = styled.div`
  font-size: ${({ titleFontSize }) => titleFontSize || '18px'};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin-top: ${({ marginTop, subtitle }) =>
    marginTop ? marginTop : subtitle ? '60px' : '30px'};
  color: ${({ color, theme }) => color || theme.colors.main.textSecondary};
  text-align: ${({ textAlign }) => textAlign};
`;
const NoDataSubtitle = styled.div`
  font-size: ${({ subTitleFontSize }) => subTitleFontSize || '14px'};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ color, theme }) => color || theme.colors.main.labelText};
  margin-top: 10px;
`;

const NoDataComponent = props => {
  const {
    title,
    subtitle,
    padding,
    imageSrc,
    imageHeight,
    imageWidth,
    titleFontSize,
    subTitleFontSize,
    textMarginTop,
    textAlign,
    color,
  } = props;
  return (
    <NoDataContainer padding={padding}>
      <ArtworkImage
        src={imageSrc || NoDataArtwork}
        alt=""
        imageWidth={imageWidth}
        imageHeight={imageHeight}
      />
      <NoDataTitle
        subtitle={subtitle}
        titleFontSize={titleFontSize}
        color={color}
        marginTop={textMarginTop}
        textAlign={textAlign}
      >
        {title || 'There is no data to be displayed!'}
      </NoDataTitle>
      {subtitle && (
        <NoDataSubtitle subTitleFontSize={subTitleFontSize} color={color}>
          {subtitle}
        </NoDataSubtitle>
      )}
    </NoDataContainer>
  );
};

export default NoDataComponent;
