import {
  FIRST_LOGIN_PASSWORD_CHANGE_REQUEST,
  LOGIN_PROFILE_CREATED_RESET,
  LOGIN_REDIRECT_RESET,
  LOGIN_REQUEST,
} from '../../modules/constants';

// ------------------------------------
// Actions
// ------------------------------------
// actions will call form index.js file
export function loginRequest(value) {
  return {
    type: LOGIN_REQUEST,
    payload: value,
  };
}
export function firstLoginPasswordChangeRequest(value) {
  return {
    type: FIRST_LOGIN_PASSWORD_CHANGE_REQUEST,
    payload: value,
  };
}

export function loginRedirectReset() {
  return {
    type: LOGIN_REDIRECT_RESET,
  };
}

export function loginProfileCreatedReset() {
  return {
    type: LOGIN_PROFILE_CREATED_RESET,
  };
}

export const actions = {
  loginRequest,
  firstLoginPasswordChangeRequest,
  loginRedirectReset,
  loginProfileCreatedReset,
};
