import {
  //Get project
  GET_PROJECT_LIST,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_LIST_FAILURE,
  GET_PROJECT_SHORT_LIST,
  GET_PROJECT_SHORT_LIST_SUCCESS,
  GET_PROJECT_SHORT_LIST_FAILURE,
  GET_PROJECT_DETAILS,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_FAILURE,
  GET_PROJECT_EXPENSES,
  GET_PROJECT_EXPENSES_SUCCESS,
  GET_PROJECT_EXPENSES_FAILURE,
  GET_PROJECT_BUDGET_AND_BILLABLE,
  GET_PROJECT_BUDGET_AND_BILLABLE_SUCCESS,
  GET_PROJECT_BUDGET_AND_BILLABLE_FAILURE,
  GET_PROJECT_YEARLY_EXPENSES,
  GET_PROJECT_YEARLY_EXPENSES_SUCCESS,
  GET_PROJECT_YEARLY_EXPENSES_FAILURE,
  //create project
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_REQUEST_SUCCESS,
  CREATE_PROJECT_REQUEST_FAILURE,
  DUPLICATE_PROJECT_REQUEST,
  DUPLICATE_PROJECT_REQUEST_SUCCESS,
  DUPLICATE_PROJECT_REQUEST_FAILURE,
  CLEAR_CREATED_PROJECT,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_REQUEST_SUCCESS,
  UPDATE_PROJECT_REQUEST_FAILURE,
  CLEAR_EDITED_PROJECT,
  CLEAR_PROJECT_ACCESS_DENIED,
  GET_ORGANIZATION_MEMBERS_LIST,
  GET_ORGANIZATION_MEMBERS_LIST_SUCCESS,
  GET_ORGANIZATION_MEMBERS_LIST_FAILURE,
  ADD_TEAM_TO_PROJECT,
  ADD_TEAM_TO_PROJECT_SUCCESS,
  ADD_TEAM_TO_PROJECT_FAILURE,
  ADD_PROJECT_ASSIGNEE,
  ADD_PROJECT_ASSIGNEE_SUCCESS,
  ADD_PROJECT_ASSIGNEE_FAILURE,
  UPDATE_PROJECT_ASSIGNEE,
  UPDATE_PROJECT_ASSIGNEE_SUCCESS,
  UPDATE_PROJECT_ASSIGNEE_FAILURE,
  DELETE_PROJECT_ASSIGNEE,
  DELETE_PROJECT_ASSIGNEE_SUCCESS,
  DELETE_PROJECT_ASSIGNEE_FAILURE,
  GET_PROJECT_ASSIGNEE_LIST,
  GET_PROJECT_ASSIGNEE_LIST_SUCCESS,
  GET_PROJECT_ASSIGNEE_LIST_FAILURE,
  CLEAR_PROJECT_ASSIGNEE_LIST,
  GET_PROJECT_TASK_LIST,
  GET_PROJECT_TASK_LIST_SUCCESS,
  GET_PROJECT_TASK_LIST_FAILURE,
  PROJECT_ADD_TASK,
  PROJECT_ADD_TASK_SUCCESS,
  PROJECT_ADD_TASK_FAILURE,
  PROJECT_EDIT_TASK,
  PROJECT_EDIT_TASK_SUCCESS,
  PROJECT_EDIT_TASK_FAILURE,
  PROJECT_DELETE_TASK,
  PROJECT_DELETE_TASK_SUCCESS,
  PROJECT_DELETE_TASK_FAILURE,
  GET_TASK_ASSIGNEE_LIST,
  GET_TASK_ASSIGNEE_LIST_SUCCESS,
  GET_TASK_ASSIGNEE_LIST_FAILURE,
  ADD_TASK_ASSIGNEE,
  ADD_TASK_ASSIGNEE_SUCCESS,
  ADD_TASK_ASSIGNEE_FAILURE,
  DELETE_TASK_ASSIGNEE,
  DELETE_TASK_ASSIGNEE_SUCCESS,
  DELETE_TASK_ASSIGNEE_FAILURE,
  // Delete Project
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_REQUEST_SUCCESS,
  DELETE_PROJECT_REQUEST_FAILURE,
  // Budget and Billing
  GET_PROJECT_BUDGET,
  GET_PROJECT_BUDGET_SUCCESS,
  GET_PROJECT_BUDGET_FAILURE,
  ADD_PROJECT_BUDGET,
  ADD_PROJECT_BUDGET_SUCCESS,
  ADD_PROJECT_BUDGET_FAILURE,
  UPDATE_PROJECT_BUDGET,
  UPDATE_PROJECT_BUDGET_SUCCESS,
  UPDATE_PROJECT_BUDGET_FAILURE,
  CLEAR_PROJECT_BUDGET,
  GET_PROJECT_BILLING,
  GET_PROJECT_BILLING_SUCCESS,
  GET_PROJECT_BILLING_FAILURE,
  ADD_PROJECT_BILLING,
  ADD_PROJECT_BILLING_SUCCESS,
  ADD_PROJECT_BILLING_FAILURE,
  UPDATE_PROJECT_BILLING,
  UPDATE_PROJECT_BILLING_SUCCESS,
  UPDATE_PROJECT_BILLING_FAILURE,
  CLEAR_PROJECT_BILLING,

  // invoices
  GET_PROJECT_INVOICES,
  GET_PROJECT_INVOICES_SUCCESS,
  GET_PROJECT_INVOICES_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../modules/constants";
import { createNotification } from "../../modules/notificationManager";

const noInternetMsg =
  "No Internet Connection! Please connect to the internet and try again";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const getProjectListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getProjectListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      isLoading: false,
      totalProjectCount: data.data.count,
      projectPageSize: data.data.page_size,
      projectList: data.data.results,
    };
  }
  return {
    ...state,
    isLoading: false,
    totalProjectCount: null,
    projectPageSize: null,
    projectList: [],
  };
};
export const getProjectListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    totalProjectCount: null,
    projectPageSize: null,
    projectList: [],
  };
};

export const getProjectShortListRequestHandler = (state, action) => {
  return {
    ...state,
    shortListIsLoading: true,
  };
};
export const getProjectShortListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      shortListIsLoading: false,
      projectShortList: data.data.results,
    };
  }
  return {
    ...state,
    shortListIsLoading: false,
    projectShortList: [],
  };
};
export const getProjectShortListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    shortListIsLoading: false,
    projectShortList: [],
  };
};

export const getProjectDetailsRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getProjectDetailsRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      isLoading: false,
      projectDetails: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    projectDetails: null,
  };
};
export const getProjectDetailsRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response && response.status === 403) {
    return {
      ...state,
      isLoading: false,
      accessDenied: true,
      projectDetails: null,
    };
  }
  return {
    ...state,
    isLoading: false,
    projectDetails: null,
  };
};

export const getProjectExpensesRequestHandler = (state, action) => {
  return {
    ...state,
    projectExpensesIsLoading: true,
  };
};
export const getProjectExpensesRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      projectExpensesIsLoading: false,
      projectExpenses: data.data,
    };
  }
  return {
    ...state,
    projectExpensesIsLoading: false,
    projectExpenses: null,
  };
};
export const getProjectExpensesRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response && response.status === 403) {
    return {
      ...state,
      projectExpensesIsLoading: false,
      accessDenied: true,
    };
  }
  return {
    ...state,
    projectExpensesIsLoading: false,
    projectExpenses: null,
  };
};

export const getProjectBudgetAndBillableRequestHandler = (state, action) => {
  return {
    ...state,
    projectBudgetAndBillableIsLoading: true,
  };
};
export const getProjectBudgetAndBillableRequestSuccessHandler = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      projectBudgetAndBillableIsLoading: false,
      projectBudgetAndBillable: data.data,
    };
  }
  return {
    ...state,
    projectBudgetAndBillableIsLoading: false,
    projectBudgetAndBillable: null,
  };
};
export const getProjectBudgetAndBillableRequestFailureHandler = (
  state,
  action
) => {
  const {
    payload: { response },
  } = action;
  return {
    ...state,
    projectBudgetAndBillableIsLoading: false,
    projectBudgetAndBillable: null,
  };
};

export const getProjectYearlyExpensesRequestHandler = (state, action) => {
  return {
    ...state,
    projectExpensesIsLoading: true,
  };
};
export const getProjectYearlyExpensesRequestSuccessHandler = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      projectExpensesIsLoading: false,
      yearlyExpenses: data.data,
    };
  }
  return {
    ...state,
    projectExpensesIsLoading: false,
    yearlyExpenses: null,
  };
};
export const getProjectYearlyExpensesRequestFailureHandler = (
  state,
  action
) => {
  return {
    ...state,
    projectExpensesIsLoading: false,
    yearlyExpenses: null,
  };
};

export const createProjectRequestHandler = (state, action) => {
  return {
    ...state,
    createProjectIsLoading: true,
  };
};
export const createProjectRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification("success", "Project created successfully", 2000);
    return {
      ...state,
      createProjectIsLoading: false,
      createdProjectId: data.data.id,
    };
  }
  return {
    ...state,
    createProjectIsLoading: false,
    createdProjectId: null,
  };
};
export const createProjectRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (response.data.code) {
          switch (response.data.code[0]) {
            case "Ensure this field has no more than 10 characters.":
              errorMsg = "Project code can have maximum 10 characters";
              break;
            default:
              errorMsg = response.data.code[0];
              break;
          }
        } else {
          errorMsg = "Failed to create project. Please try again.";
        }
        break;
      default:
        errorMsg = "Failed to create project. Please try again.";
    }
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }
  return {
    ...state,
    createProjectIsLoading: false,
    createdProjectId: null,
  };
};
export const clearCreatedProjectRequestHandler = (state, action) => {
  return {
    ...state,
    createdProjectId: null,
  };
};

export const duplicateProjectRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    duplicateProjectIsLoading: true,
    duplicateProjectId: payload.project_id,
  };
};
export const duplicateProjectRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, project_id },
  } = action;
  if (data && data.status === 200) {
    createNotification("success", "Project duplicated successfully", 2000);
    const projects = JSON.parse(JSON.stringify(state.projectList));
    if (projects && projects.length > 0) {
      const projIndex = projects.findIndex(
        (project) => project.id === project_id
      );
      if (projIndex > -1) {
        projects.splice(projIndex, 0, { ...data.data, managed: true });
      }
    }
    return {
      ...state,
      duplicateProjectIsLoading: false,
      duplicateProjectId: null,
      projectList: [...projects],
    };
  }
  return {
    ...state,
    duplicateProjectIsLoading: false,
    duplicateProjectId: null,
  };
};
export const duplicateProjectRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg = "Failed to duplicate project. Please try again!";
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }

  return {
    ...state,
    duplicateProjectIsLoading: false,
    duplicateProjectId: null,
  };
};

export const projectUpdateRequestHandler = (state, action) => {
  if (action.payload.clientDetails) {
    return {
      ...state,
      isLoading: true,
    };
  }
  return {
    ...state,
    updateProjectIsLoading: true,
    generalDetailsUpdated: false,
  };
};
export const projectUpdateRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, editGeneralDetails },
  } = action;
  if (data && data.status === 200) {
    createNotification("success", "Project details updated successfully", 2000);
    return {
      ...state,
      isLoading: false,
      updateProjectIsLoading: false,
      generalDetailsUpdated: editGeneralDetails || false,
    };
  }
  return {
    ...state,
    isLoading: false,
    updateProjectIsLoading: false,
  };
};
export const projectUpdateRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
    return {
      ...state,
      isLoading: false,
      updateProjectIsLoading: false,
    };
  }
  switch (response.status) {
    case 400:
      createNotification("error", "Please Try Again!", 3000);
      break;
    case 403:
      createNotification(
        "error",
        "Sorry! you do not have permission perform this action"
      );
      break;
    default:
    // do nothing
  }
  return {
    ...state,
    isLoading: false,
    updateProjectIsLoading: false,
  };
};

export const clearEditedProjectRequestHandler = (state, action) => {
  return {
    ...state,
    generalDetailsUpdated: false,
  };
};

export const deleteProjectRequestHandler = (state, action) => {
  return {
    ...state,
    deleteProjectIsLoading: true,
  };
};
export const deleteProjectRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
    projectId,
  } = action;
  if (data && data.status === 204) {
    createNotification("success", "Project deleted successfully", 2000);
    return {
      ...state,
      deleteProjectIsLoading: false,
      projectList: state.projectList.filter(
        (project) => project.id !== projectId
      ),
    };
  }
  return {
    ...state,
    deleteProjectIsLoading: false,
  };
};
export const deleteProjectRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
    return {
      ...state,
      deleteProjectIsLoading: false,
    };
  }
  switch (response.status) {
    case 400:
      createNotification("error", "Please Try Again!", 3000);
      break;
    case 403:
      createNotification(
        "error",
        "Sorry! you do not have permission perform this action"
      );
      break;
    default:
    // do nothing
  }
  return {
    ...state,
    deleteProjectIsLoading: false,
  };
};

export const organizationMembersListRequestHandler = (state, action) => {
  return {
    ...state,
    memberShortListIsLoading: true,
  };
};
export const organizationMembersListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    let list = [];
    if (data.data && data.data.length > 0) {
      data.data.map((member) => {
        list.push({
          id: member.id,
          name: `${member.first_name}${
            member.last_name ? ` ${member.last_name}` : ``
          }`,
          avatar: member.avatar,
          role: member.role,
          phone: member.phone,
        });
      });
    }
    return {
      ...state,
      memberShortListIsLoading: false,
      orgMembersList: list,
    };
  }
  return {
    ...state,
    memberShortListIsLoading: false,
  };
};
export const organizationMembersListRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    return {
      ...state,
      memberShortListIsLoading: false,
      orgMembersList: [],
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    return {
      ...state,
      memberShortListIsLoading: false,
      orgMembersList: [],
    };
  }
  return {
    ...state,
    memberShortListIsLoading: false,
    orgMembersList: [],
  };
};

export const addTeamToProjectRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const addTeamToProjectRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    return {
      ...state,
      isLoading: false,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const addTeamToProjectRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const addProjectAssigneeRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    projectAssigneeIsLoading: true,
    loadingAssigneeId: payload.user_id,
    projectAssigneeAdded: false,
    addedAssigneeData: null,
  };
};
export const addProjectAssigneeRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    return {
      ...state,
      projectAssigneeIsLoading: false,
      loadingAssigneeId: null,
      projectAssigneeAdded: true,
      addedAssigneeData: data.data.user,
    };
  }
  return {
    ...state,
    projectAssigneeIsLoading: false,
    loadingAssigneeId: null,
    projectAssigneeAdded: false,
    addedAssigneeData: null,
  };
};
export const addProjectAssigneeRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
    return {
      ...state,
      projectAssigneeIsLoading: false,
      loadingAssigneeId: null,
      projectAssigneeAdded: false,
      addedAssigneeData: null,
    };
  }
  switch (response.status) {
    case 400:
      createNotification("error", "Please Try Again!", 3000);
      break;
    case 403:
      createNotification(
        "error",
        "Sorry! you do not have permission perform this action"
      );
      break;
    default:
    // do nothing
  }
  return {
    ...state,
    projectAssigneeIsLoading: false,
    loadingAssigneeId: null,
    projectAssigneeAdded: false,
    addedAssigneeData: null,
  };
};

export const updateProjectAssigneeRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    projectAssigneeUpdated: false,
    updatedAssigneeData: null,
  };
};
export const updateProjectAssigneeRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, userId },
  } = action;
  if (data && data.status === 200) {
    const assigneeList = [...state.projectAssigneeList];

    assigneeList.some((assignee) => {
      if (assignee.user.id === userId) {
        assignee.can_manage = data.data.can_manage;
        assignee.can_view_report = data.data.can_view_report;
      }
    });
    return {
      ...state,
      isLoading: false,
      projectAssigneeUpdated: true,
      updatedAssigneeData: data.data,
      projectAssigneeList: [...assigneeList],
    };
  }
  return {
    ...state,
    isLoading: false,
    projectAssigneeUpdated: false,
    updatedAssigneeData: null,
  };
};
export const updateProjectAssigneeRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
    return {
      ...state,
      isLoading: false,
      projectAssigneeUpdated: false,
      updatedAssigneeData: null,
    };
  }
  switch (response.status) {
    case 400:
      createNotification("error", "Please Try Again!", 3000);
      break;
    case 403:
      createNotification(
        "error",
        "Sorry! you do not have permission perform this action"
      );
      break;
    default:
    // do nothing
  }
  return {
    ...state,
    isLoading: false,
    projectAssigneeUpdated: false,
    updatedAssigneeData: null,
  };
};

export const deleteProjectAssigneeRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    projectAssigneeIsLoading: true,
    loadingAssigneeId: payload.user_id,
  };
};
export const deleteProjectAssigneeRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
    userData,
  } = action;
  if (data && data.status === 204) {
    if (state.projectAssigneeList && state.projectAssigneeList.length > 0) {
      const assigneeList = state.projectAssigneeList.filter(
        (assignee) => assignee.user.id !== userData.userId
      );
      return {
        ...state,
        projectAssigneeIsLoading: false,
        loadingAssigneeId: null,
        projectAssigneeList: assigneeList,
      };
    }
    return {
      ...state,
      projectAssigneeIsLoading: false,
      loadingAssigneeId: null,
    };
  }
  return {
    ...state,
    projectAssigneeIsLoading: false,
    loadingAssigneeId: null,
  };
};
export const deleteProjectAssigneeRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
    return {
      ...state,
      projectAssigneeIsLoading: false,
      loadingAssigneeId: null,
    };
  }
  switch (response.status) {
    case 400:
      createNotification("error", "Please Try Again!", 3000);
      break;
    case 403:
      createNotification(
        "error",
        "Sorry! you do not have permission perform this action"
      );
      break;
    default:
    // do nothing
  }
  return {
    ...state,
    projectAssigneeIsLoading: false,
    loadingAssigneeId: null,
  };
};

export const getProjectAssigneeListRequestHandler = (state, action) => {
  return {
    ...state,
    projectAssigneeIsLoading: true,
  };
};
export const getProjectAssigneeListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      projectAssigneeIsLoading: false,
      projectAssigneeList: data.data,
    };
  }
  return {
    ...state,
    projectAssigneeIsLoading: false,
    projectAssigneeList: [],
  };
};
export const getProjectAssigneeListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    projectAssigneeIsLoading: false,
    projectAssigneeList: [],
  };
};

export const getProjectTaskListRequestHandler = (state, action) => {
  return {
    ...state,
    taskListLoading: true,
  };
};
export const getProjectTaskListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      taskListLoading: false,
      projectTaskList: data.data.results,
      // projectTaskCount: data.data.count,
      // projectTaskPageSize: data.data.page_size,
    };
  }
  return {
    ...state,
    taskListLoading: false,
    projectTaskList: [],
    // projectTaskCount: null,
    // projectTaskPageSize: null,
  };
};
export const getProjectTaskListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    taskListLoading: false,
    projectTaskList: [],
    // projectTaskCount: null,
    // projectTaskPageSize: null,
  };
};

export const projectAddTaskRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    taskIsLoading: true,
    loadingTaskProjectId: payload.project_id,
  };
};
export const projectAddTaskRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.id) {
    return {
      ...state,
      taskIsLoading: false,
      loadingTaskProjectId: null,
      projectTaskList: [...state.projectTaskList, data],
    };
  }
  return {
    ...state,
    taskIsLoading: false,
    loadingTaskProjectId: null,
  };
};
export const projectAddTaskRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (response.data.name) {
          switch (response.data.name[0]) {
            case "This field may not be blank.":
              errorMsg = "Task name can not be empty.";
              break;
            default:
              errorMsg = response.data.name[0];
          }
        } else {
          errorMsg = "Could not create task. Please try again.";
        }
        break;
      case 403:
        errorMsg = "Sorry! you do not have permission perform this action";
        break;
      default:
        errorMsg = "Could not create task. Please try again.";
    }
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }

  return {
    ...state,
    taskIsLoading: false,
    loadingTaskProjectId: null,
  };
};

export const projectEditTaskRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    taskIsLoading: true,
    loadingTaskId: payload.task_id,
    loadingTaskProjectId: payload.project_id,
  };
};
export const projectEditTaskRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    if (
      data.data &&
      state.projectTaskList &&
      state.projectTaskList.length > 0
    ) {
      const tasksList = [...state.projectTaskList];
      tasksList.some((item) => {
        if (item.id === data.data.id) {
          item.name = data.data.name;
          item.status = data.data.status;
          return true;
        }
        return false;
      });
      return {
        ...state,
        taskIsLoading: false,
        loadingTaskId: null,
        loadingTaskProjectId: null,
        projectTaskList: tasksList,
      };
    }
  }
  return {
    ...state,
    taskIsLoading: false,
    loadingTaskId: null,
    loadingTaskProjectId: null,
  };
};
export const projectEditTaskRequestFailureHandler = (state, action) => {
  return {
    ...state,
    taskIsLoading: false,
    loadingTaskId: null,
    loadingTaskProjectId: null,
  };
};

export const projectDeleteTaskRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    taskIsLoading: true,
    loadingTaskId: payload.task_id,
    loadingTaskProjectId: payload.project_id,
  };
};
export const projectDeleteTaskRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, taskId },
  } = action;
  if (data && data.status === 204 && taskId) {
    const tasksList = [...state.projectTaskList];
    return {
      ...state,
      taskIsLoading: false,
      loadingTaskId: null,
      loadingTaskProjectId: null,
      projectTaskList: tasksList.filter((task) => task.id !== taskId),
    };
  }
  return {
    ...state,
    taskIsLoading: false,
    loadingTaskId: null,
    loadingTaskProjectId: null,
  };
};
export const projectDeleteTaskRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
    return {
      ...state,
      taskIsLoading: false,
      loadingTaskId: null,
      loadingTaskProjectId: null,
      projectAssigneeAdded: false,
      addedAssigneeData: null,
    };
  }
  switch (response.status) {
    case 400:
      createNotification("error", "Please Try Again!", 3000);
      break;
    case 403:
      createNotification(
        "error",
        "Sorry! you do not have permission perform this action"
      );
      break;
    default:
    // do nothing
  }
  return {
    ...state,
    taskIsLoading: false,
    loadingTaskId: null,
    loadingTaskProjectId: null,
  };
};

export const getTaskAssigneeRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getTaskAssigneeRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      isLoading: false,
      taskAssigneeList: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    taskAssigneeList: [],
  };
};
export const getTaskAssigneeRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    taskAssigneeList: [],
  };
};

export const addTaskAssigneeRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    taskAssigneeIsLoading: true,
    loadingTaskAssigneeId: payload.user_id,
  };
};
export const addTaskAssigneeRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.id && data.task && data.task.id) {
    const tasksList = [...state.projectTaskList];
    tasksList.some((task) => {
      if (task.id === data.task.id) {
        const assigneeItem = {
          id: data.id,
          first_name: data.first_name,
          last_name: data.last_name,
          avatar: data.avatar,
          username: data.username,
        };
        task.members = [...task.members, assigneeItem];
        return true;
      }
      return false;
    });
    return {
      ...state,
      taskAssigneeIsLoading: false,
      loadingTaskAssigneeId: null,
      projectTaskList: tasksList,
    };
  }

  return {
    ...state,
    taskAssigneeIsLoading: false,
    loadingTaskAssigneeId: null,
  };
};
export const addTaskAssigneeRequestFailureHandler = (state, action) => {
  createNotification("error", "Something went wrong!!", 2000);
  return {
    ...state,
    taskAssigneeIsLoading: false,
    loadingTaskAssigneeId: null,
  };
};

export const deleteTaskAssigneeRequestHandler = (state, action) => {
  return {
    ...state,
  };
};
export const deleteTaskAssigneeRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, taskId, userId },
  } = action;
  if (data && data.status === 204 && taskId && userId) {
    const tasksList = [...state.projectTaskList];
    tasksList.some((task) => {
      if (task.id === taskId) {
        const filteredMembers = task.members.filter(
          (member) => member.id !== userId
        );
        task.members = [...filteredMembers];
        return true;
      }
      return false;
    });
    return {
      ...state,
      projectTaskList: tasksList,
    };
  }

  return {
    ...state,
  };
};
export const deleteTaskAssigneeRequestFailureHandler = (state, action) => {
  createNotification("error", "Something went wrong!!", 2000);
  return {
    ...state,
  };
};

//Budget and Billing
export const getProjectBillingRequestHandler = (state, action) => {
  return {
    ...state,
    getBillingLoading: false,
    projectBilling: null,
  };
};
export const getProjectBillingRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      getBillingLoading: false,
      projectBilling: data.data,
    };
  }
  return {
    ...state,
    getBillingLoading: false,
    projectBilling: null,
  };
};
export const getProjectBillingRequestFailureHandler = (state, action) => {
  return {
    ...state,
    getBillingLoading: false,
    projectBilling: null,
  };
};

export const addProjectBillingRequestHandler = (state, action) => {
  return {
    ...state,
    saveBillingLoading: true,
  };
};
export const addProjectBillingRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification("success", "Project billing added successfully!", 3000);
    return {
      ...state,
      saveBillingLoading: false,
      projectBilling: data.data,
    };
  }
  return {
    ...state,
    saveBillingLoading: false,
  };
};
export const addProjectBillingRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (response.data.project_hourly && response.data.project_hourly.rate) {
          errorMsg = response.data.project_hourly.rate[0];
        } else if ((errorMsg = response.data.billable_amount)) {
          errorMsg = response.data.billable_amount[0];
        } else {
          errorMsg = "Failed to add project billing. please try again.";
        }
        break;
      case 403:
        errorMsg = "Sorry! you do not have permission perform this action";
        break;
      default:
        errorMsg = "Failed to add project billing. please try again.";
    }
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }
  return {
    ...state,
    saveBillingLoading: false,
  };
};

export const updateProjectBillingRequestHandler = (state, action) => {
  return {
    ...state,
    saveBillingLoading: true,
  };
};
export const updateProjectBillingRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data) {
    createNotification(
      "success",
      "Project billing updated successfully!",
      3000
    );
    return {
      ...state,
      saveBillingLoading: false,
      projectBilling: data,
    };
  }
  return {
    ...state,
    saveBillingLoading: false,
  };
};
export const updateProjectBillingRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (response.data.project_hourly && response.data.project_hourly.rate) {
          errorMsg = response.data.project_hourly.rate[0];
        } else if ((errorMsg = response.data.billable_amount)) {
          errorMsg = response.data.billable_amount[0];
        } else {
          errorMsg = "Failed to update project billing. please try again.";
        }
        break;
      case 403:
        errorMsg = "Sorry! you do not have permission perform this action";
        break;
      default:
        errorMsg = "Failed to update project billing. please try again.";
    }
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }
  return {
    ...state,
    saveBillingLoading: false,
  };
};

export const getProjectBudgetRequestHandler = (state, action) => {
  return {
    ...state,
    getBudgetLoading: true,
    projectBudget: null,
  };
};
export const getProjectBudgetRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      getBudgetLoading: false,
      projectBudget: data.data,
    };
  }
  return {
    ...state,
    getBudgetLoading: false,
    projectBudget: null,
  };
};
export const getProjectBudgetRequestFailureHandler = (state, action) => {
  return {
    ...state,
    getBudgetLoading: false,
    projectBudget: null,
  };
};

export const addProjectBudgetRequestHandler = (state, action) => {
  return {
    ...state,
    saveBudgetLoading: true,
  };
};
export const addProjectBudgetRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification("success", "Project budget added successfully!", 4000);
    return {
      ...state,
      saveBudgetLoading: false,
      projectBudget: data.data,
    };
  }
  return {
    ...state,
    saveBudgetLoading: false,
  };
};
export const addProjectBudgetRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (response.data.budget_amount) {
          errorMsg = response.data.budget_amount[0];
        } else if ((errorMsg = response.data.budget_hours)) {
          errorMsg = response.data.budget_hours[0];
        } else {
          errorMsg = "Failed to add project budget. please try again.";
        }
        break;
      case 403:
        errorMsg = "Sorry! you do not have permission perform this action";
        break;
      default:
        errorMsg = "Failed to add project budget. please try again.";
    }
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }
  return {
    ...state,
    saveBudgetLoading: false,
  };
};

export const updateProjectBudgetRequestHandler = (state, action) => {
  return {
    ...state,
    saveBudgetLoading: true,
  };
};
export const updateProjectBudgetRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data) {
    createNotification("success", "Project budget updated successfully!", 4000);
    return {
      ...state,
      saveBudgetLoading: false,
      projectBudget: data,
    };
  }
  return {
    ...state,
    saveBudgetLoading: false,
  };
};
export const updateProjectBudgetRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (response.data.budget_amount) {
          errorMsg = response.data.budget_amount[0];
        } else if ((errorMsg = response.data.budget_hours)) {
          errorMsg = response.data.budget_hours[0];
        } else {
          errorMsg = "Failed to update project budget. please try again.";
        }
        break;
      case 403:
        errorMsg = "Sorry! you do not have permission perform this action";
        break;
      default:
        errorMsg = "Failed to update project budget. please try again.";
    }
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }
  return {
    ...state,
    saveBudgetLoading: false,
  };
};

// invoice
export const getProjectInvoicesRequestHandler = (state, action) => {
  return {
    ...state,
    projectInvoiceListLoading: true,
  };
};
export const getProjectInvoicesRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      projectInvoiceListLoading: false,
      projectInvoicesList: data.data.results,
      projectInvoiceCount: data.data.count,
      projectInvoicePageSize: data.data.page_size,
    };
  }
  return {
    ...state,
    projectInvoiceListLoading: false,
    projectInvoicesList: [],
    projectInvoiceCount: null,
    projectInvoicePageSize: null,
  };
};
export const getProjectInvoicesRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  // if (response && response.status === 403) {
  //   return {
  //     ...state,
  //     accessDenied: true,
  //   };
  // }
  return {
    ...state,
    projectInvoiceListLoading: false,
    projectInvoicesList: [],
    projectInvoiceCount: null,
    projectInvoicePageSize: null,
  };
};

export const clearProjectAssigneeListRequestHandler = (state, action) => {
  return {
    ...state,
    projectAssigneeList: [],
  };
};

export const clearProjectAccessDeniedRequestHandler = (state, action) => {
  return {
    ...state,
    accessDenied: false,
  };
};

export const clearProjectBudgetRequestHandler = (state, action) => {
  return {
    ...state,
    projectBudget: null,
  };
};
export const clearProjectBillingRequestHandler = (state, action) => {
  return {
    ...state,
    projectBilling: null,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  //Get Project
  [GET_PROJECT_LIST]: getProjectListRequestHandler,
  [GET_PROJECT_LIST_SUCCESS]: getProjectListRequestSuccessHandler,
  [GET_PROJECT_LIST_FAILURE]: getProjectListRequestFailureHandler,

  [GET_PROJECT_SHORT_LIST]: getProjectShortListRequestHandler,
  [GET_PROJECT_SHORT_LIST_SUCCESS]: getProjectShortListRequestSuccessHandler,
  [GET_PROJECT_SHORT_LIST_FAILURE]: getProjectShortListRequestFailureHandler,

  [GET_PROJECT_DETAILS]: getProjectDetailsRequestHandler,
  [GET_PROJECT_DETAILS_SUCCESS]: getProjectDetailsRequestSuccessHandler,
  [GET_PROJECT_DETAILS_FAILURE]: getProjectDetailsRequestFailureHandler,

  [GET_PROJECT_BUDGET_AND_BILLABLE]: getProjectBudgetAndBillableRequestHandler,
  [GET_PROJECT_BUDGET_AND_BILLABLE_SUCCESS]:
    getProjectBudgetAndBillableRequestSuccessHandler,
  [GET_PROJECT_BUDGET_AND_BILLABLE_FAILURE]:
    getProjectBudgetAndBillableRequestFailureHandler,

  [GET_PROJECT_EXPENSES]: getProjectExpensesRequestHandler,
  [GET_PROJECT_EXPENSES_SUCCESS]: getProjectExpensesRequestSuccessHandler,
  [GET_PROJECT_EXPENSES_FAILURE]: getProjectExpensesRequestFailureHandler,

  [GET_PROJECT_YEARLY_EXPENSES]: getProjectYearlyExpensesRequestHandler,
  [GET_PROJECT_YEARLY_EXPENSES_SUCCESS]:
    getProjectYearlyExpensesRequestSuccessHandler,
  [GET_PROJECT_YEARLY_EXPENSES_FAILURE]:
    getProjectYearlyExpensesRequestFailureHandler,

  //Add Project
  [CREATE_PROJECT_REQUEST]: createProjectRequestHandler,
  [CREATE_PROJECT_REQUEST_SUCCESS]: createProjectRequestSuccessHandler,
  [CREATE_PROJECT_REQUEST_FAILURE]: createProjectRequestFailureHandler,

  [CLEAR_CREATED_PROJECT]: clearCreatedProjectRequestHandler,

  [DUPLICATE_PROJECT_REQUEST]: duplicateProjectRequestHandler,
  [DUPLICATE_PROJECT_REQUEST_SUCCESS]: duplicateProjectRequestSuccessHandler,
  [DUPLICATE_PROJECT_REQUEST_FAILURE]: duplicateProjectRequestFailureHandler,

  [CLEAR_EDITED_PROJECT]: clearEditedProjectRequestHandler,

  [UPDATE_PROJECT_REQUEST]: projectUpdateRequestHandler,
  [UPDATE_PROJECT_REQUEST_SUCCESS]: projectUpdateRequestSuccessHandler,
  [UPDATE_PROJECT_REQUEST_FAILURE]: projectUpdateRequestFailureHandler,

  [GET_ORGANIZATION_MEMBERS_LIST]: organizationMembersListRequestHandler,
  [GET_ORGANIZATION_MEMBERS_LIST_SUCCESS]:
    organizationMembersListRequestSuccessHandler,
  [GET_ORGANIZATION_MEMBERS_LIST_FAILURE]:
    organizationMembersListRequestFailureHandler,

  [ADD_TEAM_TO_PROJECT]: addTeamToProjectRequestHandler,
  [ADD_TEAM_TO_PROJECT_SUCCESS]: addTeamToProjectRequestSuccessHandler,
  [ADD_TEAM_TO_PROJECT_FAILURE]: addTeamToProjectRequestFailureHandler,

  [ADD_PROJECT_ASSIGNEE]: addProjectAssigneeRequestHandler,
  [ADD_PROJECT_ASSIGNEE_SUCCESS]: addProjectAssigneeRequestSuccessHandler,
  [ADD_PROJECT_ASSIGNEE_FAILURE]: addProjectAssigneeRequestFailureHandler,

  [UPDATE_PROJECT_ASSIGNEE]: updateProjectAssigneeRequestHandler,
  [UPDATE_PROJECT_ASSIGNEE_SUCCESS]: updateProjectAssigneeRequestSuccessHandler,
  [UPDATE_PROJECT_ASSIGNEE_FAILURE]: updateProjectAssigneeRequestFailureHandler,

  [DELETE_PROJECT_ASSIGNEE]: deleteProjectAssigneeRequestHandler,
  [DELETE_PROJECT_ASSIGNEE_SUCCESS]: deleteProjectAssigneeRequestSuccessHandler,
  [DELETE_PROJECT_ASSIGNEE_FAILURE]: deleteProjectAssigneeRequestFailureHandler,

  [GET_PROJECT_ASSIGNEE_LIST]: getProjectAssigneeListRequestHandler,
  [GET_PROJECT_ASSIGNEE_LIST_SUCCESS]:
    getProjectAssigneeListRequestSuccessHandler,
  [GET_PROJECT_ASSIGNEE_LIST_FAILURE]:
    getProjectAssigneeListRequestFailureHandler,

  [GET_PROJECT_TASK_LIST]: getProjectTaskListRequestHandler,
  [GET_PROJECT_TASK_LIST_SUCCESS]: getProjectTaskListRequestSuccessHandler,
  [GET_PROJECT_TASK_LIST_FAILURE]: getProjectTaskListRequestFailureHandler,

  [PROJECT_ADD_TASK]: projectAddTaskRequestHandler,
  [PROJECT_ADD_TASK_SUCCESS]: projectAddTaskRequestSuccessHandler,
  [PROJECT_ADD_TASK_FAILURE]: projectAddTaskRequestFailureHandler,

  [PROJECT_EDIT_TASK]: projectEditTaskRequestHandler,
  [PROJECT_EDIT_TASK_SUCCESS]: projectEditTaskRequestSuccessHandler,
  [PROJECT_EDIT_TASK_FAILURE]: projectEditTaskRequestFailureHandler,

  [PROJECT_DELETE_TASK]: projectDeleteTaskRequestHandler,
  [PROJECT_DELETE_TASK_SUCCESS]: projectDeleteTaskRequestSuccessHandler,
  [PROJECT_DELETE_TASK_FAILURE]: projectDeleteTaskRequestFailureHandler,

  [GET_TASK_ASSIGNEE_LIST]: getTaskAssigneeRequestHandler,
  [GET_TASK_ASSIGNEE_LIST_SUCCESS]: getTaskAssigneeRequestSuccessHandler,
  [GET_TASK_ASSIGNEE_LIST_FAILURE]: getTaskAssigneeRequestFailureHandler,

  [ADD_TASK_ASSIGNEE]: addTaskAssigneeRequestHandler,
  [ADD_TASK_ASSIGNEE_SUCCESS]: addTaskAssigneeRequestSuccessHandler,
  [ADD_TASK_ASSIGNEE_FAILURE]: addTaskAssigneeRequestFailureHandler,

  [DELETE_TASK_ASSIGNEE]: deleteTaskAssigneeRequestHandler,
  [DELETE_TASK_ASSIGNEE_SUCCESS]: deleteTaskAssigneeRequestSuccessHandler,
  [DELETE_TASK_ASSIGNEE_FAILURE]: deleteTaskAssigneeRequestFailureHandler,

  // Delete Project
  [DELETE_PROJECT_REQUEST]: deleteProjectRequestHandler,
  [DELETE_PROJECT_REQUEST_SUCCESS]: deleteProjectRequestSuccessHandler,
  [DELETE_PROJECT_REQUEST_FAILURE]: deleteProjectRequestFailureHandler,

  //Budget and Billing
  [GET_PROJECT_BILLING]: getProjectBillingRequestHandler,
  [GET_PROJECT_BILLING_SUCCESS]: getProjectBillingRequestSuccessHandler,
  [GET_PROJECT_BILLING_FAILURE]: getProjectBillingRequestFailureHandler,

  [ADD_PROJECT_BILLING]: addProjectBillingRequestHandler,
  [ADD_PROJECT_BILLING_SUCCESS]: addProjectBillingRequestSuccessHandler,
  [ADD_PROJECT_BILLING_FAILURE]: addProjectBillingRequestFailureHandler,

  [UPDATE_PROJECT_BILLING]: updateProjectBillingRequestHandler,
  [UPDATE_PROJECT_BILLING_SUCCESS]: updateProjectBillingRequestSuccessHandler,
  [UPDATE_PROJECT_BILLING_FAILURE]: updateProjectBillingRequestFailureHandler,

  [CLEAR_PROJECT_BILLING]: clearProjectBillingRequestHandler,

  [GET_PROJECT_BUDGET]: getProjectBudgetRequestHandler,
  [GET_PROJECT_BUDGET_SUCCESS]: getProjectBudgetRequestSuccessHandler,
  [GET_PROJECT_BUDGET_FAILURE]: getProjectBudgetRequestFailureHandler,

  [ADD_PROJECT_BUDGET]: addProjectBudgetRequestHandler,
  [ADD_PROJECT_BUDGET_SUCCESS]: addProjectBudgetRequestSuccessHandler,
  [ADD_PROJECT_BUDGET_FAILURE]: addProjectBudgetRequestFailureHandler,

  [UPDATE_PROJECT_BUDGET]: updateProjectBudgetRequestHandler,
  [UPDATE_PROJECT_BUDGET_SUCCESS]: updateProjectBudgetRequestSuccessHandler,
  [UPDATE_PROJECT_BUDGET_FAILURE]: updateProjectBudgetRequestFailureHandler,

  [CLEAR_PROJECT_BUDGET]: clearProjectBudgetRequestHandler,

  //invoices
  [GET_PROJECT_INVOICES]: getProjectInvoicesRequestHandler,
  [GET_PROJECT_INVOICES_SUCCESS]: getProjectInvoicesRequestSuccessHandler,
  [GET_PROJECT_INVOICES_FAILURE]: getProjectInvoicesRequestFailureHandler,

  [CLEAR_PROJECT_ASSIGNEE_LIST]: clearProjectAssigneeListRequestHandler,

  [CLEAR_PROJECT_ACCESS_DENIED]: clearProjectAccessDeniedRequestHandler,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  // loading
  isLoading: false,
  createProjectIsLoading: false,
  updateProjectIsLoading: false,
  deleteProjectIsLoading: false,
  shortListIsLoading: false,
  memberShortListIsLoading: false,
  projectAssigneeIsLoading: false,
  loadingAssigneeId: null,
  projectBudgetAndBillableIsLoading: false,
  projectExpensesIsLoading: false,
  taskListLoading: false,
  projectInvoiceListLoading: false,
  taskIsLoading: false,
  loadingTaskId: null,
  loadingTaskProjectId: null,
  taskAssigneeIsLoading: false,
  loadingTaskAssigneeId: null,
  saveBillingLoading: false,
  saveBudgetLoading: false,
  getBillingLoading: false,
  getBudgetLoading: false,
  duplicateProjectId: null,

  // others
  redirect: false,
  projectList: [],
  projectShortList: [],
  projectDetails: null,
  projectBudgetAndBillable: null,
  projectExpenses: null,
  yearlyExpenses: null,
  projectBilling: null,
  projectBudget: null,
  orgMembersList: [],
  projectAssigneeList: [],
  projectTaskList: [],
  taskAssigneeList: [],
  projectInvoicesList: [],
  createdProjectId: null,
  projectClient: null,
  projectAssigneeAdded: false,
  addedAssigneeData: null,
  projectAssigneeUpdated: false,
  updatedAssigneeData: null,
  generalDetailsUpdated: false,
  accessDenied: false,

  // pagination
  totalProjectCount: null,
  projectPageSize: null,
  // projectTaskCount: null,
  // projectTaskPageSize: null,
  projectInvoiceCount: null,
  projectInvoicePageSize: null,
};

export default function projectReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
