import axios from 'axios';
import NewUserLogin from 'custom_modules/Login/newUserloginContainer';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AcceptInvitation from './custom_modules/AcceptInvitation/AcceptInvitation';
import AcceptJoinRequest from './custom_modules/AcceptJoinRequest/AcceptJoinRequest';
import RedeemAppsumo from './custom_modules/RedeemAppsumo';
import RedeemDiscountCode from './custom_modules/RedeemDiscountCode';
import RedeemGeneric from './custom_modules/RedeemGeneric';
import AdminLayout from './layouts/Admin';
//Parent Layouts
import AuthLayout from './layouts/Auth/Auth.jsx';
import SetupLayout from './layouts/Onboard/Onboard.jsx';

//add the following lines to any file where you import axios
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.withCredentials = true;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        <Route path="/auth" render={props => <AuthLayout {...props} />} />
        <Route path="/user" render={props => <AdminLayout {...props} />} />
        <Route path="/reset-password" component={NewUserLogin} />
        <Route path="/setup" render={props => <SetupLayout {...props} />} />
        <Route path="/accept-invitation" component={AcceptInvitation} />
        <Route path="/accept-request" component={AcceptJoinRequest} />
        <Route path="/appsumo" component={RedeemAppsumo} />
        {/* FIX THE LOADERS WHEN SHOWING THESE PAGES  */}
        {/* <Route path="/redeem" component={RedeemGeneric} />
        <Route path="/special-offer" component={RedeemDiscountCode} /> */}
        <Redirect from="/" to="/auth/login" />
      </Switch>
    );
  }
}

export default App;
