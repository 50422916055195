import { t } from 'i18next';
import { Modal, ModalBody } from 'reactstrap';

import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import { ColoredButton, WhiteButton } from '../../styledComponents/buttons';
import { CommonText } from '../../styledComponents/common';
import { ModalButtonSection } from '../../styledComponents/members';

const UserTypeChangeWarningModal = ({
  isOpen,
  toggle,
  handleSave,
  isLoading,
  member,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `15px` }}>
          Are you sure, you want to change this user from{' '}
          {member?.geofence_time_clock ? t('office_user') : t('remote_user')} to{' '}
          {member?.geofence_time_clock ? t('remote_user') : t('office_user')}{' '}
          type ?
        </CommonText>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            {t('cancel')}
          </WhiteButton>
          <ColoredButton
            type="blue"
            padding={isLoading ? '0 15px' : '0 30px'}
            onClick={() => handleSave(!member?.geofence_time_clock)}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText={t('yes')} fontSize="13px" />
            ) : (
              t('yes')
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default UserTypeChangeWarningModal;
