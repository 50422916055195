import DashboardCarousel from 'components/DashboardCarousel';
import { t } from 'i18next';
import { TaskStatusOptions } from 'modules/constants';
import { dashboardStatusColors } from 'styles/light/colors';

import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import { CommonFlex, CommonGrid } from '../../styledComponents/common';
import {
  ColorSquare,
  DashboardCardTitle,
  SmallCardLabel,
} from '../../styledComponents/dashboard';
import TopProjectsCard from './TopProjectsCard';
import {
  CardTitleAndSubtitleContainer,
  SmallCardContainer,
  WeeklyActivityTitleContainer,
} from './cardStyles';

const TaskProgressOverview = props => {
  const statusNames = TaskStatusOptions?.filter(status => status.value)
  ?.map(status => status.key);
  return (
    <SmallCardContainer
      gridColumn="span 4"
      laptopGridColumn="span 4"
      tabletGridColumn="span 1"
    >
      <WeeklyActivityTitleContainer margin="0 0 20px 0" padding="0">
        <CardTitleAndSubtitleContainer>
          <DashboardCardTitle>
            {t('task_progress_overview_by_offices')}
          </DashboardCardTitle>
        </CardTitleAndSubtitleContainer>
        <CommonFlex gap="15px">
          {statusNames?.map(statusName => (
            <CommonFlex gap="10px" key={statusName}>
              <ColorSquare background={dashboardStatusColors[statusName]} />
              <SmallCardLabel>{t(statusName)}</SmallCardLabel>
            </CommonFlex>
          ))}
        </CommonFlex>
      </WeeklyActivityTitleContainer>
      {props.isLoading ? (
        <ComponentCircleLoader padding="6vh" />
      ) : (
        props.task_progress_overview &&
        props.task_progress_overview.length > 0 && (
          <DashboardCarousel>
            {props.task_progress_overview.map((item, index) => (
              <TopProjectsCard
                task={item}
                isLoading={props.isLoading}
                key={index}
                history={props.history}
              />
            ))}
          </DashboardCarousel>
        )
      )}
    </SmallCardContainer>
  );
};

export default TaskProgressOverview;
