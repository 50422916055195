// Redux integrated routes
import CreateRequest from 'custom_modules/UserUpdate/CreateRequest/createRequestContainer';
import DeleteRequest from 'custom_modules/UserUpdate/DeleteRequest/deleteRequestContainer';
import UpdateRequest from 'custom_modules/UserUpdate/UpdateRequest/updateRequestContainer';

import ClockInOut from './custom_modules/ClockInOut';
// import VerificationCode from "./custom_modules/VerificationCode";
import Dashboard from './custom_modules/Dashboard';
// import AddOrJoinOrg from "./custom_modules/Onboarding/AddOrJoinOrg";
// import RequestJoinOrg from "./custom_modules/Onboarding/RequestJoinOrg";
// import OnboardingSteps from "./custom_modules/Onboarding/OnboardingSteps";
// import OnboardingLoader from "./custom_modules/Onboarding/OnboardingSteps/OnboardingLoader/onboardingLoaderContainer";
// import ActivityLocations from "./custom_modules/Activity/Location";
// import ActivityScreenshots from "./custom_modules/ActivityNew/Screenshots";
// import ActivityApps from "./custom_modules/ActivityNew/Apps";
// import ActivityURLs from "./custom_modules/ActivityNew/URLs";
// Field
import LiveLocation from './custom_modules/FieldServices/LiveLocation';
import RouteMap from './custom_modules/FieldServices/RouteMapNew';
// import Register from "./custom_modules/SignUp";
import ForgotPassword from './custom_modules/ForgotPassword';
import Login from './custom_modules/Login';
// import Organizations from "./custom_modules/Organization";
// import AddOrganization from "./custom_modules/AddOrganization";
// import Clients from "./custom_modules/Clients";
// import ClientDetails from "./custom_modules/Clients/ClientDetails/clientDetailsContainer";
// import AddClient from "./custom_modules/Clients/AddClient/addClientContainer";
// import EditClient from "./custom_modules/Clients/EditClient/editClientContainer";
// import Schedules from "./custom_modules/Schedule/index";
// import Payroll from "./custom_modules/Payroll";
import Members from './custom_modules/Members';
import AddMember from './custom_modules/Members/AddMember/addMemberContainer';
import MemberDetails from './custom_modules/Members/MemberDetails/memberDetailsContainer';
import MemberInvitation from './custom_modules/Members/MemberInvitation/memberInvitationContainer';
// import TimesheetCustomRange from "./custom_modules/Timesheet/CustomRange";
// import TimesheetApproval from "./custom_modules/TimesheetApproval";
// import WorkNotes from "./custom_modules/WorkNotes";
// import Projects from "./custom_modules/Projects";
// import ProjectDetails from "./custom_modules/Projects/ProjectDetails/projectDetailsContainer";
// import CreateProject from "./custom_modules/Projects/CreateProject/createProjectContainer";
// import EditProject from "./custom_modules/Projects/EditProject/editProjectContainer";
import Offices from './custom_modules/Offices';
import ResetPassword from './custom_modules/ResetPassword';
import GeofenceSettings from './custom_modules/Settings/GeofenceSettings';
// import TimeAndActivivtyReport from "./custom_modules/ReportsNew/TimeActivity";
// import ManualTimeReport from "./custom_modules/ReportsNew/ManualTime";
// import AppsUrlUsage from "./custom_modules/ReportsNew/AppsUrlUsage";
// import AppUsageReport from "./custom_modules/Reports/AppUsageReport";
// import DueAmountReport from "./custom_modules/Reports/DueAmountReport";
// import PaidAmountReport from "./custom_modules/Reports/PaidAmountReport";
// import OneTimeReport from "./custom_modules/Reports/OneTimePaidAmountReport";
// import Teams from "./custom_modules/Teams/";
// import AddTeam from "./custom_modules/Teams/AddTeam/addTeamContainer";
// // import EditTeam from "./custom_modules/Teams/EditTeam/editTeamContainer";
// import TeamDetails from "./custom_modules/Teams/TeamDetails/teamDetailsContainer";
// import Invoice from "./custom_modules/Invoice";
// import CreateInvoice from "./custom_modules/Invoice/CreateInvoice/createInvoiceContainer";
// import EditInvoice from "./custom_modules/Invoice/EditInvoice/editInvoiceContainer";
// import InvoicePreview from "./custom_modules/Invoice/PreviewInvoice/PreviewInvoiceContainer";
// import InvoiceClientView from "./custom_modules/Invoice/InvoiceClientView/InvoiceClientViewContainer";
// import IntegrationLayout from "./layouts/Integrations/Integrations";
// import Settings from "./custom_modules/Settings";
// import ActivitySettings from "./custom_modules/Settings/ActivitySettings";
import LocationSettings from './custom_modules/Settings/GpsLocationSettings';
import TaskLocations from './custom_modules/TaskLocations';
import Tasks from './custom_modules/Tasks';
import CreateTask from './custom_modules/Tasks/CreateTask';
import EditTask from './custom_modules/Tasks/EditTask';
import TaskApproval from './custom_modules/Tasks/TaskApproval';
import TaskDetails from './custom_modules/Tasks/TaskDetails';
// import JobSites from "./custom_modules/FieldServices/JobSites";
// import GeofenceClockInOut from "custom_modules/FieldServices/GeofenceClockInOut";
// import CurrentlyTracking from "./custom_modules/LiveFeed/CurrentlyTracking";
// import InstantScreenshots from "./custom_modules/LiveFeed/InstantScreenshots";
import TimesheetDaily from './custom_modules/Timesheet/Daily';
// import TimesheetWeekly from "./custom_modules/Timesheet/Weekly";
// import TimesheetBiweekly from "./custom_modules/Timesheet/Biweekly";
import TimesheetMonthly from './custom_modules/Timesheet/Monthly';
// import VerifyUser from "./custom_modules/VerifyUser";
import UserProfile from './custom_modules/UserProfile';
import UserAddRequest from './custom_modules/UserUpdate/CreateRequest';
import UserDetails from './custom_modules/UserUpdate/UserDetails/userDetailsContainer';

// import TimesheetSettings from "./custom_modules/Settings/TimesheetSettings";

// import Subscription from "./custom_modules/Subscription/ChangeSubscription";
// import CurrentPlan from "./custom_modules/Subscription/CurrentPlan";
// import UpgradePlan from "./custom_modules/Subscription/UpgradePlan";
// import Checkout from "./custom_modules/Subscription/Checkout";
// import PaddleCheckout from "./custom_modules/Subscription/PaddleCheckout/PaddleCheckout";

// import PublicApi from "./custom_modules/PublicApi";
// import Leave from "./custom_modules/Leave";
// import LeaveCalender from "./custom_modules/Leave/LeaveCalendar/LeaveCalendar";

const routes = [
  {
    path: '/login',
    name: 'Login',
    mini: 'L',
    component: Login,
    layout: '/auth',
    redirect: true,
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   mini: "R",
  //   component: Register,
  //   layout: "/auth",
  //   redirect: true,
  // },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    mini: 'F',
    component: ForgotPassword,
    layout: '/auth',
    redirect: true,
  },
  // {
  //   path: "/verify-code",
  //   name: "Verification Code",
  //   mini: "VC",
  //   component: VerificationCode,
  //   layout: "/auth",
  //   redirect: true,
  // },
  {
    path: '/reset_password/:confirmation_key',
    name: 'Reset Password',
    mini: 'RP',
    component: ResetPassword,
    layout: '/auth',
    redirect: true,
  },
  // {
  //   path: "/verify_user",
  //   name: "VerifyUser",
  //   mini: "VU",
  //   component: VerifyUser,
  //   layout: "/auth",
  //   redirect: true,
  // },
  // {
  //   path: "/add-or-join",
  //   name: "Add or Join Organization",
  //   mini: "AJ",
  //   component: AddOrJoinOrg,
  //   layout: "/setup",
  //   redirect: true,
  // },
  // {
  //   path: "/request-to-join",
  //   name: "Request to Join Organization",
  //   mini: "RJ",
  //   component: RequestJoinOrg,
  //   layout: "/setup",
  //   redirect: true,
  // },
  // {
  //   path: "/onboarding",
  //   name: "Onboarding",
  //   mini: "OB",
  //   component: OnboardingSteps,
  //   layout: "/setup",
  //   redirect: true,
  // },
  // {
  //   path: "/onboarding-loader",
  //   name: "Onboarding Loader",
  //   mini: "OL",
  //   component: OnboardingLoader,
  //   layout: "/setup",
  //   redirect: true,
  // },
  {
    path: '/user-profile',
    name: 'Profile',
    mini: 'P',
    component: UserProfile,
    layout: '/user',
    redirect: true,
  },
  {
    path: '/dashboard',
    group: 'Analyze',
    name: 'Dashboard',
    icon: 'dashboardIcon',
    component: Dashboard,
    layout: '/user',
  },
  // {
  //   path: "/live-feed",
  //   group: "Analyze",
  //   name: "Live Feed",
  //   icon: "dashboardIcon",
  //   component: CurrentlyTracking,
  //   layout: "/user",
  //   live: true,
  // },
  // {
  //   path: "/instant-screenshots",
  //   name: "Instant Screenshots",
  //   mini: "IS",
  //   component: InstantScreenshots,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   collapse: true,
  //   group: "Analyze",
  //   name: "RemoteTrack",
  //   icon: "remoteTrackIcon",
  //   state: "activityCollapse",
  //   monitored: true,
  //   views: [
  //     {
  //       path: "/activity/screenshots",
  //       name: "Screenshots",
  //       mini: "S",
  //       component: ActivityScreenshots,
  //       layout: "/user",
  //     },
  //     {
  //       path: "/activity/apps",
  //       name: "Apps",
  //       mini: "A",
  //       component: ActivityApps,
  //       layout: "/user",
  //     },
  //     {
  //       path: "/activity/urls",
  //       name: "URLs",
  //       mini: "U",
  //       component: ActivityURLs,
  //       layout: "/user",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   group: 'Analyze',
  //   name: 'Timesheets',
  //   icon: 'timesheetIcon',
  //   state: 'timesheetCollapse',
  //   views: [
  //     {
  //       path: '/timesheet/daily',
  //       name: 'Daily',
  //       mini: 'D',
  //       component: TimesheetDaily,
  //       layout: '/user',
  //     },
  //     {
  //       path: '/timesheet/monthly',
  //       name: 'Monthly',
  //       mini: 'M',
  //       component: TimesheetMonthly,
  //       layout: '/user',
  //     },
  // {
  //   path: "/timesheet/custom-range",
  //   name: "Custom",
  //   mini: "C",
  //   component: TimesheetCustomRange,
  //   layout: "/user",
  // },
  // {
  //   path: "/timesheet/work-notes",
  //   name: "Work Notes",
  //   mini: "W",
  //   component: WorkNotes,
  //   layout: "/user",
  //   new: true,
  // },
  //   ],
  // },
  {
    collapse: true,
    group: 'Analyze',
    name: 'Reports',
    icon: 'reportsIcon',
    state: 'reportCollapse',
    views: [
      {
        path: '/reports/route-map',
        name: 'Route Map',
        mini: 'R',
        component: RouteMap,
        layout: '/user',
      },
      {
        path: '/reports/live-location',
        name: 'Live Location',
        mini: 'L',
        component: LiveLocation,
        layout: '/user',
      },

      // {
      //   path: "/reports/time-and-activity",
      //   name: "Time and Activity",
      //   mini: "T",
      //   component: TimeAndActivivtyReport,
      //   layout: "/user",
      // },
      // {
      //   path: "/reports/manual-time",
      //   name: "Manual Time",
      //   mini: "M",
      //   component: ManualTimeReport,
      //   layout: "/user",
      // },
      // {
      //   path: "/reports/apps-url-usage",
      //   name: "Apps & URLs Usage",
      //   mini: "A",
      //   component: AppsUrlUsage,
      //   layout: "/user",
      //   monitored: true,
      // },
    ],
  },
  {
    path: '/offices',
    group: 'Manage',
    name: 'Offices',
    icon: 'officesIcon',
    component: Offices,
    layout: '/user',
  },
  // {
  //   path: "/tasks",
  //   group: "Manage",
  //   name: "Tasks",
  //   icon: "tasksIcon",
  //   component: Tasks,
  //   layout: "/user",
  // },
  {
    collapse: true,
    group: 'Manage',
    name: 'Tasks',
    icon: 'tasksIcon',
    state: 'tasksCollapse',
    views: [
      {
        path: '/tasks',
        name: 'Tasks',
        mini: 'T',
        component: Tasks,
        layout: '/user',
      },
      {
        path: '/task-approval',
        name: 'Task Approval',
        mini: 'A',
        component: TaskApproval,
        layout: '/user',
      },
    ],
  },
  {
    path: '/task-details',
    group: 'Manage',
    name: 'TaskDetails',
    component: TaskDetails,
    layout: '/user',
    redirect: true,
  },
  {
    path: '/create-task',
    group: 'Manage',
    name: 'CreateTask',
    component: CreateTask,
    layout: '/user',
    redirect: true,
  },
  {
    path: '/edit-task',
    group: 'Manage',
    name: 'EditTask',
    component: EditTask,
    layout: '/user',
    redirect: true,
  },
  {
    path: '/task-locations',
    group: 'Manage',
    name: 'TaskLocations',
    icon: 'gpsTrackIcon',
    component: TaskLocations,
    layout: '/user',
  },
  // {
  //   path: "/leave-calender",
  //   name: "Leaves Calender",
  //   mini: "LC",
  //   component: LeaveCalender,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   collapse: true,
  //   group: "Manage",
  //   name: "Attendance",
  //   icon: "clockInOutIcon",
  //   state: "attendanceCollapse",
  //   views: [
  //     {
  //       path: "/attendance/clock-in-out",
  //       name: "Clock In/Out",
  //       mini: "C",
  //       component: ClockInOut,
  //       layout: "/user",
  //     },
  //   ],
  // },
  {
    path: '/attendance',
    group: 'Manage',
    name: 'Attendance',
    icon: 'clockInOutIcon',
    component: ClockInOut,
    layout: '/user',
  },
  {
    collapse: true,
    path: '/user-update',
    group: 'Manage',
    name: 'user_update',
    icon: 'membersIcon',
    component: CreateRequest,
    layout: '/user',
    state: 'userRequestCollapse',
    views: [
      {
        path: '/user-update/create-request',
        name: 'create_request',
        mini: 'C',
        component: CreateRequest,
        layout: '/user',
      },
      {
        path: '/user-update/update-request',
        name: 'update_request',
        mini: 'U',
        component: UpdateRequest,
        layout: '/user',
      },
      {
        path: '/user-update/delete-request',
        name: 'delete_request',
        mini: 'D',
        component: DeleteRequest,
        layout: '/user',
      },
    ],
  },

  // {
  //   path: "/schedules",
  //   group: "Manage",
  //   name: "Schedules",
  //   icon: "schedulesIcon",
  //   component: Schedules,
  //   layout: "/user",
  // },

  // {
  //   collapse: true,
  //   group: "Manage",
  //   name: "Field Services",
  //   icon: "gpsTrackIcon",
  //   state: "locationCollapse",
  //   views: [
  //     {
  //       path: "/field-services/live-location",
  //       name: "Live Location",
  //       mini: "L",
  //       component: LiveLocation,
  //       layout: "/user",
  //     },
  //     {
  //       path: "/field-services/job-sites",
  //       name: "Job Sites",
  //       mini: "J",
  //       component: JobSites,
  //       layout: "/user",
  //       new: true,
  //     },
  //     {
  //       path: "/field-services/geofence-clock-in-out",
  //       name: "Geofence Clock In/Out",
  //       mini: "G",
  //       component: GeofenceClockInOut,
  //       layout: "/user",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   group: "Manage",
  //   name: "GPSTrack",
  //   icon: "gpsTrackIcon",
  //   state: "locationCollapse",
  //   views: [
  //     {
  //       path: "/activity/locations",
  //       name: "Locations",
  //       mini: "L",
  //       component: ActivityLocations,
  //       layout: "/user",
  //     },
  //   ],
  // },
  // {
  //   path: "/projects",
  //   group: "Manage",
  //   name: "Projects",
  //   icon: "projectsIcon",
  //   component: Projects,
  //   layout: "/user",
  // },
  // {
  //   path: "/project-details",
  //   name: "ProjectDetails",
  //   component: ProjectDetails,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/create-project",
  //   name: "CreateProject",
  //   component: CreateProject,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/edit-project",
  //   name: "EditProject",
  //   component: EditProject,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/invoices",
  //   group: "Manage",
  //   name: "Invoice",
  //   icon: "invoiceIcon",
  //   component: Invoice,
  //   layout: "/user",
  // },
  // {
  //   path: "/create-invoice",
  //   name: "Create Invoice",
  //   mini: "CI",
  //   component: CreateInvoice,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/edit-invoice",
  //   name: "Edit Invoice",
  //   mini: "EI",
  //   component: EditInvoice,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/invoice-preview",
  //   name: "Invoice Preview",
  //   mini: "IP",
  //   component: InvoicePreview,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/invoice-client-view",
  //   name: "Invoice Client View",
  //   mini: "ICV",
  //   component: InvoiceClientView,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/clients",
  //   group: "Manage",
  //   name: "Clients",
  //   icon: "clientsIcon",
  //   component: Clients,
  //   layout: "/user",
  // },
  // {
  //   path: "/client-details",
  //   name: "ClientDetails",
  //   component: ClientDetails,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/add-client",
  //   name: "AddClient",
  //   component: AddClient,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/edit-client",
  //   name: "EditClient",
  //   component: EditClient,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/teams",
  //   group: "Admin",
  //   name: "Teams",
  //   icon: "teamsIcon",
  //   component: Teams,
  //   layout: "/user",
  // },
  // {
  //   path: "/add-team",
  //   name: "Add Team",
  //   mini: "AT",
  //   component: AddTeam,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/edit-team",
  //   name: "Edit Team",
  //   mini: "ET",
  //   component: EditTeam,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/team-details",
  //   name: "Team Details",
  //   mini: "TD",
  //   component: TeamDetails,
  //   layout: "/user",
  //   redirect: true,
  // },
  {
    path: '/members',
    group: 'Admin',
    name: 'Members',
    icon: 'membersIcon',
    component: Members,
    layout: '/user',
  },
  {
    path: '/member-add-update',
    name: 'MembersAddUpdate',
    component: AddMember,
    layout: '/user',
    redirect: true,
  },
  {
    path: '/add-user-requrest',
    name: 'AddUserRequrest',
    component: CreateRequest,
    layout: '/user',
    redirect: true,
  },
  {
    path: '/member-details',
    name: 'MemberDetails',
    component: MemberDetails,
    layout: '/user',
    redirect: true,
  },
  {
    path: '/user-details',
    name: 'UserDetails',
    component: UserDetails,
    layout: '/user',
    redirect: true,
  },
  {
    path: '/user-update/user-delete-request',
    name: 'UserDetails',
    component: UserDetails,
    layout: '/user',
    redirect: true,
  },
  // {
  //   path: "/member-invitation",
  //   name: "MemberInvitation",
  //   component: MemberInvitation,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/public-api",
  //   name: "PublicApi",
  //   component: PublicApi,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/payroll",
  //   group: "Admin",
  //   name: "Payroll",
  //   icon: "payrollIcon",
  //   component: Payroll,
  //   layout: "/user",
  // },
  // {
  //   path: "/integrations",
  //   group: "Admin",
  //   name: "Integrations",
  //   icon: "integrationsIcon",
  //   component: IntegrationLayout,
  //   layout: "/user",
  //   new: true,
  // },
  // {
  //   path: "/organizations",
  //   group: "Admin",
  //   name: "Organizations",
  //   icon: "organizationsIcon",
  //   component: Organizations,
  //   layout: "/user",
  // },
  // {
  //   path: "/add-organization",
  //   name: "Add Organization",
  //   mini: "aw",
  //   component: AddOrganization,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/asana-permission",
  //   name: "AsanaPermission",
  //   component: AsanaPermission,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/asana-install",
  //   name: "Asana Install",
  //   component: AsanaInstall,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/asana-configure-integration",
  //   name: "Asana Install",
  //   component: AsanaCreateIntegration,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/configure-integration",
  //   name: "ConfigureIntegration",
  //   component: ConfigureIntegration,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/select-integration-project",
  //   name: "SelectIntegrationProject",
  //   component: SelectIntegrationProject,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/integration-connect-members",
  //   name: "IntegrationConnectMembers",
  //   component: IntegrationConnectMembers,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/settings",
  //   group: "Admin",
  //   name: "Settings",
  //   icon: "settingsIcon",
  //   component: Settings,
  //   layout: "/user",
  //   // redirect: true,
  // },
  // {
  //   path: "/current-plan",
  //   name: "Current Plan",
  //   mini: "CP",
  //   component: CurrentPlan,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/subscription",
  //   name: "Subscription",
  //   mini: "SC",
  //   component: Subscription,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/activity-settings",
  //   name: "Activity Settings",
  //   mini: "AS",
  //   component: ActivitySettings,
  //   layout: "/user",
  //   redirect: true,
  // },
  {
    path: '/location-settings',
    name: 'Location Settings',
    mini: 'LS',
    component: LocationSettings,
    layout: '/user',
    redirect: true,
  },
  {
    path: '/geofence-settings',
    name: 'Geofence Settings',
    mini: 'GS',
    component: GeofenceSettings,
    layout: '/user',
    redirect: true,
  },
  // {
  //   path: "/timesheet-settings",
  //   name: "Timesheet Settings",
  //   mini: "TS",
  //   component: TimesheetSettings,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/upgrade-plan",
  //   name: "Upgrade Plan",
  //   mini: "UP",
  //   component: UpgradePlan,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/checkout",
  //   name: "Checkout",
  //   mini: "CH",
  //   component: Checkout,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/paddle-payment-checkout",
  //   name: "Paddle Checkout",
  //   mini: "PC",
  //   component: PaddleCheckout,
  //   layout: "/user",
  //   redirect: true,
  // },
];

export default routes;
