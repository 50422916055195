import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Plus from '../../../assets/img/icons/plus_white.svg';
import BottomPagination from '../../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../../components/Pagination/TopPagination/TopPagination';
import {
  CommonGrid,
  HeaderContainer,
  PageTitle,
} from '../../../styledComponents/common';
import UsersTable from './DeleteRequestTable';
import UsersFilter from './UsersFilter';

const DeleteRequest = ({
  officesShortList,
  getOfficesShortList,
  getUserRequests,
  history,
  membersList,
  isLoading,
  // pagination
  totalMembersCount,
  deleteUserRequest,
  pageSize,
  deletedUserId,
  isDeleting,
  rejectUserRequest,
  rejectRequestLoading,
  rejectedUserId,
}) => {
  const { t } = useTranslation();
  const [memberList, setMemberList] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState('');

  const [userCodeSearchTerm, setUserCodeSearchTerm] = useState('');
  const [currentUserCodeSearchedTerm, setCurrentUserCodeSearchedTerm] =
    useState('');

  const [selectedRole, setSelectedRole] = useState({
    label: t('all_roles'),
    value: null,
  });

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const handleDeleteRequest = selectedUser => {
    deleteUserRequest({ user_id: selectedUser.original_user_id });
  };

  useEffect(() => {
    setSelectedOffice({
      label: t('all_offices'),
      value: null,
    });
    getUserRequests({ page: 1, action_status: 'delete' });
  }, []);

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      setMemberList(membersList);
    } else if (membersList && membersList.length === 0) {
      setMemberList([]);
    }
  }, [membersList]);

  const handleSearchByName = () => {
    const payload = {
      page: 1,
      action_status: 'delete',
    };
    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm('');
    }
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (selectedRole && selectedRole.value) {
      payload.role = selectedRole.value;
    }
    if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
      payload.user_code = userCodeSearchTerm.toLowerCase().trim();
    }
    getUserRequests(payload);

    setCurrentPageNumber(1);
  };
  const handleClearSearch = () => {
    if (currentSearchedTerm) {
      const payload = {
        page: 1,
        action_status: 'delete',
      };
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }
      if (selectedRole && selectedRole.value) {
        payload.role = selectedRole.value;
      }
      if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
        payload.user_code = userCodeSearchTerm.toLowerCase().trim();
      }
      getUserRequests(payload);

      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setSearchTerm('');
      setCurrentSearchedTerm('');
    }, 100);
  };

  const handleSearchByUserCode = () => {
    const payload = {
      page: 1,
      action_status: 'delete',
    };
    if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
      payload.user_code = userCodeSearchTerm.toLowerCase().trim();
      setCurrentUserCodeSearchedTerm(userCodeSearchTerm);
    } else {
      setCurrentUserCodeSearchedTerm('');
    }
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (selectedRole && selectedRole.value) {
      payload.role = selectedRole.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
    }
    getUserRequests(payload);

    setCurrentPageNumber(1);
  };
  const handleClearUserCodeSearch = () => {
    if (currentUserCodeSearchedTerm) {
      const payload = {
        page: 1,
        action_status: 'delete',
      };
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }
      if (selectedRole && selectedRole.value) {
        payload.role = selectedRole.value;
      }
      if (searchTerm && searchTerm.trim()) {
        payload.name = searchTerm.toLowerCase().trim();
      }
      getUserRequests(payload);

      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setUserCodeSearchTerm('');
      setCurrentUserCodeSearchedTerm('');
    }, 100);
  };

  const handlePageChange = page => {
    // console.log(page);
    const payload = {
      page: page,
      action_status: 'delete',
    };
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
    }
    if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
      payload.user_code = userCodeSearchTerm.toLowerCase().trim();
    }
    if (selectedRole && selectedRole.value) {
      payload.role = selectedRole.value;
    }
    getUserRequests(payload);
    setCurrentPageNumber(page);
  };

  const selectOffice = e => {
    const payload = {
      page: 1,
      action_status: 'delete',
    };
    if (e && e.value) {
      payload.office_id = e.value;
    }
    if (selectedRole && selectedRole.value) {
      payload.role = selectedRole.value;
    }
    getUserRequests(payload);
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setUserCodeSearchTerm('');
    setCurrentUserCodeSearchedTerm('');
    setSelectedOffice(e);
  };

  const handleRoleChange = e => {
    const payload = {
      page: 1,
      action_status: 'delete',
    };
    if (e && e.value) {
      payload.role = e.value;
    }
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
    }
    if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
      payload.user_code = userCodeSearchTerm.toLowerCase().trim();
    }
    getUserRequests(payload);
    setCurrentPageNumber(1);
    setSelectedRole(e);
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const onUserCodeSearchTermChange = e => {
    const { value } = e.target;
    setUserCodeSearchTerm(value);
  };

  return (
    <div className="content">
      <CommonGrid alignItem="center">
        <PageTitle>{t('delete_request')}</PageTitle>
      </CommonGrid>

      <HeaderContainer columns="1fr auto">
        <UsersFilter
          officesShortList={officesShortList}
          getOfficesShortList={getOfficesShortList}
          selectedOffice={selectedOffice}
          selectOffice={selectOffice}
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          handleSearchByName={handleSearchByName}
          handleClearSearch={handleClearSearch}
          userCodeSearchTerm={userCodeSearchTerm}
          onUserCodeSearchTermChange={onUserCodeSearchTermChange}
          handleSearchByUserCode={handleSearchByUserCode}
          handleClearUserCodeSearch={handleClearUserCodeSearch}
          selectedRole={selectedRole}
          onRoleChange={handleRoleChange}
        />
      </HeaderContainer>

      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <TopPagination
          itemName={t('members')}
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}

      <UsersTable
        membersList={memberList}
        history={history}
        isLoading={isLoading}
        handleDeleteRequest={handleDeleteRequest}
        isDeleting={isDeleting}
        deletedUserId={deletedUserId}
        rejectUserRequest={rejectUserRequest}
        rejectRequestLoading={rejectRequestLoading}
        rejectedUserId={rejectedUserId}
      />
      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export default DeleteRequest;
