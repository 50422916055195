import { Modal, ModalBody } from "reactstrap";

const EvidenceModal = ({ isOpen, toggle, isLoading, evidence, width }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `5vh`, width: `70vw`, maxWidth: `512px` }}
    >
      <ModalBody style={{ padding: `10px`, display: `grid` }}>
        <img
          src={evidence}
          alt=""
          style={{ maxHeight: "70vh", margin: `0 auto` }}
        />
      </ModalBody>
    </Modal>
  );
};

export default EvidenceModal;
