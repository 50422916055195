import React from 'react';

const AudioPlayer = ({audio}) => {
    return (
        <audio controls>
            <source src={audio} type="audio/mp4"/>
        </audio>
    );

};

export default AudioPlayer;