import { connect } from 'react-redux';

import { getOfficesShortList } from '../Offices/officesActions';
// Actions
import { addTaskLocation, getTaskLocation } from '../Tasks/tasksActions';
// Component
import TaskLocations from './TaskLocations';

// Store props to map with current state
const mapStateToProps = state => ({
  officesShortList: state.office.officesShortList,
  // loading
  locationListIsLoading: state.task.locationListIsLoading,
  addTaskLocationLoading: state.task.addTaskLocationLoading,

  // data
  locationsList: state.task.locationsList,
  createdTaskLocationInfo: state.task.createdTaskLocationInfo,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getTaskLocation: params => dispatch(getTaskLocation(params)),
  addTaskLocation: params => dispatch(addTaskLocation(params)),
  getOfficesShortList: params => dispatch(getOfficesShortList(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TaskLocations);
