import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import arrowWhiteIcon from '../../assets/img/icons/arrow_right_white.svg';
import deleteIcon from '../../assets/img/icons/delete.svg';
import duplicateIcon from '../../assets/img/icons/duplicate-new.svg';
import editIcon from '../../assets/img/icons/edit-pen.svg';
import tick from '../../assets/img/icons/tick-white-bold.svg';
import { CardText } from '../../components/DashboardCards/cardStyles';
import DropdownLoader from '../../components/DropdownLoader/DropdownLoader';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import AssigneeTooltip from '../../components/Tooltip/AssigneeTooltip';
import CommonTooltip from '../../components/Tooltip/CommonTooltip';
import TaskActionTooltip from '../../components/Tooltip/TaskActionTooltip';
import {
  ActionSection,
  BudgetCollapseSection,
  ClientDeadlineGrid,
  ListUpperSection,
  ProjectDuplicateDiv,
  ProjectDuplicateIcon,
  ProjectItemCount,
  ProjectListItem,
  ProjectListSection,
  ProjectTableContainer,
  ProjectTitleDiv,
  TableItem,
  TableRowAndBudgetCollapseContainer,
} from '../../styledComponents/ProjectsNew';
import { PrimaryButton } from '../../styledComponents/buttons';
import {
  ColoredText,
  CommonImage,
  CommonText,
  Container,
  TableText,
} from '../../styledComponents/common';
import { AssigneeRoundImage } from '../../styledComponents/createProject';
import {
  IntegrationIcon,
  IntegrationIconDiv,
} from '../../styledComponents/tasks';
import { checkHtml, checkUrl } from '../../utils/helper';
import { toHHMMSS } from '../../utils/helper';
import {
  DeleteButton,
  EditButton,
} from '../FieldServices/JobSites/jobSitesStyles';
import {
  CardBlock,
  GreenCircle,
  LateTag,
} from '../FieldServices/RouteMapNew/routeMapStyles';
import {
  ExpandIcon,
  ProjectExpandButton,
  ProjectListStatusBadge,
} from '../Projects/projectsStyles';
import { CommDropdownComp } from './AddAssigneeDropdown';
import {
  ActionIconSection,
  ApprovalBadge,
  AssigneeRoundImg,
  CollapseSection,
  RoundIcon,
  TaskInfoSection,
  TaskStatusButton,
} from './tasksStyles';

const TasksList = props => {
  const [duplicateTooltip, setDuplicateTooltip] = useState(null);

  const {
    selectedOrganization,
    expandedTaskId,
    setExpandedTaskId,
    visibilityChange,
    loadingTaskAssigneeId,
    toggleDeleteModal,
    isLoading,
    tasksList,
    createTask,
    history,
  } = props;

  const projectBudgetAndBillableIsLoading = false;
  const [isCheckInOut, setIsCheckInOut] = useState(false);

  const updateAssingeeList = (person, projectId, taskId) => {
    // if (
    //   selectedOrganization &&
    //   selectedOrganization.id &&
    //   projectId &&
    //   taskId &&
    //   person &&
    //   person.id
    // ) {
    //   const payload = {
    //     organization_id: selectedOrganization.id,
    //     project_id: projectId,
    //     task_id: taskId,
    //     user_id: person.id,
    //   };
    //   addTaskAssignee(payload);
    // }
  };

  const handleDeleteAssignee = (assigneeId, projectId, taskId) => {
    // if (
    //   selectedOrganization &&
    //   selectedOrganization.id &&
    //   projectId &&
    //   taskId &&
    //   assigneeId
    // ) {
    //   const payload = {
    //     organization_id: selectedOrganization.id,
    //     project_id: projectId,
    //     task_id: taskId,
    //     user_id: assigneeId,
    //   };
    //   deleteTaskAssignee(payload);
    // }
  };

  const handleExpandBudgetSection = taskId => {
    if (expandedTaskId && expandedTaskId === taskId) {
      setExpandedTaskId(null);
    } else {
      setExpandedTaskId(taskId);
    }
  };

  const duplicateTask = task => {
    const payload = {
      name: task.name,
      office_id: task.office.id,
      description: task.description,
      location: task.location ? task.location.id : null,
      task_type: task.task_type && task.task_type.id ? task.task_type.id : null,
      required_start_date: task.required_start_date,
      required_end_date: task.required_end_date,
    };
    createTask(payload);
  };

  const { t, i18n } = useTranslation();

  return (
    <Container style={{ paddingBottom: '20px' }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : tasksList && tasksList.length > 0 ? (
        <>
          <ProjectTableContainer heading>
            <TableItem>
              <TableText>{t('task_name')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('task_code')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('assignee')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('start_end')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('status')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('approval')}</TableText>
            </TableItem>
            <TableItem />
          </ProjectTableContainer>
          {tasksList.map((task, index) => (
            <TableRowAndBudgetCollapseContainer key={task.id}>
              <ProjectTableContainer>
                <TableItem
                  style={{
                    gridTemplateColumns: 'auto auto',
                    alignItems: 'center',
                    gridColumnGap: '10px',
                  }}
                >
                  <TableText
                    // hoverPointer
                    // hoverUnderline
                    name
                    fontWeight={
                      expandedTaskId && expandedTaskId === task.id && '600'
                    }
                    // onClick={() =>
                    //   history.push(`/user/task-details?id=${task.id}`)
                    // }
                  >
                    {task.name}
                  </TableText>
                </TableItem>
                <TableItem>
                  <TableText name>{task.task_code || 'N/A'}</TableText>
                </TableItem>
                <TableItem padding="10px 0 10px 10px">
                  {task.assignees &&
                  task.assignees.length > 0 &&
                  task.assignees[0].id ? (
                    <AssigneeRoundImg
                      image={task.assignees[0].avatar || avatar5}
                    >
                      <AssigneeTooltip
                        toolTipText={`${task.assignees[0].first_name}${
                          task.assignees[0].last_name
                            ? ` ${task.assignees[0].last_name}`
                            : ``
                        }`}
                        phone={task.assignees[0].phone}
                        top="-60px"
                        left="-55px"
                      />
                    </AssigneeRoundImg>
                  ) : null}
                </TableItem>
                <TableItem>
                  <TableText name>
                    {task.required_start_date &&
                      moment(task.required_start_date).format('h:mm a')}{' '}
                    -{' '}
                    {task.required_end_date &&
                      moment(task.required_end_date).format('h:mm a')}
                  </TableText>
                </TableItem>
                <TableItem>
                  <TaskStatusButton status={task.status}>
                    {task.status === 'to do'
                      ? t('to_do')
                      : task.status === 'complete'
                      ? t('complete')
                      : task.status === 'in progress'
                      ? t('in_progress')
                      : task.status === 'partially complete'
                      ? t('partially_complete')
                      : t('totally_missed')}
                  </TaskStatusButton>
                </TableItem>
                <TableItem>
                  {task.approval &&
                    task.approval.status &&
                    (task.status === 'complete' ||
                      task.status === 'partially complete') && (
                      <ApprovalBadge status={task.approval.status}>
                        {t(task.approval.status)}
                      </ApprovalBadge>
                    )}
                </TableItem>
                <TableItem>
                  {localStorage.getItem('user_role') !== 'employee' &&
                    !localStorage.getItem('viewer_role') && (
                      <ActionIconSection>
                        {task.status &&
                        task.status === 'to do' &&
                        moment().isBefore(moment(task.required_start_date)) ? (
                          <RoundIcon
                            type="edit"
                            onClick={() =>
                              history.push(`/user/edit-task?id=${task.id}`)
                            }
                          >
                            <CommonImage src={editIcon} alt="" size="16px" />
                            <TaskActionTooltip toolTipText={t('edit_task')} />
                          </RoundIcon>
                        ) : (
                          <div />
                        )}
                        <RoundIcon
                          type="duplicate"
                          onClick={() =>
                            history.push(`/user/create-task?id=${task.id}`)
                          }
                        >
                          <CommonImage src={duplicateIcon} alt="" size="16px" />
                          <TaskActionTooltip toolTipText={t('duplicate')} />
                        </RoundIcon>
                        {moment(task.required_start_date)
                          .add(30, 'minutes')
                          .isAfter(moment()) && task.status === 'to do' ? (
                          <RoundIcon onClick={() => toggleDeleteModal(task.id)}>
                            <CommonImage src={deleteIcon} alt="" size="16px" />
                            <TaskActionTooltip toolTipText={t('delete_task')} />
                          </RoundIcon>
                        ) : (
                          <div />
                        )}
                      </ActionIconSection>
                    )}
                </TableItem>
                <TableItem>
                  <ProjectExpandButton
                    onClick={() => handleExpandBudgetSection(task.id)}
                  >
                    <ExpandIcon
                      src={arrowWhiteIcon}
                      alt=""
                      open={expandedTaskId && expandedTaskId === task.id}
                    />
                  </ProjectExpandButton>
                </TableItem>
              </ProjectTableContainer>
              <CollapseSection
                open={expandedTaskId && expandedTaskId === task.id}
              >
                <TaskInfoSection>
                  <CardBlock>
                    <CardText name fontSize="13px" title nowrap>
                      {task.required_start_date &&
                        moment(task.required_start_date).format('h:mm a')}{' '}
                      -{' '}
                      {task.required_end_date &&
                        moment(task.required_end_date).format('h:mm a')}
                    </CardText>
                    <CardText fontSize="12px" label title>
                      {t('start_end')}
                    </CardText>
                  </CardBlock>
                  <CardBlock>
                    {/* <CardText name fontSize="13px" title nowrap>
                      {task.checkinout && task.checkinout.length > 0
                        ? task.checkinout.map((item, i) => {
                            if (item.event === "checkin") {
                              return (
                                <React.Fragment key={i}>
                                  {moment.unix(item.timestamp).format("h:mm a")}
                                  {moment
                                    .unix(item.timestamp)
                                    .isAfter(
                                      moment(task.required_start_date)
                                    ) ? (
                                    <LateTag>{t("late")}</LateTag>
                                  ) : (
                                    <GreenCircle>
                                      <img src={tick} alt="" width="100%" />
                                    </GreenCircle>
                                  )}
                                </React.Fragment>
                              );
                            } else {
                              return null;
                            }
                          })
                        : `-`}
                    </CardText> */}
                    <CardText name fontSize="13px" title nowrap>
                      {task.checkinout &&
                      task.checkinout.length > 0 &&
                      task.checkinout.filter(i => i.event === 'checkin')
                        .length > 0
                        ? task.checkinout
                            .filter(i => i.event === 'checkin')
                            .map((item, i) => {
                              if (i === 0) {
                                return (
                                  <React.Fragment key={i}>
                                    {moment
                                      .unix(item.timestamp)
                                      .format('h:mm a')}
                                    {moment
                                      .unix(item.timestamp)
                                      .isAfter(
                                        moment(task.required_start_date),
                                      ) ? (
                                      <LateTag>{t('late')}</LateTag>
                                    ) : (
                                      <GreenCircle>
                                        <img src={tick} alt="" width="100%" />
                                      </GreenCircle>
                                    )}
                                  </React.Fragment>
                                );
                              }
                              return null;
                            })
                        : `-`}
                    </CardText>
                    <CardText fontSize="12px" label title>
                      {t('checked_in')}
                    </CardText>
                  </CardBlock>
                  <CardBlock>
                    {/* <CardText name fontSize="13px" title nowrap>
                      {task.checkinout && task.checkinout.length > 0
                        ? task.checkinout.map((item, i) => {
                            if (item.event === "checkout") {
                              return (
                                <React.Fragment key={i}>
                                  {moment.unix(item.timestamp).format("h:mm a")}
                                  {moment
                                    .unix(item.timestamp)
                                    .isAfter(moment(task.required_end_date)) ? (
                                    <LateTag>{t("late")}</LateTag>
                                  ) : (
                                    <GreenCircle>
                                      <img src={tick} alt="" width="100%" />
                                    </GreenCircle>
                                  )}
                                </React.Fragment>
                              );
                            } else {
                              return null;
                            }
                          })
                        : `-`}
                    </CardText> */}
                    <CardText name fontSize="13px" title nowrap>
                      {task.checkinout &&
                      task.checkinout.length > 1 &&
                      task.checkinout.filter(i => i.event === 'checkout')
                        .length > 0
                        ? task.checkinout
                            .filter(i => i.event === 'checkout')
                            .map((item, i) => {
                              if (i === 0) {
                                return (
                                  <React.Fragment key={i}>
                                    {moment
                                      .unix(item.timestamp)
                                      .format('h:mm a')}
                                    {moment
                                      .unix(item.timestamp)
                                      .isAfter(
                                        moment(task.required_end_date),
                                      ) ? (
                                      <LateTag>{t('late')}</LateTag>
                                    ) : (
                                      <GreenCircle>
                                        <img src={tick} alt="" width="100%" />
                                      </GreenCircle>
                                    )}
                                  </React.Fragment>
                                );
                              }
                              return null;
                            })
                        : `-`}
                    </CardText>
                    <CardText fontSize="12px" label title>
                      {t('checked_out')}
                    </CardText>
                  </CardBlock>
                  <CardBlock>
                    <CardText name fontSize="13px" title nowrap>
                      {task.checkinout &&
                      task.checkinout.length > 1 &&
                      task.checkinout.filter(i => i.event === 'checkin')
                        .length > 0 &&
                      task.checkinout.filter(i => i.event === 'checkout')
                        .length > 0
                        ? toHHMMSS(
                            task.checkinout.filter(
                              i => i.event === 'checkout',
                            )[0].timestamp -
                              task.checkinout.filter(
                                i => i.event === 'checkin',
                              )[0].timestamp,
                          )
                        : `-`}
                    </CardText>
                    <CardText fontSize="12px" label title>
                      {t('worked')}
                    </CardText>
                  </CardBlock>
                  {task.task_type && task.task_type.id && (
                    <CardBlock>
                      <CardText name fontSize="13px" title>
                        {i18n.language && i18n.language === 'en'
                          ? task.task_type.name
                          : task.task_type.arabic_name}
                      </CardText>
                      <CardText fontSize="12px" label title>
                        {t('task_type')}
                      </CardText>
                    </CardBlock>
                  )}

                  <CardBlock>
                    <CardText name fontSize="13px" title>
                      {task.location &&
                        task.location.address &&
                        task.location.address}
                    </CardText>
                    <CardText fontSize="12px" label title>
                      {t('location_address')}
                    </CardText>
                  </CardBlock>
                </TaskInfoSection>
              </CollapseSection>
            </TableRowAndBudgetCollapseContainer>
          ))}
        </>
      ) : (
        <NoDataComponent
          title={t('no_tasks_found')}
          padding="5vh 0"
          imageHeight="140px"
        />
      )}
    </Container>
  );
};

export default TasksList;
