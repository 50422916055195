import { t } from 'i18next';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import {
  ModalDeleteButton,
  WhiteButton,
} from '../../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonText,
  FormAlert,
  InputLabel,
  InputTextArea,
  InputWithLabelSection,
  RedStar,
} from '../../../styledComponents/common';
import { checkHtml, checkUrl, toHHMMSS } from '../../../utils/helper';
import DeleteTimeRangeComponent from '../DeleteTimeRangeComponent';
import { getHoursAndMinutes } from './ManualTimeModal';

const DeleteModal = styled(Modal)`
  max-width: 520px;
  margin-top: 20vh;
`;
const DeleteModalHeader = styled(ModalHeader)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  padding: 25px;
`;
const DeleteModalBody = styled(ModalBody)`
  padding: 15px 25px;
`;
const ModalButtonSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  grid-column-gap: 12px;
  margin-top: 20px;
`;

const DeleteTimeModal = props => {
  const [reason, setReason] = useState('');
  const [saveErrors, setSaveErrors] = useState({});

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const {
    isOpen,
    currentTimesheet,
    handleDeleteTimesheet,
    toggle,
    isLoading,
    timezone,
  } = props;

  useEffect(() => {
    if (!isOpen) {
      setReason('');
      setSaveErrors({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (currentTimesheet && currentTimesheet.id) {
      setStartTime(currentTimesheet.start_time);
      setEndTime(currentTimesheet.end_time);
    }
  }, [currentTimesheet]);

  const getHours = time => {
    let hours = time.split(':');
    return `${hours[0]} ${parseInt(hours[0]) < 1 ? 'hour' : 'hours'} ${
      hours[1]
    } ${parseInt(hours[1]) < 1 ? 'minute' : 'minutes'}`;
  };

  const getDuration = duration => {
    // let hour = parseInt(duration.split(":")[0]);
    // let minutes = parseInt(duration.split(":")[1]);

    // return `${hour} ${hour < 2 ? "hour" : "hours"} ${minutes} minutes`;
    return getHours(toHHMMSS(duration));
  };

  const handleStartTime = (date, time) => {
    if (date && time) {
      const formatted_time = time.replace(/ /g, '');
      const start_time = `${date}T${formatted_time}`;
      if (timezone) {
        setStartTime(moment.tz(start_time, 'YYYY-MM-DDTh:mmA', timezone));
      } else {
        setStartTime(moment(start_time, 'YYYY-MM-DDTh:mmA'));
      }
    } else {
      setStartTime('');
    }
    if (saveErrors && saveErrors['time']) {
      const errors = { ...saveErrors };
      delete errors['time'];
      setSaveErrors(errors);
    }
  };
  const handleEndTime = (date, time) => {
    if (date && time) {
      const formatted_time = time.replace(/ /g, '');
      const end_time = `${date}T${formatted_time}`;
      if (timezone) {
        setEndTime(moment.tz(end_time, 'YYYY-MM-DDTh:mmA', timezone));
      } else {
        setEndTime(moment(end_time, 'YYYY-MM-DDTh:mmA'));
      }
    } else {
      setEndTime('');
    }
    if (saveErrors && saveErrors['time']) {
      const errors = { ...saveErrors };
      delete errors['time'];
      setSaveErrors(errors);
    }
  };

  const checkError = () => {
    let formIsValid = true;
    const saveErrors = {};
    if (checkUrl(reason)) {
      formIsValid = false;
      saveErrors['reason'] = 'Reason can not contain url.';
    } else if (checkHtml(reason)) {
      formIsValid = false;
      saveErrors['reason'] = 'Invalid input in reason.';
    } else if (!startTime || !endTime) {
      formIsValid = false;
      saveErrors['time'] = 'Please select start and end time.';
    } else if (
      startTime &&
      currentTimesheet &&
      startTime.isBefore(currentTimesheet.start_time, 'minutes')
    ) {
      formIsValid = false;
      saveErrors['time'] = 'Invalid time input.';
    } else if (
      endTime &&
      currentTimesheet &&
      endTime.isAfter(currentTimesheet.end_time, 'minutes')
    ) {
      formIsValid = false;
      saveErrors['time'] = 'Invalid time input.';
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleDeleteTime = () => {
    if (
      currentTimesheet &&
      currentTimesheet.id &&
      startTime &&
      endTime &&
      checkError()
    ) {
      handleDeleteTimesheet(
        currentTimesheet.id,
        startTime.isSame(currentTimesheet.start_time, 'minutes')
          ? currentTimesheet.start_time
          : startTime,
        endTime.isSame(currentTimesheet.end_time, 'minutes')
          ? currentTimesheet.end_time
          : endTime,
        reason,
      );
    }
  };

  return (
    <DeleteModal isOpen={isOpen} toggle={toggle}>
      <DeleteModalHeader>
        {currentTimesheet && (
          <CommonFlex justifyContent="space-between">
            <CommonText title>
              {`${currentTimesheet.start_time.format(
                'h:mm A',
              )} to ${currentTimesheet.end_time.format('h:mm A')}`}
            </CommonText>
            {/* <CommonText title>Duration: {duration}</CommonText> */}
          </CommonFlex>
        )}
      </DeleteModalHeader>
      <DeleteModalBody>
        <CommonGrid
          columns="auto 45% auto"
          margin="10px 0 20px"
          alignItem="center"
        >
          <CommonText>Delete Time</CommonText>
          <CommonGrid columns="100%" style={{ gridRowGap: '3px' }}>
            <DeleteTimeRangeComponent
              startTime={currentTimesheet && currentTimesheet.start_time}
              setStartTime={handleStartTime}
              endTime={currentTimesheet && currentTimesheet.end_time}
              setEndTime={handleEndTime}
            />
            {saveErrors &&
              saveErrors['time'] &&
              saveErrors['time'] !== undefined && (
                <FormAlert margin="0">{saveErrors['time']}</FormAlert>
              )}
          </CommonGrid>

          {moment(endTime).isAfter(moment(startTime)) ? (
            <ColoredText type="blue" fontSize="13px">
              {getHoursAndMinutes(
                moment(endTime).diff(moment(startTime), 'minutes'),
              )}
            </ColoredText>
          ) : (
            <ColoredText type="error" fontSize="11.5px">
              {t('end_time_must_be_after_start_time')}
            </ColoredText>
          )}
        </CommonGrid>

        <InputWithLabelSection>
          <InputLabel>
            Reason <RedStar>*</RedStar>
          </InputLabel>
          <InputTextArea
            id="reason-text-area"
            placeholder="Why do you want to delete the timesheet?"
            value={reason}
            onChange={e => setReason(e.target.value)}
            height="100px"
            error={
              saveErrors &&
              saveErrors['reason'] &&
              saveErrors['reason'] !== undefined
            }
          />
          {saveErrors &&
            saveErrors['reason'] &&
            saveErrors['reason'] !== undefined && (
              <FormAlert margin="0 0 0 5px">{saveErrors['reason']}</FormAlert>
            )}
        </InputWithLabelSection>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          <ModalDeleteButton
            padding={isLoading && '0 10px'}
            disabled={!reason.trim()}
            onClick={() => handleDeleteTime()}
          >
            {isLoading ? (
              <ButtonTextLoader
                loadingText="Deleting"
                fontSize="14px"
                fontWeight="400"
              />
            ) : (
              'Delete'
            )}
          </ModalDeleteButton>
        </ModalButtonSection>
      </DeleteModalBody>
    </DeleteModal>
  );
};

export default DeleteTimeModal;
