import moment from 'moment-timezone';

import {
  GET_ROUTE_MAP_LOCATIONS,
  GET_ROUTE_MAP_LOCATIONS_FAILURE,
  GET_ROUTE_MAP_LOCATIONS_SUCCESS,
  GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST,
  GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST_FAILURE,
  GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST_SUCCESS,
  GET_TASK_CHECK_IN_OUT_LIST,
  GET_TASK_CHECK_IN_OUT_LIST_FAILURE,
  GET_TASK_CHECK_IN_OUT_LIST_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
} from '../../../modules/constants';
import { createNotification } from '../../../modules/notificationManager';

export const handleRouteMapLocationsRequest = (state, action) => {
  return {
    ...state,
    locationsIsLoading: true,
  };
};
export const handleRouteMapLocationsRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 200 && data.data) {
    let locationsList = JSON.parse(JSON.stringify(data.data));

    return {
      ...state,
      locationsIsLoading: false,
      routeMapLocations: locationsList.sort(
        (a, b) => a.timestamp - b.timestamp,
      ),
    };
  }
  return {
    ...state,
    locationsIsLoading: false,
    routeMapLocations: [],
  };
};
export const handleRouteMapLocationsRequestFailure = (state, action) => {
  return {
    ...state,
    locationsIsLoading: false,
    routeMapLocations: [],
  };
};

export const handleSingleEmployeeClockInOutListRequest = (state, action) => {
  return {
    ...state,
  };
};
export const handleSingleEmployeeClockInOutListRequestSuccess = (
  state,
  action,
) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 200 && data.data) {
    let list = JSON.parse(JSON.stringify(data.data));

    // console.log(list);

    let result = [];
    let lastAttendanceTimestamp = 0;

    // const clockInOutPairs = [];
    if (
      list.length > 0 &&
      list[0].attendances &&
      list[0].attendances.length > 0
    ) {
      result = list[0].attendances.sort((a, b) => a.timestamp - b.timestamp);
      const lastAttendance = result[result.length - 1];
      if (lastAttendance?.clockout_timestamp) {
        lastAttendanceTimestamp = lastAttendance.clockout_timestamp;
      } else {
        lastAttendanceTimestamp = moment
          .unix(lastAttendance.clockin_timestamp)
          .endOf('day')
          .unix();
        // lastAttendanceTimestamp = lastAttendance.clockin_timestamp;
      }
    }

    return {
      ...state,
      clockInOutList: result,
      lastAttendanceTimestamp,
    };
  }
  return {
    ...state,
    clockInOutList: [],
    lastAttendanceTimestamp: 0,
  };
};
export const handleSingleEmployeeClockInOutListRequestFailure = (
  state,
  action,
) => {
  return {
    ...state,
    clockInOutList: [],
    lastAttendanceTimestamp: 0,
  };
};

export const handleTaskCheckInOutListRequest = (state, action) => {
  return {
    ...state,
  };
};
export const handleTaskCheckInOutListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 200 && data.data) {
    let list = JSON.parse(JSON.stringify(data.data));

    // console.log(list);

    return {
      ...state,
      taskCheckInOutList: list.sort((a, b) => a.timestamp - b.timestamp),
    };
  }
  return {
    ...state,
    taskCheckInOutList: [],
  };
};
export const handleTaskCheckInOutListRequestFailure = (state, action) => {
  return {
    ...state,
    taskCheckInOutList: [],
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...initialState,
  };
};

const ACTION_HANDLERS = {
  [GET_ROUTE_MAP_LOCATIONS]: handleRouteMapLocationsRequest,
  [GET_ROUTE_MAP_LOCATIONS_SUCCESS]: handleRouteMapLocationsRequestSuccess,
  [GET_ROUTE_MAP_LOCATIONS_FAILURE]: handleRouteMapLocationsRequestFailure,

  [GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST]:
    handleSingleEmployeeClockInOutListRequest,
  [GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST_SUCCESS]:
    handleSingleEmployeeClockInOutListRequestSuccess,
  [GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST_FAILURE]:
    handleSingleEmployeeClockInOutListRequestFailure,

  [GET_TASK_CHECK_IN_OUT_LIST]: handleTaskCheckInOutListRequest,
  [GET_TASK_CHECK_IN_OUT_LIST_SUCCESS]: handleTaskCheckInOutListRequestSuccess,
  [GET_TASK_CHECK_IN_OUT_LIST_FAILURE]: handleTaskCheckInOutListRequestFailure,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

const initialState = {
  locationsIsLoading: false,

  routeMapLocations: [],

  clockInOutList: [],
  lastAttendanceTimestamp: 0,

  taskCheckInOutList: [],

  // pagination
  paginationSize: 10,
};

export default function routeMapReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
