import { t } from 'i18next';
import {
  CREATE_USER_REQUEST,
  CREATE_USER_REQUEST_FAILURE,
  CREATE_USER_REQUEST_SUCCESS,
  DELETE_MEMBER_INVITATION,
  DELETE_MEMBER_INVITATION_FAILURE,
  DELETE_MEMBER_INVITATION_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_REQUEST_FAILURE,
  DELETE_USER_REQUEST_SUCCESS,
  GET_MEMBER_DETAILS,
  GET_MEMBER_DETAILS_FAILURE,
  GET_MEMBER_DETAILS_SUCCESS,
  GET_USERS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  GET_USER_UPDATE_REQUESTS,
  GET_USER_UPDATE_REQUESTS_FAILURE,
  GET_USER_UPDATE_REQUESTS_SUCCESS,
  REJECT_USER_REQUEST,
  REJECT_USER_REQUEST_FAILURE,
  REJECT_USER_REQUEST_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_REQUEST_FAILURE,
  UPDATE_USER_REQUEST_SUCCESS,
} from 'modules/constants';
import { createNotification } from 'modules/notificationManager';

// import { createNotification } from '../../../modules/notificationManager';

// ------------------------------------
// Action handler methods
// ------------------------------------

export const membersListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const membersListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    totalMembersCount: data.data.count,
    pageSize: data.data.page_size,
    members: data.data.results,
  };
};
export const membersListRequestFailureHandler = (state, action) => {
  createNotification(
    'error',
    'There was an error getting data. Please try again',
    3000,
  );
  return {
    ...state,
    isLoading: false,
    totalMembersCount: null,
    pageSize: null,
    members: [],
  };
};

export const deleteUserRequestHandler = (state, action) => {
  return {
    ...state,
    deletedUserId: null,
    isDeleting: true,
  };
};
export const deleteUserRequestSuccessHandler = (state, action) => {
  createNotification('success', 'Request deleted successfully', 3000);
  const {
    payload: { user_id },
  } = action;

  const newMembers = state.members.filter(
    member => member.original_user_id !== user_id,
  );
  return {
    ...state,
    deletedUserId: user_id,
    isDeleting: false,
    members: [...newMembers],
  };
};
export const deleteUserRequestFailureHandler = (state, action) => {
  createNotification(
    'error',
    'There was an error deleting data. Please try again',
    3000,
  );
  return {
    ...state,
    isDeleting: false,
  };
};

export const createUserRequestHandler = (state, action) => {
  return {
    ...state,
    createdUserId: null,
    createRequestLoading: true,
  };
};
export const createUserRequestSuccessHandler = (state, action) => {
  createNotification('success', t('request_created_successfully'), 3000);
  const {
    payload: { user_id },
  } = action;

  const newMembers = state.members.filter(member => member.id !== user_id);
  return {
    ...state,
    createRequestLoading: false,
    createdUserId: user_id,
    members: [...newMembers],
  };
};
export const createUserRequestFailureHandler = (state, action) => {
  createNotification(
    'error',
    'There was an error creating request. Please try again',
    3000,
  );
  return {
    ...state,
    createdUserId: null,
    createRequestLoading: false,
  };
};

export const rejectUserRequestHandler = (state, action) => {
  return {
    ...state,
    rejectedUserId: null,
    rejectRequestLoading: true,
  };
};
export const rejectUserRequestSuccessHandler = (state, action) => {
  createNotification('success', t('request_rejected_successfully'), 3000);
  const {
    payload: { user_id },
  } = action;

  const newMembers = state.members.filter(member => member.id !== user_id);
  const newUserUpdateRequests = state.userUpdateRequests.filter(
    userUpdateRequest => userUpdateRequest.new_data.id !== user_id,
  );
  return {
    ...state,
    userUpdateRequests: [...newUserUpdateRequests],
    rejectRequestLoading: false,
    rejectedUserId: user_id,
    members: [...newMembers],
  };
};
export const rejectUserRequestFailureHandler = (state, action) => {
  createNotification(
    'error',
    'There was an error rejecting request. Please try again',
    3000,
  );
  return {
    ...state,
    rejectedUserId: null,
    rejectRequestLoading: false,
  };
};

export const updateUserRequestHandler = (state, action) => {
  return {
    ...state,
    updateRequestLoading: true,
  };
};
export const updateUserRequestSuccessHandler = (state, action) => {
  createNotification('success', 'Request updated successfully', 3000);
  const {
    payload: { user_id },
  } = action;

  const newUserUpdateRequests = state.userUpdateRequests.filter(
    userUpdateRequest =>
      userUpdateRequest.new_data.original_user_id !== user_id,
  );
  return {
    ...state,
    updateRequestLoading: false,
    userUpdateRequests: [...newUserUpdateRequests],
  };
};
export const updateUserRequestFailureHandler = (state, action) => {
  createNotification(
    'error',
    'There was an error updating request. Please try again',
    3000,
  );
  return {
    ...state,
    updateRequestLoading: false,
  };
};

export const userUpdateRequestsListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const userUpdateRequestsListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    totalMembersCount: data.data.count,
    pageSize: data.data.page_size,
    userUpdateRequests: data.data.results,
  };
};
export const userUpdateRequestsListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    totalMembersCount: null,
    pageSize: null,
    userUpdateRequests: [],
  };
};

export const deleteMemberInvitationRequestHandler = (state, action) => {
  return {
    ...state,
    deleteInvitationLoading: true,
  };
};
export const deleteMemberInvitationRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    deleteInvitationLoading: false,
  };
};
export const deleteMemberInvitationRequestFailureHandler = (state, action) => {
  return {
    ...state,
    deleteInvitationLoading: false,
  };
};

export const membersDetailsRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const membersDetailsRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    memberDetails: data.data,
  };
};
export const membersDetailsRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    membersDetails: null,
  };
};

// create member
export const addMemberRequest = (state, action) => {
  return {
    ...state,
    createMemberLoading: true,
  };
};
export const addMemberRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification('success', t('member_created_successfully'), 2000);
    return {
      ...state,
      createMemberLoading: false,
      createdMemberInfo: data.data,
    };
  }
  return {
    ...state,
    createMemberLoading: false,
    createdMemberInfo: null,
  };
};
export const addMemberRequestFailure = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went Wrong.', 3000);
    return {
      ...state,
      createMemberLoading: false,
      createdMemberInfo: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification(
      'error',
      'Sorry! could not add member. Please try again.',
      2000,
    );
  }
  return {
    ...state,
    createMemberLoading: false,
    createdMemberInfo: null,
  };
};

export const updateMemberRequestHandler = (state, action) => {
  return {
    ...state,
    updateMemberLoading: true,
  };
};
export const updateMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  createNotification('success', 'User Details updated successfully.', 4000);
  if (data.status === 200 && data.data) {
    const details = JSON.parse(JSON.stringify(state.memberDetails));
    return {
      ...state,
      updateMemberLoading: false,
      updatedData: data.data,
    };
  }
  return {
    ...state,
    updateMemberLoading: false,
    updatedData: null,
  };
};
export const updateMemberRequestFailureHandler = (state, action) => {
  createNotification(
    'error',
    'Could not update member. Please try again!',
    3000,
  );
  return {
    ...state,
    updateMemberLoading: false,
    updatedData: null,
  };
};

export const deleteMemberRequestHandler = (state, action) => {
  return {
    ...state,
    deleteMemberLoading: true,
  };
};
export const deleteMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  createNotification('success', 'Member deleted successfully.', 3000);
  return {
    ...state,
    deleteMemberLoading: false,
  };
};
export const deleteMemberRequestFailureHandler = (state, action) => {
  createNotification(
    'error',
    'Could not delete member. Please try again!',
    3000,
  );
  return {
    ...state,
    deleteMemberLoading: false,
  };
};

export const clearMembersDetailsDataRequest = (state, action) => {
  return {
    ...state,
    memberDetails: null,
    weeklyEarning: null,
    monthlyEarning: null,
  };
};

export const handleRemoveMemberFromProject = (state, action) => {
  const { payload, userData } = action;

  if (payload.data && payload.data.status === 204) {
    if (
      state.memberDetails &&
      state.memberDetails.user &&
      state.memberDetails.user.id &&
      state.memberDetails.user.id === userData.userId &&
      userData.projectId &&
      state.memberDetails.projects &&
      state.memberDetails.projects.length > 0
    ) {
      const memberData = { ...state.memberDetails };
      memberData.projects = state.memberDetails.projects.filter(
        project => project.id !== userData.projectId,
      );
      return {
        ...state,
        memberDetails: { ...memberData },
      };
    }
  }
  return {
    ...state,
  };
};

export const handleRemoveMemberFromTeam = (state, action) => {
  const { payload, userData } = action;

  if (payload.data && payload.data.status === 204) {
    if (
      state.memberDetails &&
      state.memberDetails.user &&
      state.memberDetails.user.id &&
      state.memberDetails.user.id === userData.userId &&
      userData.teamId &&
      state.memberDetails.teams &&
      state.memberDetails.teams.length > 0
    ) {
      const memberData = { ...state.memberDetails };
      memberData.teams = state.memberDetails.teams.filter(
        team => team.id !== userData.teamId,
      );
      return {
        ...state,
        memberDetails: { ...memberData },
      };
    }
  }
  return {
    ...state,
  };
};

export const handleSetPaymentConfiguration = (state, action) => {
  return {
    ...state,
    paymentConfigurationLoading: true,
  };
};
export const handleSetPaymentConfigurationSuccess = (state, action) => {
  createNotification(
    'success',
    "Member's payment configuration added successfully.",
    3000,
  );
  return {
    ...state,
    paymentConfigurationLoading: false,
  };
};
export const handleSetPaymentConfigurationFailure = (state, action) => {
  createNotification(
    'error',
    'Could not add payment configuration. Please try again!',
    3000,
  );
  return {
    ...state,
    paymentConfigurationLoading: false,
  };
};

export const handleUpdatePaymentConfiguration = (state, action) => {
  return {
    ...state,
    paymentConfigurationLoading: true,
  };
};
export const handleUpdatePaymentConfigurationSuccess = (state, action) => {
  createNotification(
    'success',
    "Member's payment configuration updated successfully.",
    3000,
  );
  return {
    ...state,
    paymentConfigurationLoading: false,
  };
};
export const handleUpdatePaymentConfigurationFailure = (state, action) => {
  createNotification(
    'error',
    'Could not update payment configuration. Please try again!',
    3000,
  );
  return {
    ...state,
    paymentConfigurationLoading: false,
  };
};

export const handleUpdateTimesheetApprovalSettingsSuccessHandler = (
  state,
  action,
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    const membersList = JSON.parse(JSON.stringify(state.members));
    const details = JSON.parse(JSON.stringify(state.memberDetails));
    if (membersList && membersList.length > 0) {
      membersList.some(member => {
        if (member.id === data.data.id) {
          member.require_timesheet_approval =
            data.data.require_timesheet_approval;
          return true;
        }
        return false;
      });
    }
    if (details && details.id && details.id === data.data.id) {
      details.require_timesheet_approval = data.data.require_timesheet_approval;
    }
    return {
      ...state,
      members: [...membersList],
      memberDetails: { ...details },
    };
  }
  return {
    ...state,
  };
};

export const clearMemberInvitedRequest = (state, action) => {
  return {
    ...state,
    memberInvited: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_USERS]: membersListRequestHandler,
  [GET_USERS_SUCCESS]: membersListRequestSuccessHandler,
  [GET_USERS_FAILURE]: membersListRequestFailureHandler,

  [GET_USER_UPDATE_REQUESTS]: userUpdateRequestsListRequestHandler,
  [GET_USER_UPDATE_REQUESTS_SUCCESS]:
    userUpdateRequestsListRequestSuccessHandler,
  [GET_USER_UPDATE_REQUESTS_FAILURE]:
    userUpdateRequestsListRequestFailureHandler,

  [DELETE_USER_REQUEST]: deleteUserRequestHandler,
  [DELETE_USER_REQUEST_SUCCESS]: deleteUserRequestSuccessHandler,
  [DELETE_USER_REQUEST_FAILURE]: deleteUserRequestFailureHandler,

  [REJECT_USER_REQUEST]: rejectUserRequestHandler,
  [REJECT_USER_REQUEST_SUCCESS]: rejectUserRequestSuccessHandler,
  [REJECT_USER_REQUEST_FAILURE]: rejectUserRequestFailureHandler,

  [UPDATE_USER_REQUEST]: updateUserRequestHandler,
  [UPDATE_USER_REQUEST_SUCCESS]: updateUserRequestSuccessHandler,
  [UPDATE_USER_REQUEST_FAILURE]: updateUserRequestFailureHandler,

  [CREATE_USER_REQUEST]: createUserRequestHandler,
  [CREATE_USER_REQUEST_SUCCESS]: createUserRequestSuccessHandler,
  [CREATE_USER_REQUEST_FAILURE]: createUserRequestFailureHandler,

  [DELETE_MEMBER_INVITATION]: deleteMemberInvitationRequestHandler,
  [DELETE_MEMBER_INVITATION_SUCCESS]:
    deleteMemberInvitationRequestSuccessHandler,
  [DELETE_MEMBER_INVITATION_FAILURE]:
    deleteMemberInvitationRequestFailureHandler,

  [GET_MEMBER_DETAILS]: membersDetailsRequestHandler,
  [GET_MEMBER_DETAILS_SUCCESS]: membersDetailsRequestSuccessHandler,
  [GET_MEMBER_DETAILS_FAILURE]: membersDetailsRequestFailureHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  inviteMemberLoading: false,
  deleteInvitationLoading: false,
  createMemberLoading: false,
  updateMemberLoading: false,
  paymentConfigurationLoading: false,
  deleteMemberLoading: false,
  members: [],
  userUpdateRequests: [],
  memberDetails: null,
  updatedData: null,
  invitedMembersList: [],
  weeklyEarning: null,
  monthlyEarning: null,
  paymentConfHistory: undefined,
  removed: false,
  archive: null,
  unarchive: null,
  invitationList: [],
  memberInvited: false,
  createdMemberInfo: null,

  // pagination
  totalMembersCount: null,
  pageSize: null,
};

export default function userReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
