import { t } from 'i18next';
import moment from 'moment';
import React from 'react';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import { Button } from 'reactstrap';
import styled from 'styled-components';

import datePickerIcon from '../../assets/img/icons/date_picker_icon.svg';

const ToText = styled.div`
  font-size: ${({ theme }) => theme.dropdown.fontSize};
  color: ${({ theme }) => theme.font.pageText.colorMain};
`;

const DateRangePickerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isApply, theme }) =>
    isApply ? theme.button.primary.bgColor : theme.button.secondary.bgColor};
  border: 1px solid
    ${({ isApply, theme }) => !isApply && theme.button.secondary.borderColor};
  color: ${({ isApply, theme }) =>
    isApply
      ? theme.button.primary.textColor
      : theme.button.secondary.textColor};
  height: 36px;
  padding: 0px 10px;
  cursor: pointer;
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  margin: ${({ margin }) => margin || '0'};
  &:hover {
    background-color: ${({ isApply, theme }) =>
      isApply
        ? theme.button.primary.hoverBgColor
        : theme.button.secondary.hoverBgColor};
    color: ${({ theme }) => theme.button.secondary.hoverTextColor};
  }
`;

const CustomInputIcon = () => {
  return <img style={{ marginTop: '-2px' }} src={datePickerIcon} />;
};

const ArrowIcon = () => <ToText>{t('to')}</ToText>;

class ReactDateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
    };
  }
  getCalendarInfo = (showFooter = true) => {
    const onApply = () => {
      this.props.onDateSelect();
      this.setState({ focusedInput: null });
    };

    return (
      <div className="date-range-footer">
        {showFooter && (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DateRangePickerButton onClick={this.props.selectLastWeek}>
                Last 7 days
              </DateRangePickerButton>
              <DateRangePickerButton
                onClick={this.props.selectLastMonth}
                margin="0 0 0 8px"
              >
                Last 30 days
              </DateRangePickerButton>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <DateRangePickerButton isApply onClick={() => onApply()}>
                Apply
              </DateRangePickerButton>
            </div>
          </>
        )}
      </div>
    );
  };

  render() {
    const {
      startDate,
      startDateId,
      endDate,
      endDateId,
      onDatesChange,
      dateDisplayFormat,
      disableCurrentAndFutureDates,
      disablePastDates,
      showFooter = true,
    } = this.props;
    return (
      <DateRangePicker
        startDate={startDate}
        startDateId={startDateId}
        endDate={endDate}
        endDateId={endDateId}
        onDatesChange={({ startDate, endDate }) => {
          onDatesChange({ startDate, endDate: endDate ? endDate : startDate });
        }}
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => this.setState({ focusedInput })}
        showDefaultInputIcon={false}
        customInputIcon={<CustomInputIcon />}
        inputIconPosition="after"
        isOutsideRange={
          disableCurrentAndFutureDates
            ? day => !isInclusivelyBeforeDay(day, moment().subtract(1, 'day'))
            : disablePastDates
            ? day => isInclusivelyBeforeDay(day, moment().subtract(1, 'day'))
            : () => false
        }
        displayFormat={dateDisplayFormat}
        renderCalendarInfo={() => this.getCalendarInfo(showFooter)}
        calendarInfoPosition="bottom"
        keepOpenOnDateSelect={showFooter}
        customArrowIcon={<ArrowIcon />}
        minimumNights={0}
        hideKeyboardShortcutsPanel
        readOnly
      />
    );
  }
}

export default ReactDateRangePicker;
