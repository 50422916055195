import { connect } from "react-redux";

// Component
import CreateTask from "./CreateTask";
import { getOrganizationMembersList } from "../../Projects/projectsActions";
import {
  createTask,
  getTaskLocation,
  addTaskLocation,
  getTaskType,
  getTaskDetails,
  clearTaskState,
} from "../tasksActions";
import { getOfficesShortList } from "../../Offices/officesActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersList: state.project.orgMembersList,
  officesShortList: state.office.officesShortList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  isLoading: state.task.isLoading,
  addTaskLocationLoading: state.task.addTaskLocationLoading,
  createdTaskInfo: state.task.createdTaskInfo,
  locationsList: state.task.locationsList,
  createdTaskLocationInfo: state.task.createdTaskLocationInfo,
  taskTypeIsLoading: state.task.taskTypeIsLoading,
  taskTypeList: state.task.taskTypeList,
  taskDetails: state.task.taskDetails,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationMembersList: (params) =>
    dispatch(getOrganizationMembersList(params)),
  createTask: (params) => dispatch(createTask(params)),
  getTaskLocation: (params) => dispatch(getTaskLocation(params)),
  addTaskLocation: (params) => dispatch(addTaskLocation(params)),
  getOfficesShortList: (params) => dispatch(getOfficesShortList(params)),
  getTaskType: (params) => dispatch(getTaskType(params)),
  getTaskDetails: (params) => dispatch(getTaskDetails(params)),
  clearTaskState: () => dispatch(clearTaskState()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CreateTask);
