import { connect } from 'react-redux';

import { getOfficesShortList } from '../../Offices/officesActions';
import { getOrganizationMembersList } from '../../Projects/projectsActions';
import RouteMap from './RouteMap';
import {
  getRouteMapLocations,
  getSingleEmployeeClockInOutList,
  getTaskCheckInOutList,
} from './routeMapActions';

const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersList: state.project.orgMembersList,
  officesShortList: state.office.officesShortList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  // loading
  locationsIsLoading: state.routeMap.locationsIsLoading,

  routeMapLocations: state.routeMap.routeMapLocations,
  clockInOutList: state.routeMap.clockInOutList,
  taskCheckInOutList: state.routeMap.taskCheckInOutList,
  lastAttendanceTimestamp: state.routeMap.lastAttendanceTimestamp,
});

const mapDispatchToProps = dispatch => ({
  getRouteMapLocations: payload => dispatch(getRouteMapLocations(payload)),
  getOfficesShortList: payload => dispatch(getOfficesShortList(payload)),
  getOrganizationMembersList: payload =>
    dispatch(getOrganizationMembersList(payload)),
  getSingleEmployeeClockInOutList: payload =>
    dispatch(getSingleEmployeeClockInOutList(payload)),
  getTaskCheckInOutList: payload => dispatch(getTaskCheckInOutList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteMap);
