import DatePicker from 'components/SingleDatePicker/SingleDatePicker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import TimePicker from '../../../components/TimePicker';

const TimeRangeContainer = styled.div`
  display: grid;
  height: 120%;
  grid-template-columns: 45% 5% 45%;
  justify-content: space-between;
  align-items: center;
`;
const DashDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TimeDash = styled.div`
  width: 8px;
  height: 1px;
  background: ${({ theme }) => theme.colors.main.textSecondary};
`;
const TimePickerError = styled.div`
  min-width: 210px;
  position: absolute;
  font-size: 11px;
  top: 44px;
  color: ${({ theme }) => theme.colors.main.error};
`;

const TimeRangeComponentNew = ({
  setTime,
  initialTime,
  selectedDate,
  onDateChange,
  mode,
}) => {
  const [initialValue, setInitialValue] = useState(
    moment()
      .add(mode === 'end' ? 65 : 5, 'minutes')
      .format('HH:mm'),
  );
  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);
  const [focusEnd, setFocusEnd] = useState(false);

  useEffect(() => {
    // console.log(initialStartTime)
    if (initialTime) {
      // console.log(initialStartTime)
      setInitialValue(initialTime);
    }
  }, [initialTime]);

  const onTimeChange = value => {
    setInitialValue(value);
    setTime(value);
  };

  return (
    <TimeRangeContainer style={{ position: 'relative' }}>
      <DatePicker
        id="issuedDate"
        date={selectedDate}
        onDateChange={onDateChange}
        dateDisplayFormat={'DD/MM/YYYY'}
        placeholder={'Select date'}
      />
      <DashDiv>
        <TimeDash />
      </DashDiv>
      <TimePicker
        hour12Format
        value={initialValue}
        onChange={value => onTimeChange(value)}
        focusInput={focusEnd}
        setFocusInput={setFocusEnd}
      />
      {(startError || endError) && (
        <TimePickerError>
          Please enter a valid time. (e.g: 09:00 am)
        </TimePickerError>
      )}
    </TimeRangeContainer>
  );
};

export default TimeRangeComponentNew;
