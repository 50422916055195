import {
  ADD_MEMBER,
  CLEAR_MEMBER_DETAILS_DATA,
  CLEAR_MEMBER_INVITED,
  CREATE_USER_REQUEST,
  DELETE_MEMBER_INVITATION,
  DELETE_USER_REQUEST,
  GET_INVITED_MEMBERS,
  GET_MEMBER_DETAILS,
  GET_USERS,
  GET_USER_UPDATE_REQUESTS,
  INVITE_MEMBER,
  REJECT_USER_REQUEST,
  REMOVE_MEMBER,
  UPDATE_MEMBER,
  UPDATE_USER_REQUEST,
} from '../../modules/constants';

export function getUserRequests(value) {
  return {
    type: GET_USERS,
    payload: value,
  };
}
export function getUserUpdateRequests(value) {
  return {
    type: GET_USER_UPDATE_REQUESTS,
    payload: value,
  };
}
export function createUserRequest(value) {
  return {
    type: CREATE_USER_REQUEST,
    payload: value,
  };
}
export function deleteUserRequest(value) {
  return {
    type: DELETE_USER_REQUEST,
    payload: value,
  };
}

export function rejectUserRequest(value) {
  return {
    type: REJECT_USER_REQUEST,
    payload: value,
  };
}

export function updateUserRequest(value) {
  return {
    type: UPDATE_USER_REQUEST,
    payload: value,
  };
}

export function getInvitedMembersList(value) {
  return {
    type: GET_INVITED_MEMBERS,
    payload: value,
  };
}

export function inviteMember(value) {
  return {
    type: INVITE_MEMBER,
    payload: value,
  };
}

export function deleteMemberInvitation(value) {
  return {
    type: DELETE_MEMBER_INVITATION,
    payload: value,
  };
}

export function getMemberDetails(value) {
  return {
    type: GET_MEMBER_DETAILS,
    payload: value,
  };
}

export function addMember(value) {
  return {
    type: ADD_MEMBER,
    payload: value,
  };
}

export function updateMember(value) {
  return {
    type: UPDATE_MEMBER,
    payload: value,
  };
}
export function removeMember(value) {
  return {
    type: REMOVE_MEMBER,
    payload: value,
  };
}
export function clearMemberDetailsData() {
  return {
    type: CLEAR_MEMBER_DETAILS_DATA,
  };
}
export function clearMemberInvited() {
  return {
    type: CLEAR_MEMBER_INVITED,
  };
}
