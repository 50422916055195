import styled, { css } from "styled-components";
import { Nav, Collapse } from "reactstrap";
import { CenteredTooltip } from "../../styledComponents/common";

export const SidebarCollapseButtonIcon = styled.img`
  transform: ${({ sidebarMini }) => `rotate(${sidebarMini ? `-` : ``}90deg)`};
  transition: transform 0.3s ease;
`;
export const SidebarCollapseButton = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.colors.main.white};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.sidebar.collapseButtonShadow};
  border-radius: 50%;
  right: -15px;
  top: 18px;
  z-index: 2000;
  cursor: pointer;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.main.primary};
  }
`;
export const LogoContainer = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px;
  position: relative;
  z-index: 4;
  overflow: hidden;
`;
export const LogoLink = styled.a``;
export const LogoIconDiv = styled.div`
  width: 32px;
  height: 32px;
  float: left;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 0 0 32px;
`;
export const LogoIcon = styled.img``;
export const LogoText = styled.img`
  width: 120px;
  height: 28px;
`;

export const SidebarLinksWrapper = styled.div`
  position: relative;
  z-index: 4;
  width: ${({ theme, sidebarMini }) =>
    sidebarMini ? theme.sidebar.sidebarMiniWidth : theme.sidebar.sidebarWidth};
  padding-bottom: 20px;

  @media (min-width: 992px) {
    -webkit-transition-property: top, bottom, width;
    transition-property: top, bottom, width;
    -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
    transition-duration: 0.2s, 0.2s, 0.35s;
    -webkit-transition-timing-function: linear, linear, ease;
    transition-timing-function: linear, linear, ease;
    -webkit-overflow-scrolling: touch;
  }
`;

export const LinkGroup = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.sidebar.linkGroup.borderColor};
  padding-bottom: 15px;
`;
export const LinkGroupTitle = styled.div`
  margin-top: 15px;
  margin-left: 20px;
  font-size: ${({ theme }) => theme.sidebar.fontSize};
  font-weight: ${({ theme }) => theme.sidebar.linkGroup.fontWeight};
  color: ${({ theme }) => theme.sidebar.textColor}; ;
`;
export const SidebarNav = styled(Nav)`
  margin-top: 10px;
  display: block;
`;

// collapse link
export const CollapseLinkIcon = styled.div`
  text-transform: uppercase;
  width: ${({ theme }) => theme.sidebar.collapseLink.width};
  height: ${({ theme }) => theme.sidebar.collapseLink.height};
  margin-right: 10px;
  margin-left: 0px;
  font-size: ${({ theme }) => theme.sidebar.collapseLink.fontSize};
  font-weight: ${({ theme }) => theme.sidebar.collapseLink.fontWeight};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  float: left;
  z-index: 1;
  border-radius: 50%;
  color: ${({ theme }) => theme.sidebar.collapseLink.textColor};
  background-color: ${({ theme }) => theme.sidebar.collapseLink.bgColor};
`;
export const CollapseLinkText = styled.span`
  font-size: ${({ theme }) => theme.sidebar.fontSize};
  color: ${({ theme }) => theme.sidebar.textColor};
  margin-left: 5px;
`;
export const SingleCollapseLinkContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ sidebarMini }) =>
    sidebarMini &&
    css`
      height: 30px;
      ${CollapseLinkText} {
        display: none;
      }
      ${NewBadge} {
        display: none;
      }
    `}
  ${({ active }) =>
    active &&
    css`
      ${CollapseLinkIcon} {
        background-color: ${({ theme }) =>
          theme.sidebar.collapseLink.activeBgColor};
      }
      ${CollapseLinkText} {
        color: ${({ theme }) => theme.sidebar.activeColor};
      }
    `}
  &:hover {
    ${({ active }) =>
      !active &&
      css`
        ${CollapseLinkText} {
          color: ${({ theme }) => theme.sidebar.hoverColor};
        }
        ${CollapseLinkIcon} {
          background-color: ${({ theme }) =>
            theme.sidebar.collapseLink.hoverBgColor};
        }
      `}
  }
`;

export const SingleLinkContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ name }) =>
    (name === "Projects" || name === "Organizations") &&
    css`
      margin-top: 15px;
    `}
  ${({ sidebarMini }) =>
    sidebarMini &&
    css`
      width: 34px;
      justify-content: center;
      height: 30px;
      ${LinkText} {
        display: none;
      }
      ${CollapseArrowIcon} {
        display: none;
      }
      ${NewBadge} {
        display: none;
      }
    `}
`;
export const CollapseArrowIcon = styled.img`
  margin-left: auto;
  transform: ${({ collapsed }) =>
    collapsed ? `rotate(-90deg)` : `rotate(0deg)`};
  transition: transform 0.35s ease;
`;
export const LinkIcon = styled.img`
  height: 18px;
  float: left;
  width: 30px;
`;
export const LinkText = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.sidebar.textColor};
  margin-left: 5px;
`;
export const NewBadge = styled.div`
  height: 20px;
  width: 35px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.main.white};
  margin-left: 10px;
  background: ${({ theme }) => theme.sidebar.activeColor};
`;
export const SidebarLinkCollapse = styled(Collapse)``;
export const SidebarListItem = styled.li`
  a {
    display: block;
    text-decoration: none;
    position: relative;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 14px;
    padding: ${({ collapse }) => (collapse ? `0px 20px` : `7px 20px 0px`)};
    line-height: 30px;
  }
  ${({ active }) =>
    active &&
    css`
      img {
        filter: ${({ theme }) => theme.sidebar.imageFilter.active};
      }
      ${LinkText} {
        color: ${({ theme }) => theme.sidebar.singleLink.activeTextColor};
      }
      ${SingleLinkContainer} {
        background-color: ${({ theme }) =>
          theme.sidebar.singleLink.activeBgColor};
        border-radius: ${({ theme }) =>
          theme.sidebar.singleLink.activeBorderRadius};
        height: ${({ theme }) => theme.sidebar.singleLink.activeHeight};
      }
      ${NewBadge} {
        background-color: ${({ theme }) =>
          theme.sidebar.singleLink.activeTextColor};
        color: ${({ theme }) => theme.sidebar.singleLink.activeBgColor};
      }
    `}
  &:hover {
    ${({ active }) =>
      !active &&
      css`
        ${LinkText} {
          color: ${({ theme }) => theme.sidebar.hoverColor};
        }
        img {
          filter: ${({ theme }) => theme.sidebar.imageFilter.hover};
        }
      `}
  }
`;
export const SidebarCollapseList = styled.ul`
  margin-top: 10px;
  display: block;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
`;
export const SidebarCollapseListItem = styled.li`
  letter-spacing: 0.03em;
  line-height: 1.5em;
  &:hover {
    ${({ active }) =>
      !active &&
      css`
        ${LinkText} {
          color: ${({ theme }) => theme.sidebar.hoverColor};
        }
        img {
          filter: ${({ theme }) => theme.sidebar.imageFilter.hover};
        }
      `}
  }
  ${({ active }) =>
    active &&
    css`
      ${LinkText} {
        color: ${({ theme }) => theme.sidebar.singleLink.activeTextColor};
      }
      ${SingleLinkContainer} {
        background-color: ${({ theme }) =>
          theme.sidebar.singleLink.activeBgColor};
        border-radius: ${({ theme }) =>
          theme.sidebar.singleLink.activeBorderRadius};
        height: ${({ theme }) => theme.sidebar.singleLink.activeHeight};
      }
      /* ${NewBadge} {
        background-color: ${({ theme }) =>
          theme.sidebar.singleLink.activeTextColor};
        color: ${({ theme }) => theme.sidebar.singleLink.activeBgColor};
      } */
    `};
`;
export const SidebarLink = styled.a`
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 14px;
  padding: 7px 20px 0px;
  line-height: 30px;
  &:hover {
    text-decoration: none;
  }
  img {
    filter: ${({ active, theme }) =>
      active && theme.sidebar.imageFilter.active};
  }
`;

export const SidebarArrowIcon = styled.img`
  margin-left: ${({ marginLeft }) => marginLeft || "auto"};
  transform: ${({ popupOpen }) =>
    popupOpen ? `rotate(180deg)` : `rotate(0deg)`};
  transition: transform 0.35s ease;
`;
export const PopupArrowIcon = styled.img`
  transform: ${({ popupOpen }) =>
    popupOpen ? `rotate(0deg)` : `rotate(180deg)`};
  transition: transform 0.35s ease;
`;
export const SeeMoreArrow = styled.img`
  transform: ${({ collapsed }) =>
    collapsed ? `rotate(-90deg)` : `rotate(0deg)`};
  transition: transform 0.35s ease;
  margin-top: -3px;
`;
export const SeeMoreContainer = styled.div``;
export const SeeMoreDiv = styled.div`
  padding: 15px 20px 0px;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    css`
      ${LinkText} {
        color: ${({ theme }) => theme.sidebar.activeColor};
      }
      ${SeeMoreArrow} {
        filter: ${({ theme }) => theme.sidebar.imageFilter.seeMoreActive};
      }
    `}
  &:hover {
    ${({ isActive }) =>
      !isActive &&
      css`
        ${LinkText} {
          color: ${({ theme }) => theme.sidebar.hoverColor};
        }
        ${SeeMoreArrow} {
          filter: ${({ theme }) => theme.sidebar.imageFilter.hover};
        }
      `}
  }
`;

export const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: ${({ sidebarMini, theme }) =>
    sidebarMini ? theme.sidebar.sidebarMiniWidth : theme.sidebar.sidebarWidth};
  left: 0;
  z-index: ${({ sidebarMini }) => (sidebarMini ? `1030` : `999`)};
  border-right: 1px solid #e0e0e0;
  .sidebar-scrollbar {
    width: ${({ sidebarMini, theme }) =>
      sidebarMini
        ? `${theme.sidebar.sidebarMiniWidth} !important`
        : `${theme.sidebar.sidebarWidth} !important`};
  }

  @media screen and (max-width: 991px) {
    display: block;
    right: auto;
    z-index: 1032;
    padding: 0;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transform: ${({ navOpen, theme }) =>
      navOpen
        ? `translate3d(0px, 0, 0)`
        : `translate3d(-${theme.sidebar.sidebarWidth}, 0, 0)`};
  }

  @media screen and (min-width: 992px) {
    transition-property: top, bottom, width;
    -webkit-transition-property: top, bottom, width;
    transition-property: top, bottom, width;
    -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
    transition-duration: 0.2s, 0.2s, 0.35s;
    -webkit-transition-timing-function: linear, linear, ease;
    transition-timing-function: linear, linear, ease;
    -webkit-overflow-scrolling: touch;

    ${({ sidebarMini }) =>
      sidebarMini &&
      css`
        ${LinkText} {
          transform: translateZ(0);
          opacity: 0;
          transition: all 0.15s ease;
        }
        ${CollapseLinkText} {
          transform: translateZ(0);
          opacity: 0;
          transition: all 0.15s ease;
        }
        ${CollapseArrowIcon} {
          transform: translateZ(0);
          opacity: 0;
          transition: all 0.15s ease;
        }
        ${LogoText} {
          /* -webkit-transform: translate3d(-25px, 0, 0);
          transform: translate3d(-25px, 0, 0);
          opacity: 0;
          display: none; */
          transform: translateZ(0);
          opacity: 0;
          transition: all 0.15s ease;
        }
        .sidebar-scrollbar {
          transition-property: width;
          transition-duration: 350ms;
          transition-timing-function: 350ms;
        }
      `}
  }
`;

// Organization and Profile Part
export const OrgIcon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $size }) => $size || "40px"};
  height: ${({ $size }) => $size || "40px"};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  background-color: ${({ active, bgColor, theme }) =>
    !active
      ? theme.colors.main.grayMedium
      : bgColor
      ? bgColor
      : theme.colors.main.textSecondary};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  margin: ${({ margin }) => margin || "0"};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ fontSize }) => fontSize || "16px"};
  color: ${({ theme }) => theme.colors.main.white};
  z-index: 5;
  cursor: pointer;
  transition: all 0.15s ease;
  &:hover ${CenteredTooltip} {
    opacity: 1;
    visibility: visible;
  }
`;
export const PictureTooltipContainer = styled.div`
  position: relative;
  &:hover ${CenteredTooltip} {
    opacity: 1;
    visibility: visible;
  }
`;
export const ProfilePicture = styled.img`
  width: ${({ $size }) => $size || "40px"};
  height: ${({ $size }) => $size || "40px"};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  margin: ${({ margin }) => margin || "0"};
  cursor: ${({ cursor }) => cursor};
  transition: all 0.15s ease;
`;
export const PopupArrowDiv = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.main.textSecondary};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ margin }) => margin || "0 0 0 20px"};
  cursor: pointer;
`;
export const NameAndOrgDiv = styled.div`
  margin-left: 13px;
`;
export const UserName = styled.div`
  font-size: ${({ theme }) => theme.sidebar.profileSection.nameFontSize};
  font-weight: ${({ theme }) => theme.sidebar.profileSection.nameFontWeight};
  color: ${({ theme }) => theme.sidebar.profileSection.nameTextColor};
`;
export const OrgName = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.sidebar.textColor};
`;
export const OrgAndProfileContainer = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.sidebar.profileSection.height};
  padding: ${({ padding, sidebarMini }) =>
    padding ? padding : sidebarMini ? "0 10px 0 5px" : "0 20px 0 10px"};
  box-shadow: 2px 0px 10px rgba(105, 142, 190, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ sidebarMini }) =>
    sidebarMini &&
    css`
      ${NameAndOrgDiv} {
        transform: translateZ(0);
        opacity: 0;
        transition: all 0.15s ease;
      }
      ${SidebarArrowIcon} {
        transform: translateZ(0);
        opacity: 0;
        transition: all 0.15s ease;
      }
      ${PopupArrowDiv} {
        transform: translateZ(0);
        display: none;
        transition: all 0.15s ease;
      }
    `}
`;
