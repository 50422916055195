import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar5 from '../../assets/img/avatar_5.svg';
import editIcon from '../../assets/img/icons/edit-pen.svg';
import createProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import ProjectListPopup from '../../components/ProjectListPopup/ProjectListPopup';
import Select from '../../components/ReactSelectDropdown';
import ToggleButtonComponent from '../../components/ToggleButton/ToggleButton';
import TaskActionTooltip from '../../components/Tooltip/TaskActionTooltip';
import {
  AssigneeMoreText,
  ColoredText,
  CommonFlex,
  CommonImage,
  CommonText,
  Container,
  ResendInvite,
  RoundLetterSection,
  StyledLink,
  TableText,
} from '../../styledComponents/common';
import {
  ToggleButton,
  ToggleButtonRound,
} from '../../styledComponents/invoice';
import {
  InviteHeadSection,
  InviteLink,
  InviteProjectSection,
  InviteTableContainer,
  MemberNameDiv,
  MembersTableContainer,
  MembersTableHeadContainer,
  RadioButton,
  TeamSection,
  UserSettings,
} from '../../styledComponents/members';
import { AssignedProjectBox, TableItem } from '../../styledComponents/teams';
import { RoundIcon } from '../Tasks/tasksStyles';
import {
  ApprovalSettingsSection,
  CommFirstRoundLetterComp,
  CommImageInviteComp,
  CommImageNameComp,
} from './CommonComponents';
import SelfieWarningModal from './SelfieModal';
import UserTypeChangeWarningModal from './userTypeChangeWarningModal';

const MembersTable = props => {
  const { t } = useTranslation();
  const [isUserTypeChangeModalOpen, setIsUserTypeChangeModalOpen] =
    useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selfieModalOpen, setSelfieModalOpen] = useState(false);

  const [role, setRole] = useState('');

  const updateUserType = val => {
    props.onGeoFenceTimeClockChange(selectedMember.id, val);
    setSelectedMember(null);
    setIsUserTypeChangeModalOpen(!isUserTypeChangeModalOpen);
  };

  const onSelfieToggle = member => {
    setSelectedMember(member);
    setSelfieModalOpen(true);
  };

  const updateSelfiePermission = val => {
    props.UpdateSelfiePermission(selectedMember.id, val);
    setSelectedMember(null);
    setSelfieModalOpen(!selfieModalOpen);
  };

  useEffect(() => {
    setRole(localStorage.getItem('user_role'));
  }, []);
  return (
    <Container style={{ paddingBottom: `20px` }}>
      <UserTypeChangeWarningModal
        member={selectedMember}
        isOpen={isUserTypeChangeModalOpen}
        toggle={() => {
          setIsUserTypeChangeModalOpen(!isUserTypeChangeModalOpen);
        }}
        handleSave={updateUserType}
        isLoading={false}
      />

      <SelfieWarningModal
        member={selectedMember}
        isOpen={selfieModalOpen}
        toggle={() => {
          setSelfieModalOpen(!selfieModalOpen);
        }}
        handleSave={updateSelfiePermission}
        isLoading={false}
      />
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <MembersTableHeadContainer
            role={role}
            style={{ borderBottom: `1px solid #C2CCE1` }}
          >
            <TableItem>
              <TableText>{t('member_name')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('user_id')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('email')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('role')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('contact_number')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('office')}</TableText>
            </TableItem>
            {(role === 'admin' || role === 'gm' || role === 'agm') &&
            !localStorage.getItem('viewer_role') ? (
              <TableItem>
                <TableText>{t('employee_type')}</TableText>
              </TableItem>
            ) : null}
            {role === 'admin' && !localStorage.getItem('viewer_role') ? (
              <TableItem>
                <TableText>{t('selfie')}</TableText>
              </TableItem>
            ) : null}
          </MembersTableHeadContainer>
          {props.membersList && props.membersList.length > 0 ? (
            props.membersList.map((member, index) => (
              <MembersTableContainer key={index} role={role}>
                <TableItem>
                  <div
                  // onClick={() =>
                  //   props.history.push(
                  //     `/user/member-details?id=${member.id}`
                  //   )
                  // }
                  >
                    <CommImageNameComp
                      imgSource={member.avatar ? member.avatar : Avatar5}
                      name={`${member.first_name} ${member.last_name}`}
                      size="36px"
                    />
                  </div>
                </TableItem>
                <TableItem>
                  <TableText>{member.user_code || `-`}</TableText>
                  {member.mac_address ? (
                    <TableText fontWeight="500" color="#4d94fb">
                      {member.mac_address}
                    </TableText>
                  ) : null}
                </TableItem>
                <TableItem>
                  <TableText>{member.email || `-`}</TableText>
                </TableItem>
                <TableItem>
                  <TableText>{member.role ? member.role : `-`}</TableText>
                </TableItem>
                <TableItem>
                  <TableText>{member.phone ? member.phone : `-`}</TableText>
                </TableItem>
                <TableItem>
                  <TableText>
                    {member.office && member.office.id
                      ? member.office.name
                      : `-`}
                  </TableText>
                </TableItem>
                {(role === 'admin' ||
                  (role === 'gm' && member.role !== 'admin') ||
                  (role === 'agm' &&
                    (member.role === 'supervisor' ||
                      member.role === 'employee'))) &&
                !localStorage.getItem('viewer_role') ? (
                  <TableItem>
                    <UserSettings>
                      <RadioButton
                        cursor={
                          // role === 'gm' || role === 'agm'
                          //   ? 'not-allowed'
                          //   :
                          'pointer'
                        }
                        onClick={() => {
                          if (
                            !member.geofence_time_clock
                            // ||
                            // role === 'gm' ||
                            // role === 'agm'
                          ) {
                            return;
                          }
                          setSelectedMember(member);
                          setIsUserTypeChangeModalOpen(
                            !isUserTypeChangeModalOpen,
                          );
                        }}
                        active={!member.geofence_time_clock}
                      />
                      <ColoredText
                        cursor={
                          // role === 'gm' || role === 'agm'
                          //   ? 'not-allowed'
                          //   :
                          'pointer'
                        }
                        type={!member.geofence_time_clock && 'success'}
                        fontSize="13px"
                      >
                        {t('remote_user')}
                      </ColoredText>
                      {/* <ToggleButton
                      onClick={() =>
                        props.onGeoFenceTimeClockChange(
                          index,
                          member.id,
                          member.geofence_time_clock
                        )
                      }
                      active={member.geofence_time_clock}
                    >
                      <ToggleButtonRound active={member.geofence_time_clock} />
                    </ToggleButton> */}
                      <RadioButton
                        cursor={
                          // role === 'gm' || role === 'agm'
                          //   ? 'not-allowed'
                          //   :
                          'pointer'
                        }
                        onClick={() => {
                          if (
                            member.geofence_time_clock
                            // ||
                            // role === 'gm' ||
                            // role === 'agm'
                          ) {
                            return;
                          }
                          setSelectedMember(member);
                          setIsUserTypeChangeModalOpen(
                            !isUserTypeChangeModalOpen,
                          );
                        }}
                        active={member.geofence_time_clock}
                      />
                      <ColoredText
                        cursor={
                          // role === 'gm' || role === 'agm'
                          //   ? 'not-allowed'
                          //   :
                          'pointer'
                        }
                        type={member.geofence_time_clock && 'success'}
                        fontSize="13px"
                      >
                        {t('office_user')}
                      </ColoredText>
                    </UserSettings>
                  </TableItem>
                ) : (
                  <></>
                )}
                {role === 'admin' && !localStorage.getItem('viewer_role') ? (
                  <TableItem>
                    <ToggleButtonComponent
                      active={member.clockin_selfie}
                      handleClick={() => onSelfieToggle(member)}
                    />
                  </TableItem>
                ) : null}
                {role === 'admin' && !localStorage.getItem('viewer_role') && (
                  <TableItem>
                    <RoundIcon
                      type="edit"
                      onClick={() =>
                        props.history.push(
                          `/user/member-add-update?id=${member.id}`,
                        )
                      }
                    >
                      <CommonImage src={editIcon} alt="" size="16px" />
                      <TaskActionTooltip toolTipText={t('edit_member')} />
                    </RoundIcon>
                  </TableItem>
                )}
              </MembersTableContainer>
            ))
          ) : (
            <NoDataComponent
              title={t('no_members_found')}
              padding="5vh 0"
              imageHeight="140px"
            />
          )}
        </>
      )}
    </Container>
  );
};

export default MembersTable;
