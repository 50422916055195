import { connect } from 'react-redux';

import { getOfficesShortList } from '../../Offices/officesActions';
import { updateMemberwiseTimesheetApprovalSettings } from '../../Settings/TimesheetSettings/timesheetSettingsActions';
// Actions
import {
  createUserRequest,
  deleteMemberInvitation,
  getInvitedMembersList,
  getUserRequests,
  inviteMember,
  rejectUserRequest,
  removeMember,
  updateMember,
} from '../updateUserActions';
import CreateRequest from './CreateRequest';

// Store props to map with current state
const mapStateToProps = state => ({
  isLoading: state.userUpdate.isLoading,
  rejectedUserId: state.userUpdate.rejectedUserId,
  rejectRequestLoading: state.userUpdate.rejectRequestLoading,
  createdUserId: state.userUpdate.createdUserId,
  createRequestLoading: state.userUpdate.createRequestLoading,
  deleteInvitationLoading: state.userUpdate.deleteInvitationLoading,
  deleteMemberLoading: state.userUpdate.deleteMemberLoading,
  selectedOrganization: state.organization.selectedOrganization,
  membersList: state.userUpdate.members,
  updatedData: state.userUpdate.updatedData,
  invitedMembersList: state.userUpdate.invitedMembersList,
  officesShortList: state.office.officesShortList,
  // pagination
  totalMembersCount: state.userUpdate.totalMembersCount,
  pageSize: state.userUpdate.pageSize,
  state: state,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getUserRequests: details => dispatch(getUserRequests({ ...details })),
  getOfficesShortList: details => dispatch(getOfficesShortList({ ...details })),

  createUserRequest: details => dispatch(createUserRequest({ ...details })),
  rejectUserRequest: details => dispatch(rejectUserRequest({ ...details })),
  updateMember: details => dispatch(updateMember({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CreateRequest);
