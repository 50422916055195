import { useState, useEffect } from "react";
import DateRangePicker from "../../components/ReactDateRangePicker/ReactDateRangePicker";
import DatePicker from "../../components/SingleDatePicker/SingleDatePicker";
import CreateProjectDropdown from "../../components/DropdownStyle/CreateProjectDropdown";
import AddSingleMemberDropdown from "../../components/CustomDropdown/AddSingleMemberDropdown";
import Select from "../../components/ReactSelectDropdown";
import { FilterContainer } from "../../styledComponents/timesheet";
import {
  FilterLabel,
  InputWithLabelSection,
} from "../../styledComponents/common";

import CommonFilter from "../../components/CommonFilter/commonFilterContainer";

import TimezoneDropdown from "../../components/TimezoneDropdown/TimezoneDropdown";

import { useTranslation } from "react-i18next";

const TimesheetFilter = (props) => {
  const { t } = useTranslation();
  const [officeOptions, setOfficeOptions] = useState([
    {
      label: t("all_offices"),
      value: null,
    },
  ]);
  const [memberOptions, setMemberOptions] = useState([]);
  const {
    getOrganizationMembersList,
    organizationMembersList,
    getOfficesShortList,
    officesShortList,
    selectedOffice,
    selectOffice,
    selectedMember,
    selectMember,
    clearMember,
    selectedDate,
    handleDateChange,
  } = props;

  useEffect(() => {
    getOfficesShortList();
    getOrganizationMembersList({ office_id: null });
  }, []);

  useEffect(() => {
    if (officesShortList && officesShortList.length === 1) {
      getOrganizationMembersList({ office_id: officesShortList[0].id });
    }
    if (officesShortList && officesShortList.length > 1) {
      let newArr = [
        {
          label: t("all_offices"),
          value: null,
        },
      ];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      setOfficeOptions(newArr);
    }
  }, [officesShortList]);

  useEffect(() => {
    if (
      selectedOffice &&
      selectedOffice.value &&
      officesShortList &&
      officesShortList.length > 1
    ) {
      getOrganizationMembersList({ office_id: selectedOffice.value });
    }
  }, [selectedOffice]);

  const onMemberSelect = (person) => {
    selectMember(person);
  };

  return (
    <div>
      <FilterContainer
        admin={localStorage.getItem("user_role") === "admin"}
        isMonthly={props.page && props.page === "monthly"}
      >
        {localStorage.getItem("user_role") === "admin" && (
          <InputWithLabelSection style={{minWidth: `240px`}}>
            <FilterLabel>{t("office")}</FilterLabel>
            <Select
              isSearchable
              value={selectedOffice}
              options={officeOptions}
              placeholder="Select Office..."
              onChange={(e) => {
                selectOffice(e);
              }}
              styles={CreateProjectDropdown()}
              clearable
            />
          </InputWithLabelSection>
        )}
        <InputWithLabelSection>
          <FilterLabel>{t("assignee")}</FilterLabel>
          <div style={{ width: `40px` }}>
            <AddSingleMemberDropdown
              assigneeManage={selectedMember}
              updateState={onMemberSelect}
              membersList={organizationMembersList}
              clearAssignee={() => clearMember()}
            />
          </div>
        </InputWithLabelSection>
        {props.page && props.page !== "monthly" && (
          <InputWithLabelSection>
            <FilterLabel>{t("select_date")}</FilterLabel>
            <DatePicker
              id="createIssuedDate"
              date={selectedDate}
              onDateChange={handleDateChange}
              dateDisplayFormat={"DD/MM/YYYY"}
              placeholder={"Select date"}
              allowPreviousDates
            />
          </InputWithLabelSection>
        )}
      </FilterContainer>
    </div>
  );
};

export default TimesheetFilter;
