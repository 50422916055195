import { t } from 'i18next';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 20px;
`;

const PasswordSuggestion = styled.p`
  margin: 0;
  color: ${props => props.color};
  font-size: 13px;
  font-weight: 600;
`;

const PasswordValidationContainer = styled.div`
  margin-top: 15px;
`;

const SingleValidationDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
`;

const ValidationCheckbox = styled.div`
  height: 10px;
  width: 10px;
  background: ${props => (props.isValid ? '#3BAE7C' : '#fff')};
  border: ${props =>
    props.isValid ? '1px solid #3bae7c' : '1px solid #c2cce1'};
  border-radius: 11px;
`;

const ValidationText = styled.div`
  margin-left: 10px;
  font-size: 13px;
  color: ${props => (props.isValid ? '#3BAE7C' : 'rgba(47,57,78,0.6)')};
`;

const PasswordStrengthChecker = props => {
  const [lengthIsValid, setLengthIsValid] = useState(false);
  const [uppercaseIsValid, setUppercaseIsValid] = useState(false);
  const [lowercaseIsValid, setLowercaseIsValid] = useState(false);
  const [numberIsValid, setNumberIsValid] = useState(false);
  const [suggestionMessage, setSuggestionMessage] = useState(null);
  const [checkCount, setCheckCount] = useState(0);
  const [charsLeft, setCharsLeft] = useState(8);

  useEffect(() => {
    let count = 0;
    if (!props.password) {
      setLengthIsValid(false);
      setUppercaseIsValid(false);
      setLowercaseIsValid(false);
      setNumberIsValid(false);
      setSuggestionMessage(null);
      count = 0;
    } else if (props.password) {
      if (checkPasswordLength(props.password)) {
        count++;
      }
      if (checkUppercase(props.password)) {
        count++;
      }
      if (checkLowercase(props.password)) {
        count++;
      }
      if (checkNumber(props.password)) {
        count++;
      }
    }
    setCheckCount(count);
  }, [props.password]);

  useEffect(() => {
    if (checkCount > 0 && checkCount < 3) {
      setSuggestionMessage({
        message: t('weak_password'),
        color: '#fe5151',
      });
    } else if (checkCount === 3) {
      if (!lengthIsValid) {
        if (charsLeft && charsLeft > 0) {
          setSuggestionMessage({
            message: `Seems good, just add ${charsLeft} more ${
              charsLeft === 1 ? 'character' : 'characters'
            }.`,
            color: '#F3A936',
          });
        }
      } else if (!uppercaseIsValid) {
        setSuggestionMessage({
          message: `Seems good, just include an UPPERCASE letter.`,
          color: '#F3A936',
        });
      } else if (!lowercaseIsValid) {
        setSuggestionMessage({
          message: `Seems good, just include an lowercase letter.`,
          color: '#F3A936',
        });
      } else if (!numberIsValid) {
        setSuggestionMessage({
          message: `Seems good, just include a number.`,
          color: '#F3A936',
        });
      }
    } else if (checkCount === 4) {
      setSuggestionMessage({
        message: t('great_looks_like_a_strong_password'),
        color: '#3BAE7C',
      });
    }
  }, [checkCount]);

  const checkPasswordLength = password => {
    if (password.length >= 8) {
      setLengthIsValid(true);
      setCharsLeft(0);
      return true;
    } else {
      setLengthIsValid(false);
      setCharsLeft(8 - password.length);
      if (checkCount && checkCount === 3) {
        setSuggestionMessage({
          message: `Seems good, just add ${8 - password.length} more ${
            8 - password.length === 1 ? 'character' : 'characters'
          }.`,
          color: '#F3A936',
        });
      }
      return false;
    }
  };

  const checkUppercase = password => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setUppercaseIsValid(true);
      return true;
    } else {
      setUppercaseIsValid(false);
      return false;
    }
  };

  const checkLowercase = password => {
    const pattern = /[a-z]/;
    if (pattern.test(password)) {
      setLowercaseIsValid(true);
      return true;
    } else {
      setLowercaseIsValid(false);
      return false;
    }
  };

  const checkNumber = password => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      setNumberIsValid(true);
      return true;
    } else {
      setNumberIsValid(false);
      return false;
    }
  };

  return (
    <Container>
      {suggestionMessage && (
        <PasswordSuggestion color={suggestionMessage.color}>
          {suggestionMessage.message}
        </PasswordSuggestion>
      )}
      <PasswordValidationContainer>
        <SingleValidationDiv>
          <ValidationCheckbox isValid={lengthIsValid} />
          <ValidationText isValid={lengthIsValid}>
            {t('contains_at_least_8_characters')}
          </ValidationText>
        </SingleValidationDiv>
        <SingleValidationDiv>
          <ValidationCheckbox isValid={uppercaseIsValid} />
          <ValidationText isValid={uppercaseIsValid}>
            {t('contains_at_least_one_UPPERCASE_letter')}
          </ValidationText>
        </SingleValidationDiv>
        <SingleValidationDiv>
          <ValidationCheckbox isValid={lowercaseIsValid} />
          <ValidationText isValid={lowercaseIsValid}>
            {t('contains_at_least_one_lowercase_letter')}
          </ValidationText>
        </SingleValidationDiv>
        <SingleValidationDiv>
          <ValidationCheckbox isValid={numberIsValid} />
          <ValidationText isValid={numberIsValid}>
            {t('contains_at_least_one_number')}
          </ValidationText>
        </SingleValidationDiv>
      </PasswordValidationContainer>
    </Container>
  );
};

export default PasswordStrengthChecker;
