import RightSlidingPanel from 'components/RightSlidingPanel/RightSlidingPanel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar5 from '../../../assets/img/avatar_5.svg';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent';
import { TableText } from '../../../styledComponents/common';
import {
  UpdateRequestTableContainer,
  UserAddRequestTableHeadContainer,
} from '../../../styledComponents/members';
import { TableItem } from '../../../styledComponents/teams';
import { CommImageNameComp } from '../CommonComponents';
import {
  AddUserRequestButton,
  UpdateRequestContainer,
} from '../UpdateUserStyles';
import UserRequestApproveRejectModal from './UserRequestApproveRejectModal';
import UpdateUserRequestSidePanelComponent from './updateUserRequestSidePanelComponent';

const UsersTable = ({
  handleUpdateUser,
  isLoading,
  membersList,
  selectedMember,
  setSelectedMember,
  handleRejectMember,
}) => {
  const { t } = useTranslation();

  const [isUserApprovalModalOpen, setIsUserApprovalModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);

  const toggle = () => {
    setIsUserApprovalModalOpen(!isUserApprovalModalOpen);
    currentAction && setCurrentAction(null);
  };

  return (
    <UpdateRequestContainer style={{ paddingBottom: `20px` }}>
      <RightSlidingPanel
        isOpen={selectedMember}
        closePanel={() => setSelectedMember(null)}
        width="500px"
      >
        <UpdateUserRequestSidePanelComponent
          selectedMember={selectedMember}
          toggle={() => setSelectedMember(null)}
          handleUpdateUser={handleUpdateUser}
          handleRejectMember={handleRejectMember}
        />
      </RightSlidingPanel>
      <UserRequestApproveRejectModal
        isOpen={isUserApprovalModalOpen}
        toggle={toggle}
        action={currentAction}
        actionFunction={() => {}}
        isLoading={isLoading}
      />
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <UserAddRequestTableHeadContainer
            style={{ borderBottom: `1px solid #C2CCE1` }}
          >
            <TableItem>
              <TableText fontSize={'13px'}>{t('member_name')}</TableText>
            </TableItem>
          </UserAddRequestTableHeadContainer>
          {membersList?.length > 0 ? (
            membersList.map((member, index) => (
              <UpdateRequestTableContainer key={index}>
                <TableItem>
                  <div onClick={() => setSelectedMember(member)}>
                    <CommImageNameComp
                      imgSource={member.new_data?.avatar || Avatar5}
                      name={`${member.new_data.first_name} ${member.new_data.last_name}`.trim()}
                      size="36px"
                    />
                  </div>
                </TableItem>
                <TableItem>
                  <AddUserRequestButton
                    type="details"
                    onClick={() => setSelectedMember(member)}
                  >
                    {t('see_details')}
                  </AddUserRequestButton>
                </TableItem>
              </UpdateRequestTableContainer>
            ))
          ) : (
            <NoDataComponent
              title={t('no_members_found')}
              padding="5vh 0"
              imageHeight="140px"
            />
          )}
        </>
      )}
    </UpdateRequestContainer>
  );
};

export default UsersTable;
