import { t } from 'i18next';
import { useState } from 'react';
import { CommonFlex, CommonText } from 'styledComponents/common';

import AudioPlayer from '../TaskApproval/AudioPlayer';
import EvidencesModal from './EvidencesModal';
import { EvidenceThumbnail } from './taskDetailsStyles';

const TaskEvidence = ({ evidences }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedEvidence, setSelectedEvidence] = useState(false);

  const popupToggle = index => {
    setPopupOpen(!popupOpen);
    setSelectedEvidence(index);
  };
  return (
    <div>
      <EvidencesModal
        isOpen={popupOpen}
        toggle={popupToggle}
        evidences={evidences?.filter(evidence => evidence.image)}
        selectedEvidence={selectedEvidence}
      />
      <CommonFlex
        gap="16px"
        direction="column"
        alignItems="start"
        wrap="wrap"
        width="100%"
      >
        <CommonText name title fontSize="16px">
          {t('evidence')}
        </CommonText>
        <CommonFlex gap="16px" wrap="wrap">
          {evidences
            ?.filter(evidence => evidence.image)
            ?.map((evidence, index) => (
              <EvidenceThumbnail
                key={index}
                // width="fit-content"
                onClick={() => popupToggle(index)}
              >
                <img
                  src={evidence.image}
                  alt=""
                  style={{
                    // maxWidth: '200px',
                    height: '200px',
                    borderRadius: 10,
                  }}
                />
              </EvidenceThumbnail>
            ))}
        </CommonFlex>
        <CommonFlex gap="16px" wrap="wrap">
          {evidences
            ?.filter(evidence => evidence.audio)
            ?.map((evidence, index) => (
              <AudioPlayer key={index} audio={evidence.audio} />
            ))}
        </CommonFlex>
      </CommonFlex>
    </div>
  );
};

export default TaskEvidence;
