import EvidenceModal from 'custom_modules/Tasks/TaskDetails/EvidenceModal';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import InfoTooltip from '../../components/Tooltip/InfoTooltip';
import {
  CommonText,
  RoundMiniImage,
  TableText,
} from '../../styledComponents/common';
import { MemberNameDiv } from '../../styledComponents/members';
import { TableItem } from '../../styledComponents/teams';
import {
  formatDurationToHoursMinutes,
  getHours,
  toHHMMSS,
} from '../../utils/helper';
import { CommImageNameComp } from '../Members/CommonComponents';
import {
  AttendanceTableContainer,
  ClockInOutTableHeader,
  ClockInOutTableRow,
  SelfieImage,
} from './clockInOutStyles';

const ClockInOutTable = props => {
  const { isLoading, history, clockInOutList } = props;
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const { t } = useTranslation();

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  return (
    <AttendanceTableContainer style={{ paddingBottom: '20px' }}>
      {selectedImage && (
        <EvidenceModal
          isOpen={popupOpen}
          toggle={popupToggle}
          evidence={selectedImage}
        />
      )}
      {isLoading ? (
        <ComponentCircleLoader />
      ) : clockInOutList && clockInOutList.length > 0 ? (
        <>
          <ClockInOutTableHeader>
            <TableItem>
              <TableText>{t('member_name')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('user_id')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('office')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('date')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('clock_in')}</TableText>
            </TableItem>
            <TableItem>
              {/* <InfoTooltip
                mainText={t('clock_out')}
                toolTipText="Clock out occurs when the user stops the timer at the end of the day"
                title
                label
                top="-55px"
                tooltipPadding="5px 8px"
                infoSize="17px"
              /> */}
              <TableText>{t('clock_out')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('duration')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('image')}</TableText>
            </TableItem>
          </ClockInOutTableHeader>

          {clockInOutList &&
            clockInOutList.length > 0 &&
            clockInOutList.map((instance, index) => {
              return (
                <ClockInOutTableRow key={index}>
                  <TableItem>
                    <MemberNameDiv>
                      <CommImageNameComp
                        imgSource={instance.employee.avatar || avatar5}
                        name={`${instance.employee.first_name}${
                          instance.employee.last_name
                            ? ` ${instance.employee.last_name}`
                            : ``
                        }`}
                        size="36px"
                      />
                    </MemberNameDiv>
                  </TableItem>
                  <TableItem>
                    <TableText name>
                      {instance.employee && instance.employee.user_code
                        ? instance.employee.user_code
                        : '-'}
                    </TableText>
                  </TableItem>

                  <TableItem>
                    <TableText name>
                      {instance.employee &&
                      instance.employee.office &&
                      instance.employee.office.name
                        ? instance.employee.office.name
                        : '-'}
                    </TableText>
                  </TableItem>

                  <TableItem>
                    <TableText name>
                      {instance.attendances &&
                      instance.attendances.length > 0 &&
                      instance.attendances[0].clockin_timestamp
                        ? moment
                            .unix(instance.attendances[0].clockin_timestamp)
                            .format('DD-MM-YYYY')
                        : '-'}
                    </TableText>
                  </TableItem>

                  <TableItem>
                    <TableText name>
                      {instance.attendances &&
                      instance.attendances.length > 0 &&
                      instance.attendances[0].clockin_timestamp
                        ? moment
                            .unix(instance.attendances[0].clockin_timestamp)
                            .format('LT')
                        : '-'}
                    </TableText>
                  </TableItem>

                  <TableItem>
                    <TableText name>
                      {instance.attendances &&
                      instance.attendances.length > 0 &&
                      instance.attendances[0].clockout_timestamp
                        ? moment
                            .unix(instance.attendances[0].clockout_timestamp)
                            .format('LT')
                        : '-'}
                    </TableText>
                  </TableItem>

                  <TableItem>
                    <TableText name>
                      {instance.attendances &&
                      instance.attendances.length > 0 &&
                      instance.attendances[0].clockin_timestamp &&
                      instance.attendances[0].clockout_timestamp
                        ? formatDurationToHoursMinutes(
                            moment(
                              instance.attendances[0].clockout_timestamp,
                            ).diff(
                              moment(instance.attendances[0].clockin_timestamp),
                            ),
                          )
                        : '-'}
                    </TableText>
                  </TableItem>

                  <TableItem>
                    {instance.attendances &&
                    instance.attendances.length > 0 &&
                    instance.attendances[0].selfie ? (
                      <SelfieImage
                        style={{ cursor: `pointer` }}
                        onClick={() => {
                          setSelectedImage(instance.attendances[0].selfie);
                          popupToggle();
                        }}
                        src={instance.attendances[0].selfie}
                      />
                    ) : (
                      '-'
                    )}
                  </TableItem>

                  {instance.attendances &&
                    instance.attendances.length > 1 &&
                    instance.attendances.map((attendance, i) => (
                      <>
                        {i > 0 && (
                          <>
                            <TableItem></TableItem>
                            <TableItem></TableItem>
                            <TableItem></TableItem>
                            <TableItem>
                              <TableText name>
                                {attendance.clockin_timestamp
                                  ? moment
                                      .unix(attendance.clockin_timestamp)
                                      .format('DD-MM-YYYY')
                                  : '-'}
                              </TableText>
                            </TableItem>
                            <TableItem>
                              <TableText name>
                                {attendance.clockin_timestamp
                                  ? moment
                                      .unix(attendance.clockin_timestamp)
                                      .format('LT')
                                  : '-'}
                              </TableText>
                            </TableItem>
                            <TableItem>
                              <TableText
                                name
                                color={
                                  attendance.clockout_timestamp &&
                                  moment
                                    .unix(attendance.clockin_timestamp)
                                    .format('DD-MM-YYYY') !==
                                    moment
                                      .unix(attendance.clockout_timestamp)
                                      .format('DD-MM-YYYY') &&
                                  `rgb(77, 148, 251)`
                                }
                                fontWeight={
                                  attendance.clockout_timestamp &&
                                  moment
                                    .unix(attendance.clockin_timestamp)
                                    .format('DD-MM-YYYY') !==
                                    moment
                                      .unix(attendance.clockout_timestamp)
                                      .format('DD-MM-YYYY') &&
                                  `600`
                                }
                              >
                                {attendance.clockout_timestamp
                                  ? moment
                                      .unix(attendance.clockout_timestamp)
                                      .format('LT')
                                  : '-'}
                              </TableText>
                              {attendance.clockout_timestamp &&
                              moment
                                .unix(attendance.clockin_timestamp)
                                .format('DD-MM-YYYY') !==
                                moment
                                  .unix(attendance.clockout_timestamp)
                                  .format('DD-MM-YYYY') ? (
                                <TableText>
                                  {moment
                                    .unix(attendance.clockout_timestamp)
                                    .format('DD-MM-YYYY')}
                                </TableText>
                              ) : null}
                            </TableItem>

                            <TableItem>
                              <TableText name>
                                {attendance.clockin_timestamp &&
                                attendance.clockout_timestamp
                                  ? formatDurationToHoursMinutes(
                                      moment(
                                        attendance.clockout_timestamp,
                                      ).diff(
                                        moment(attendance.clockin_timestamp),
                                      ),
                                    )
                                  : '-'}
                              </TableText>
                            </TableItem>

                            <TableItem>
                              {attendance.selfie ? (
                                <SelfieImage
                                  height="56px"
                                  style={{
                                    cursor: `pointer`,
                                    margin: `-10px 0`,
                                  }}
                                  onClick={() => {
                                    setSelectedImage(attendance.selfie);
                                    popupToggle();
                                  }}
                                  src={attendance.selfie}
                                />
                              ) : (
                                '-'
                              )}
                            </TableItem>
                          </>
                        )}
                      </>
                    ))}
                </ClockInOutTableRow>
              );
            })}
        </>
      ) : (
        <NoDataComponent
          title={t('no_attendance_in_this_day')}
          padding="5vh 0"
          imageHeight="140px"
        />
      )}
    </AttendanceTableContainer>
  );
};

export default ClockInOutTable;
