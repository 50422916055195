import { t } from 'i18next';
import { useEffect, useState } from 'react';

import hidePassword from '../../assets/img/common/password_hide.svg';
import viewPassword from '../../assets/img/common/password_show.svg';
import arrowOpen from '../../assets/img/icons/arrow-collapse.svg';
import arrow from '../../assets/img/icons/arrow-expand.svg';
import PasswordStrengthChecker from '../../components/PasswordStrengthChecker/PasswordStrengthChecker';
import { PrimaryButton, WhiteButton } from '../../styledComponents/buttons';
import {
  CommonFlex,
  CommonText,
  InputField,
  InputLabel,
  InputWithLabelSection,
} from '../../styledComponents/common';
import { ActivityTextSection } from '../../styledComponents/members';
import { handleValidation, password_validate } from '../../utils/helper';
import ChangePasswordFormFields from './ChangePasswordFormFields';
import {
  ArrowButton,
  ChangePassDiv,
  ChangePasswordContainer,
  ChangePasswordForm,
  CollapseContainer,
  DivPassword,
  GreenLabel,
  ShowPasswordIcon,
} from './changePasswordStyle';

const ChangePassword = props => {
  const [passwordOld, setPasswordOld] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordIsFocused, setPasswordIsFocused] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [validationMessages, setvalidationMessages] = useState({});

  useEffect(() => {
    if (
      !(passwordOld === '' && password === '' && confirmPassword === '') &&
      props.passwordChanged.passwordChanged
    ) {
      setPasswordOld('');
      setPassword('');
      setConfirmPassword('');
      props.passwordChanged.passwordChanged = false;
      setModalOpen(false);
    }
  }, [props.passwordChanged.passwordChanged]);

  useEffect(() => {
    if (confirmPassword === password && password !== '') {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }, [password, confirmPassword]);

  const toggleShowPassword = item => {
    if (item === 'password') {
      //passwordFieldRef.current.focus();
      setShowOldPassword(!showOldPassword);
    } else if (item === 'Newpassword') {
      // confirmPasswordFieldRef.current.focus();
      setShowNewPassword(!showNewPassword);
    }
  };
  const toggleShowCart = () => {
    setModalOpen(!modalOpen);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const el = ChangePasswordFormFields.find(v => {
      return v.fieldName === name;
    });
    const result = handleValidation(name, el.type, value, el.options);
    switch (name) {
      case 'old_password':
        setPasswordOld(value);
        break;
      case 'new_password':
        setPassword(value);
        break;
      case 'confirm_password':
        setConfirmPassword(value);
        break;
    }
  };

  const savePassword = e => {
    const old_password = passwordOld;
    const new_password = password;
    if (old_password && new_password && passwordMatch) {
      const { changePasswordAction, history, loginProfileCreatedReset } = props;
      let payload = {};
      payload = { old_password, new_password };
      changePasswordAction(payload);
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('user_role');
      localStorage.removeItem('viewer_role');
      localStorage.removeItem('user_profile');
      localStorage.removeItem('user_fullName');
      localStorage.removeItem('selected_organization');
      localStorage.removeItem('selected_organization_id');
      localStorage.removeItem('selected_organization_role');
      localStorage.removeItem('selected_organization_plan');
      localStorage.removeItem('email');
      localStorage.removeItem('created_at');
      localStorage.removeItem('is_owner');
      localStorage.removeItem('user_timezone');
      history.push('/auth/login');
      loginProfileCreatedReset();
    }
  };

  return (
    <ChangePasswordContainer>
      <CollapseContainer onClick={e => toggleShowCart()}>
        <ActivityTextSection>
          <CommonText fontSize="16px" title>
            {t('change_password')}
          </CommonText>
          <CommonText $label fontWeight="500">
            {t('strong_password_text')}
          </CommonText>
        </ActivityTextSection>
        <ArrowButton src={arrow} collapsed={modalOpen} />
      </CollapseContainer>
      {modalOpen && (
        <ChangePassDiv collapsed={modalOpen}>
          <ChangePasswordForm>
            <InputWithLabelSection>
              <InputLabel>{t('enter_current_password')}</InputLabel>
              <DivPassword>
                <InputField
                  autocomplete="false"
                  type={showOldPassword ? 'text' : 'password'}
                  name="old_password"
                  id="password"
                  value={passwordOld}
                  onChange={e => handleChange(e)}
                  margin
                />
                <ShowPasswordIcon
                  old
                  src={showOldPassword ? hidePassword : viewPassword}
                  alt=""
                  active={passwordIsFocused}
                  onClick={e => toggleShowPassword('password')}
                />
              </DivPassword>
            </InputWithLabelSection>
          </ChangePasswordForm>

          <ChangePasswordForm alignItems="start" margin="20px 0 0 0">
            <InputWithLabelSection>
              <InputLabel>{t('enter_new_password')}</InputLabel>
              <DivPassword>
                <InputField
                  type={showNewPassword ? 'text' : 'password'}
                  name="new_password"
                  id="newPassword"
                  value={password}
                  onChange={e => handleChange(e)}
                  // invalid={!!passwordState.validationMessages.new_password}
                />
                <ShowPasswordIcon
                  src={showNewPassword ? hidePassword : viewPassword}
                  alt=""
                  active={passwordIsFocused}
                  onClick={e => toggleShowPassword('Newpassword')}
                />
              </DivPassword>
              {((password && password.length > 0) || passwordIsFocused) && (
                <PasswordStrengthChecker password={password} />
              )}
            </InputWithLabelSection>
            <InputWithLabelSection>
              <InputLabel>{t('confirm_new_password')}</InputLabel>
              <ChangePasswordForm columns="100%" margin="0 25px 0 0">
                <DivPassword>
                  <InputField
                    type="password"
                    name="confirm_password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={e => handleChange(e)}
                    isValid={passwordMatch}
                  />
                </DivPassword>
                {passwordMatch && (
                  <GreenLabel>{t('passwords_match')}!</GreenLabel>
                )}
              </ChangePasswordForm>
            </InputWithLabelSection>
          </ChangePasswordForm>

          <CommonFlex margin="25px 0 0 0" gap="12px">
            <WhiteButton
              type="cancel"
              padding="0 45px"
              onClick={e => toggleShowCart()}
            >
              {t('cancel')}
            </WhiteButton>
            <PrimaryButton
              onClick={() => savePassword()}
              disabled={
                !password_validate(password) ||
                !passwordMatch ||
                passwordOld.length < 1
              }
            >
              {t('change_password')}
            </PrimaryButton>
          </CommonFlex>
        </ChangePassDiv>
      )}
    </ChangePasswordContainer>
  );
};

export default ChangePassword;
