import styled from 'styled-components';

export const ResetPasswordWrpper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 540px;
  margin: auto;
  align-items: stretch;
  gap: 40px;
  padding-bottom: 30px;
  color: #000000;
`;

export const ResetPasswordComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  align-items: center;
`;
