import { connect } from "react-redux";

// Component
import TimesheetDaily from "./TimesheetDaily";
// Actions
import { getDailyTimesheets } from "../timesheetActions";
import { getOrganizationMembersList } from "../../Projects/projectsActions";
import { getOfficesShortList } from "../../Offices/officesActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.timesheet.dailyTimesheetLoading,
  timesheet: state.timesheet.dailyList,
  orgMembersList: state.project.orgMembersList,
  officesShortList: state.office.officesShortList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getDailyTimesheets: (params) => dispatch(getDailyTimesheets(params)),
  getOfficesShortList: (params) => dispatch(getOfficesShortList(params)),
  getOrganizationMembersList: (params) =>
    dispatch(getOrganizationMembersList(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TimesheetDaily);
