import styled from 'styled-components';

import { RoundImageTooltip } from '../../styledComponents/createProject';
import { ActionTooltip } from '../../styledComponents/tasks';

export const ActionIconSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30px);
  gap: 12px;
  justify-content: center;
  align-items: center;
`;
export const RoundIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: 1px solid;
  border-color: ${({ type }) =>
    type === 'edit' ? `#434343` : type === 'duplicate' ? `#6776CB` : `#EF717D`};
  background-color: ${({ theme }) => theme.colors.main.white};
  cursor: pointer;
  &:hover {
    box-shadow: 3px 3px 10px #c2cce1;
  }
  &:hover ${ActionTooltip} {
    display: flex;
  }
`;
export const CollapseSection = styled.div`
  display: grid;
  height: ${({ open }) => (open ? `135px` : `0`)};
  transition: height 0.5s ease;
  overflow: hidden;
`;

export const TaskInfoSection = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(100px, 120px)) minmax(200px, 250px);
  gap: 30px;
  justify-content: space-evenly;
  align-items: center;
  padding: 22px 25px;
  border-radius: 10px;
  border: 1px solid #c2cce1;
  background-color: ${({ theme }) => theme.colors.main.white};
  margin: 0px 20px 20px;
`;
export const TaskStatusButton = styled.div`
  border-radius: ${({ theme }) => theme.borderRadii.button};
  padding: 5px;
  background-color: ${({ bgColor, status, theme }) =>
    bgColor
      ? bgColor
      : status && status === 'partially complete'
      ? theme.colors.main.warning
      : status && status === 'complete'
      ? theme.colors.main.success
      : status && status === 'in progress'
      ? theme.colors.main.blue
      : status && status === 'totally missed'
      ? theme.colors.main.error
      : theme.colors.main.gray};
  color: ${({ textColor, status, theme }) =>
    textColor ? textColor : theme.colors.main.white};
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
`;
export const ApprovalBadge = styled.div`
  border-radius: 35px;
  border: 1px solid;
  border-color: ${({ bgColor, status, theme }) =>
    bgColor
      ? bgColor
      : status && status === 'pending'
      ? theme.colors.main.warning
      : status && status === 'approved'
      ? theme.colors.main.success
      : status && status === 'rejected'
      ? theme.colors.main.error
      : theme.colors.main.gray};
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.colors.main.white};
  color: ${({ bgColor, status, theme }) =>
    bgColor
      ? bgColor
      : status && status === 'pending'
      ? theme.colors.main.warning
      : status && status === 'approved'
      ? theme.colors.main.success
      : status && status === 'rejected'
      ? theme.colors.main.error
      : theme.colors.main.gray};
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
`;

export const AssigneeRoundImg = styled.div`
  width: 40px;
  height: 40px;
  background-image: ${({ image }) => image && `url(${image})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  position: relative;
  &:hover ${RoundImageTooltip} {
    display: flex;
  }
`;
