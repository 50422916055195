// Effects
// Constants
import {
  ADD_MEMBER,
  ADD_MEMBER_FAILURE,
  ADD_MEMBER_SUCCESS,
  ARCHIVE_MEMBERS,
  ARCHIVE_MEMBERS_FAILURE,
  ARCHIVE_MEMBERS_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_REQUEST_FAILURE,
  CREATE_USER_REQUEST_SUCCESS,
  DELETE_MEMBER_INVITATION,
  DELETE_MEMBER_INVITATION_FAILURE,
  DELETE_MEMBER_INVITATION_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_REQUEST_FAILURE,
  DELETE_USER_REQUEST_SUCCESS,
  GET_INVITED_MEMBERS,
  GET_INVITED_MEMBERS_FAILURE,
  GET_INVITED_MEMBERS_SUCCESS,
  GET_MEMBER_DETAILS,
  GET_MEMBER_DETAILS_FAILURE,
  GET_MEMBER_DETAILS_SUCCESS,
  GET_MEMBER_MONTHLY_EARNING,
  GET_MEMBER_MONTHLY_EARNING_FAILURE,
  GET_MEMBER_MONTHLY_EARNING_SUCCESS,
  GET_MEMBER_WEEKLY_EARNING,
  GET_MEMBER_WEEKLY_EARNING_FAILURE,
  GET_MEMBER_WEEKLY_EARNING_SUCCESS,
  GET_USERS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  GET_USER_UPDATE_REQUESTS,
  GET_USER_UPDATE_REQUESTS_FAILURE,
  GET_USER_UPDATE_REQUESTS_SUCCESS,
  INVITE_MEMBER,
  INVITE_MEMBER_FAILURE,
  INVITE_MEMBER_SUCCESS,
  REJECT_USER_REQUEST,
  REJECT_USER_REQUEST_FAILURE,
  REJECT_USER_REQUEST_SUCCESS,
  REMOVE_MEMBER,
  REMOVE_MEMBER_FAILURE,
  REMOVE_MEMBER_SUCCESS, // payment configuration
  SET_PAYMENT_CONFIGURATION,
  SET_PAYMENT_CONFIGURATION_FAILURE,
  SET_PAYMENT_CONFIGURATION_SUCCESS,
  UPDATE_MEMBER,
  UPDATE_MEMBER_FAILURE,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_PAYMENT_CONFIGURATION,
  UPDATE_PAYMENT_CONFIGURATION_FAILURE,
  UPDATE_PAYMENT_CONFIGURATION_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_REQUEST_FAILURE,
  UPDATE_USER_REQUEST_SUCCESS,
} from 'modules/constants';
import { all, call, put, take, takeLatest } from 'redux-saga/effects';
import { defaultApi } from 'utils/axiosApi';

// Service
// import { defaultApi } from '../../../utils/axiosApi';

//Handle login request

export function* getUserRequests({ payload }) {
  console.log('🚀 ~ function*getUserRequests ~ payload:', payload);
  const { page, name, office_id, role, user_code, action_status } = payload;
  try {
    const url = `/albait/internal-api/?page=${page}${
      name ? `&name=${name}` : ''
    }${action_status ? `&action_status=${action_status}` : ''}${
      office_id ? `&office_id=${office_id}` : ''
    }${role ? `&role=${role}` : ''}${
      user_code ? `&user_code=${user_code}` : ''
    }`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_USERS_SUCCESS,
      payload: { data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: GET_USERS_FAILURE,
      payload: err,
    });
  }
}

export function* getUserUpdateRequests({ payload }) {
  const { page, name, office_id, role, user_code } = payload;
  try {
    const url = `/albait/internal-api/?page=${page}&action_status=update${
      name ? `&name=${name}` : ''
    }${office_id ? `&office_id=${office_id}` : ''}${
      role ? `&role=${role}` : ''
    }${user_code ? `&user_code=${user_code}` : ''}`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_USER_UPDATE_REQUESTS_SUCCESS,
      payload: { data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: GET_USER_UPDATE_REQUESTS_FAILURE,
      payload: err,
    });
  }
}
export function* deleteUserRequest({ payload }) {
  const { user_id } = payload;
  try {
    const url = `/albait/service-user/${user_id}/`;
    const method = 'DELETE';
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_USER_REQUEST_SUCCESS,
      payload: { user_id: user_id, data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: DELETE_USER_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export function* updateUserRequest({ payload }) {
  try {
    const {
      first_name,
      last_name,
      office,
      email,
      role,
      user_code,
      phone,
      user_id,
    } = payload;

    const data = new FormData();

    first_name && data.append('first_name', first_name);
    last_name && data.append('last_name', last_name);
    office && data.append('office', office);
    email && data.append('email', email);
    role && data.append('role', role);
    user_code && data.append('user_code', user_code);
    phone && data.append('phone', phone);

    const url = `/albait/service-user/${user_id}/`;
    const method = 'PATCH';
    const response = yield defaultApi(url, method, data);
    yield put({
      type: UPDATE_USER_REQUEST_SUCCESS,
      payload: { user_id: user_id, data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: UPDATE_USER_REQUEST_FAILURE,
      payload: err,
    });
  }
}
export function* rejectUserRequest({ payload }) {
  const { user_id } = payload;
  try {
    const url = `/albait/internal-api/${user_id}/`;
    const method = 'DELETE';
    const response = yield defaultApi(url, method);
    yield put({
      type: REJECT_USER_REQUEST_SUCCESS,
      payload: { user_id: user_id, data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: REJECT_USER_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export function* handleCreateUserRequest({ payload }) {
  try {
    const {
      first_name,
      last_name,
      office,
      email,
      password,
      role,
      user_code,
      phone,
      image,
      user_id,
    } = payload;
    const data = new FormData();
    data.append('first_name', first_name);
    data.append('last_name', last_name);
    data.append('office', office);
    data.append('email', email);
    data.append('role', role);
    data.append('user_code', user_code);
    phone?.length > 0 && data.append('phone', phone);

    const url = `/albait/service-user/`;
    const method = 'POST';
    const response = yield defaultApi(url, method, data);
    yield put({
      type: CREATE_USER_REQUEST_SUCCESS,
      payload: { user_id: user_id, data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: CREATE_USER_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export function* getMemberDetails({ payload }) {
  const { user_id } = payload;
  try {
    const url = `/albait/users/${user_id}/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_MEMBER_DETAILS_SUCCESS,
      payload: { data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: GET_MEMBER_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_USERS, getUserRequests),
    takeLatest(GET_USER_UPDATE_REQUESTS, getUserUpdateRequests),
    takeLatest(CREATE_USER_REQUEST, handleCreateUserRequest),
    takeLatest(DELETE_USER_REQUEST, deleteUserRequest),
    takeLatest(UPDATE_USER_REQUEST, updateUserRequest),
    takeLatest(REJECT_USER_REQUEST, rejectUserRequest),

    takeLatest(GET_MEMBER_DETAILS, getMemberDetails),
  ]);
}
