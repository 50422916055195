import {
  AuthPageLeftContainer,
  LogoImg,
  SloganText,
  AuthPageArtwork,
} from "../../styledComponents/authLayout";
import { CommonFlex, CommonImage } from "../../styledComponents/common";

import Logo from "../../assets/img/logo/login_logo.svg";
import AuthArtwork from "../../assets/img/common/auth_artwork.png";
import AlbaitLogo from "../../assets/img/logo/albait-logo.png";

const AuthPageLeftSection = () => {
  return (
    <AuthPageLeftContainer>
      <CommonFlex gap="40px">
        <LogoImg src={Logo} alt="" />
        <CommonImage src={AlbaitLogo} alt="" style={{ width: "150px" }} />
      </CommonFlex>

      <SloganText>Al Bait Guests by Apploye</SloganText>
      <AuthPageArtwork src={AuthArtwork} alt="" />
    </AuthPageLeftContainer>
  );
};

export default AuthPageLeftSection;
