import React, { useEffect, useState } from 'react';

import angleLeft from '../../assets/img/icons/angle-left.svg';
import angleRight from '../../assets/img/icons/angle-right.svg';
import './style.css';

const DashboardCarousel = ({ children, next = true, prev = true }) => {
  const [moveClass, setMoveClass] = useState('');
  const [carouselItems, setCarouselItems] = useState(children);

  useEffect(() => {
    document.documentElement.style.setProperty('--num', carouselItems.length);
  }, [carouselItems]);

  const handleAnimationEnd = () => {
    console.log(moveClass);
    if (moveClass === 'prev') {
      shiftNext([...carouselItems]);
    } else if (moveClass === 'next') {
      shiftPrev([...carouselItems]);
    }
    setMoveClass('');
  };

  const shiftPrev = copy => {
    for (let i = 0; i < 3; i++) {
      let lastCard = copy.pop();
      copy.splice(0, 0, lastCard);
    }
    setCarouselItems(copy);
  };

  const shiftNext = copy => {
    for (let i = 0; i < 3; i++) {
      let firstCard = copy.shift();
      copy.splice(copy.length, 0, firstCard);
    }
    setCarouselItems(copy);
  };

  return (
    <div className="ReactCarouselWrapper">
      <div className="CarouselController">
        {prev && (
          <button onClick={() => setMoveClass('next')} className="prev">
            <img src={angleLeft} alt="angle-left" />
          </button>
        )}
        {next && (
          <button onClick={() => setMoveClass('prev')} className="next">
            <img src={angleRight} alt="angle-right" />
          </button>
        )}
      </div>
      <ul
        onAnimationEnd={handleAnimationEnd}
        className={`${moveClass} carouselItemContainer`}
      >
        {carouselItems &&
          carouselItems.map(item => (
            <li className="carouselItem" key={Math.random()}>
              {item}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default DashboardCarousel;
