import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect, useState } from 'react';

import Avatar5 from '../../../assets/img/avatar_5.svg';
import phoneIcon from '../../../assets/img/icons/phone.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent';
import { WhiteButtonWithIcon } from '../../../styledComponents/buttons';
import {
  CardTitle,
  CommonFlex,
  CommonText,
  Container,
  ContentHeader,
  PageTitle,
} from '../../../styledComponents/common';
import { UpperTitleButtonSection } from '../../../styledComponents/members';
import { CommImageNameComp } from '../CommonComponents';
import {
  UpdateDataSectionContainer,
  UserInfoButton,
} from '../UpdateUserStyles';

const UserDetails = ({
  isLoading,
  history,
  location,
  selectedOrganization,
  clearMemberDetailsData,
  getMemberDetails,
  removeMember,
  memberDetails,
  state,
}) => {
  console.log('🚀 ~ state:', state, isLoading);
  const deleteRequestPage = history.location?.pathname.includes(
    '/user-update/user-delete-request',
  );

  const [modal, setModal] = useState(false);

  useEffect(() => {
    return () => {
      clearMemberDetailsData();
    };
  }, []);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (location.search) {
        const queryParsed = queryString.parse(location.search);
        let payload = {
          organization_id: selectedOrganization?.id,
          user_id: queryParsed?.id,
        };
        getMemberDetails(payload);
      }
    }
  }, [selectedOrganization]);

  const toggleDeleteModal = () => {
    setModal(!modal);
  };

  const handleDeleteMember = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      memberDetails &&
      memberDetails?.userId
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        user_id: memberDetails?.userId,
      };
      removeMember(payload);
      setTimeout(() => {
        toggleDeleteModal();
        history.push('/user/members');
      }, 300);
    }
  };

  const onProjectHover = index => {
    setProjectIndex(index);
  };

  const onTeamHover = index => {
    setTeamIndex(index);
  };

  const tooltipChange = index => {
    setTooltip(index);
  };
  return (
    <div className="content">
      {' '}
      <PageTitle>
        {deleteRequestPage ? t('delete_request') : t('users')}
      </PageTitle>{' '}
      <ContentHeader>
        <BackButtonComponent
          onClick={() =>
            history.push(
              deleteRequestPage
                ? '/user/user-update/delete-request'
                : '/user/user-update/create-request',
            )
          }
          subTitle={
            deleteRequestPage
              ? t('back_to_delete_request')
              : t('back_to_create_request')
          }
        />
      </ContentHeader>{' '}
      <Container style={{ padding: `30px 0` }}>
        {isLoading === true ? (
          <ComponentCircleLoader />
        ) : memberDetails && memberDetails !== null ? (
          <>
            <UpperTitleButtonSection>
              <CardTitle>{t('user_details')}</CardTitle>
              {selectedOrganization &&
                deleteRequestPage &&
                selectedOrganization.role !== 'member' && (
                  <UpperTitleButtonSection gap="10px" padding="0">
                    {memberDetails?.role !== 'owner' && (
                      <WhiteButtonWithIcon
                        type="cancel"
                        fontSize="14px"
                        onClick={() => toggleDeleteModal()}
                      >
                        {t('cancel')}
                      </WhiteButtonWithIcon>
                    )}
                    {memberDetails?.role !== 'owner' && (
                      <WhiteButtonWithIcon
                        type="delete"
                        fontSize="14px"
                        onClick={() => toggleDeleteModal()}
                      >
                        {t('delete')}
                      </WhiteButtonWithIcon>
                    )}
                  </UpperTitleButtonSection>
                )}
            </UpperTitleButtonSection>
            <UpdateDataSectionContainer>
              <CommImageNameComp
                imgSource={
                  memberDetails.avatar ? memberDetails.avatar : Avatar5
                }
                name={`${memberDetails.first_name} ${memberDetails.last_name}`}
                email={memberDetails?.email}
                size="60px"
              />
              <CommonFlex direction="column" gap="10px" alignItems="start">
                <CommonText>{t('contact_number')}</CommonText>
                <CommonFlex>
                  <img
                    src={phoneIcon}
                    alt=""
                    style={{ height: '16px', width: '16px' }}
                  />
                  <CommonText>{memberDetails?.phone}</CommonText>
                </CommonFlex>
              </CommonFlex>

              <CommonFlex>
                <CommonFlex direction="column" gap="5px" alignItems="start">
                  <CommonText margin="0">{t('user_id')}</CommonText>
                  <UserInfoButton>
                    <CommonText color="#000000">
                      {memberDetails?.user_code}
                    </CommonText>
                  </UserInfoButton>
                </CommonFlex>
                <CommonFlex direction="column" gap="5px" alignItems="start">
                  <CommonText>{t('offices')}</CommonText>
                  <UserInfoButton type="office">
                    <CommonText color="#ffffff">
                      {memberDetails?.office?.name}
                    </CommonText>
                  </UserInfoButton>
                </CommonFlex>
                <CommonFlex direction="column" gap="5px" alignItems="start">
                  <CommonText>{t('role')}</CommonText>
                  <UserInfoButton>
                    <CommonText color="#000000">
                      {memberDetails?.role}
                    </CommonText>
                  </UserInfoButton>
                </CommonFlex>
              </CommonFlex>
            </UpdateDataSectionContainer>
          </>
        ) : (
          <NoDataComponent title="Sorry! could not fetch member details. Please try again!" />
        )}
      </Container>
    </div>
  );
};

export default UserDetails;
