import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BottomPagination from '../../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../../components/Pagination/TopPagination/TopPagination';
import {
  CommonGrid,
  HeaderContainer,
  PageTitle,
} from '../../../styledComponents/common';
import UsersTable from './UpdateRequestTable';
import UsersFilter from './UsersFilter';

const UpdateRequest = ({
  officesShortList,
  getOfficesShortList,
  getUserUpdateRequests,
  history,
  userUpdateRequests,
  isLoading,
  updateMember,
  // pagination
  totalMembersCount,
  pageSize,
  updateUserRequest,
  updateRequestLoading,
  rejectUserRequest,
  rejectRequestLoading,
  rejectedUserId,
}) => {
  const { t } = useTranslation();
  const [selectedMember, setSelectedMember] = useState(null);
  const [memberList, setMemberList] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState('');

  const [userCodeSearchTerm, setUserCodeSearchTerm] = useState('');
  const [currentUserCodeSearchedTerm, setCurrentUserCodeSearchedTerm] =
    useState('');

  const [selectedRole, setSelectedRole] = useState({
    label: t('all_roles'),
    value: null,
  });

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const handleRejectMember = () => {
    let payload = {};
    const { old_data, new_data } = selectedMember;
    payload.user_id = new_data.id;
    rejectUserRequest(payload);
    setSelectedMember(null);
  };

  const handleUpdateUser = () => {
    //
    const payload = {};
    const { old_data, new_data } = selectedMember;
    payload.user_id = new_data.original_user_id;
    if (old_data.first_name !== new_data.first_name)
      payload.first_name = new_data.first_name;
    if (old_data.last_name !== new_data.last_name)
      payload.last_name = new_data.last_name;
    if (old_data.email !== new_data.email) payload.email = new_data.email;
    // if (old_data.username !== new_data.username)
    //   payload.username = new_data.username;
    if (old_data.role !== new_data.role) payload.role = new_data.role;

    if (old_data.office?.id !== new_data.office?.id)
      payload.office = new_data.office?.id;
    if (old_data.phone !== new_data.phone) payload.phone = new_data.phone;
    if (old_data.user_code !== new_data.user_code)
      payload.user_code = new_data.user_code;
    updateUserRequest(payload);
    setSelectedMember(null);
  };

  useEffect(() => {
    setSelectedOffice({
      label: t('all_offices'),
      value: null,
    });
    getUserUpdateRequests({ page: 1, action_status: 'update' });
  }, []);

  useEffect(() => {
    if (userUpdateRequests && userUpdateRequests.length > 0) {
      setMemberList(userUpdateRequests);
    } else if (userUpdateRequests && userUpdateRequests.length === 0) {
      setMemberList([]);
    }
  }, [userUpdateRequests]);

  const handleSearchByName = () => {
    const payload = {
      page: 1,
      action_status: 'update',
    };
    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm('');
    }
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (selectedRole && selectedRole.value) {
      payload.role = selectedRole.value;
    }
    if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
      payload.user_code = userCodeSearchTerm.toLowerCase().trim();
    }
    getUserUpdateRequests(payload);

    setCurrentPageNumber(1);
  };
  const handleClearSearch = () => {
    if (currentSearchedTerm) {
      const payload = {
        page: 1,
        action_status: 'update',
      };
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }
      if (selectedRole && selectedRole.value) {
        payload.role = selectedRole.value;
      }
      if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
        payload.user_code = userCodeSearchTerm.toLowerCase().trim();
      }
      getUserUpdateRequests(payload);

      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setSearchTerm('');
      setCurrentSearchedTerm('');
    }, 100);
  };

  const handleSearchByUserCode = () => {
    const payload = {
      page: 1,
      action_status: 'update',
    };
    if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
      payload.user_code = userCodeSearchTerm.toLowerCase().trim();
      setCurrentUserCodeSearchedTerm(userCodeSearchTerm);
    } else {
      setCurrentUserCodeSearchedTerm('');
    }
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (selectedRole && selectedRole.value) {
      payload.role = selectedRole.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
    }
    getUserUpdateRequests(payload);

    setCurrentPageNumber(1);
  };
  const handleClearUserCodeSearch = () => {
    if (currentUserCodeSearchedTerm) {
      const payload = {
        page: 1,
        action_status: 'update',
      };
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }
      if (selectedRole && selectedRole.value) {
        payload.role = selectedRole.value;
      }
      if (searchTerm && searchTerm.trim()) {
        payload.name = searchTerm.toLowerCase().trim();
      }
      getUserUpdateRequests(payload);

      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setUserCodeSearchTerm('');
      setCurrentUserCodeSearchedTerm('');
    }, 100);
  };

  const handlePageChange = page => {
    // console.log(page);
    const payload = {
      page: page,
      action_status: 'update',
    };
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
    }
    if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
      payload.user_code = userCodeSearchTerm.toLowerCase().trim();
    }
    if (selectedRole && selectedRole.value) {
      payload.role = selectedRole.value;
    }
    getUserUpdateRequests(payload);
    setCurrentPageNumber(page);
  };

  const selectOffice = e => {
    const payload = {
      page: 1,
      action_status: 'update',
    };
    if (e && e.value) {
      payload.office_id = e.value;
    }
    if (selectedRole && selectedRole.value) {
      payload.role = selectedRole.value;
    }
    getUserUpdateRequests(payload);
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setUserCodeSearchTerm('');
    setCurrentUserCodeSearchedTerm('');
    setSelectedOffice(e);
  };

  const handleRoleChange = e => {
    const payload = {
      page: 1,
      action_status: 'update',
    };
    if (e && e.value) {
      payload.role = e.value;
    }
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.name = searchTerm.toLowerCase().trim();
    }
    if (userCodeSearchTerm && userCodeSearchTerm.trim()) {
      payload.user_code = userCodeSearchTerm.toLowerCase().trim();
    }
    getUserUpdateRequests(payload);
    setCurrentPageNumber(1);
    setSelectedRole(e);
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const onUserCodeSearchTermChange = e => {
    const { value } = e.target;
    setUserCodeSearchTerm(value);
  };

  return (
    <div className="content">
      <CommonGrid alignItem="center">
        <PageTitle>{t('update_request')}</PageTitle>
      </CommonGrid>

      <HeaderContainer columns="1fr auto">
        <UsersFilter
          officesShortList={officesShortList}
          getOfficesShortList={getOfficesShortList}
          selectedOffice={selectedOffice}
          selectOffice={selectOffice}
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          handleSearchByName={handleSearchByName}
          handleClearSearch={handleClearSearch}
          userCodeSearchTerm={userCodeSearchTerm}
          onUserCodeSearchTermChange={onUserCodeSearchTermChange}
          handleSearchByUserCode={handleSearchByUserCode}
          handleClearUserCodeSearch={handleClearUserCodeSearch}
          selectedRole={selectedRole}
          onRoleChange={handleRoleChange}
        />
      </HeaderContainer>

      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <TopPagination
          itemName={t('members')}
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}

      <UsersTable
        membersList={memberList}
        history={history}
        searchTerm={searchTerm}
        isLoading={isLoading}
        handleUpdateUser={handleUpdateUser}
        handleRejectMember={handleRejectMember}
        {...{ selectedMember, setSelectedMember }}
      />
      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export default UpdateRequest;
