import styled from 'styled-components';

export const AddUserRequestButton = styled.div`
  border-radius: ${({ theme }) => theme.borderRadii.button};
  padding: 10px 15px;
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  background-color: ${({ bgColor, type, theme }) =>
    bgColor
      ? bgColor
      : type === 'reject'
      ? theme.colors.main.white
      : type === 'approve'
      ? theme.colors.main.success
      : theme.colors.main.details};
  color: ${({ textColor, type, theme }) =>
    textColor
      ? textColor
      : type === 'reject'
      ? theme.colors.main.error
      : theme.colors.main.white};
  font-size: 11px;
  border: 1px solid
    ${({ borderColor, type, theme }) =>
      borderColor
        ? borderColor
        : type === 'reject'
        ? theme.colors.main.error
        : null};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const UpdateRequestContainer = styled.div`
  display: grid;
  position: relative;
  background-color: ${({ bgColor, theme }) => bgColor || theme.card.bgColor};
  border-radius: ${({ borderRadius, theme }) =>
    borderRadius || theme.card.borderRadius};
  border: 1px solid
    ${({ borderColor, theme }) => borderColor || theme.card.borderColor};
  padding: ${({ padding, theme }) => padding || theme.card.padding};
  margin-top: ${({ marginTop, theme }) => marginTop || theme.card.marginTop};
  gap: ${({ gap }) => gap && gap};
  @media (max-width: 1250px) {
    max-width: 100vw;
    overflow: auto;
  }
`;

export const UpdateDataSectionContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 35px;
  padding: ${({ padding, theme }) => padding || '30px'};
  gap: ${({ gap }) => gap && gap};
`;

export const AbsoluteContainer = styled.div`
  background: #f1f3f4;
  height: 100%;
  width: ${({ selectedMember }) => (selectedMember ? '500px' : '0px')};
  position: absolute;
  right: 0px;
  overflow: hidden;
  justify-content: center;
`;

export const StickyDiv = styled.div`
  background: #f1f3f4;
  width: 343px;
  position: sticky;
  right: 0px;
  top: 0;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const UserInfoButton = styled.div`
  color: ${({ type }) => (type === 'office' ? '#FFFFFF' : '#000000')};
  padding: 10px 30px;
  border: 1px solid ${({ type }) => (type === 'office' ? '#606CFF' : '#c2cce1')};
  background-color: ${({ type }) =>
    type === 'office' ? '#606CFF' : '#FFFFFF'};
  border-radius: 6px;
`;
