import AddMultipleAssigneeDropdown from 'components/CustomDropdown/AddMultipleAssigneeDropdown';
import TimeRangeComponentNew from 'custom_modules/Timesheet/Daily/TimeRangeComponentNew';
import TimeDuration from 'custom_modules/Timesheet/Daily/timeDuration';
import { t } from 'i18next';

import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import Select from '../../../components/ReactSelectDropdown';
import { filterOption } from '../../../components/ReactSelectDropdown/ReactSelectDropdown';
import { PrimaryButton } from '../../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonText,
  Container,
  ContentHeader,
  FormAlert,
  InputField,
  InputLabel,
  InputTextArea,
  InputWithLabelSection,
  PageTitle,
  RedStar,
} from '../../../styledComponents/common';
import DefaultTaskLocationWarningModal from '../DefaultTaskLocationWarningModal';
import AddLocationModal from './AddLocationModal';
import {
  AssigneeSection,
  InputSection,
  TimeRangeSection,
} from './createTaskStyles';

const TaskForm = ({
  popupOpen,
  popupToggle,
  addTaskLocation,
  addTaskLocationLoading,
  createdTaskLocationInfo,
  getTaskLocation,
  getOfficesShortList,
  officesShortList,
  isSameLocationWarningModalOpen,
  popupToggleSameLocationWarningModal,
  handleSave,
  isLoading,
  history,
  taskName,
  onTaskNameChange,
  saveErrors,
  selectedOffice,
  officeOptions,
  onOfficeChange,
  memberShortListIsLoading,
  selectedAssignees,
  orgMembersList,
  handleAssignMember,
  handleRemoveMember,
  taskDescription,
  onTaskDescriptionChange,
  selectedLocation,
  taskLocationOptions,
  onLocationChange,
  selectedTaskType,
  statusOptions,
  onTaskTypeChange,
  endDate,
  onEndDateChange,
  startDate,
  onStartDateChange,
  handleStartTime,
  handleEndTime,
  startTime,
  endTime,
  creating,
}) => {
  return (
    <div className="content">
      <AddLocationModal
        isOpen={popupOpen}
        toggle={popupToggle}
        addTaskLocation={addTaskLocation}
        addTaskLocationLoading={addTaskLocationLoading}
        createdTaskLocationInfo={createdTaskLocationInfo}
        getTaskLocation={getTaskLocation}
        getOfficesShortList={getOfficesShortList}
        officesShortList={officesShortList}
        onLocationChange={onLocationChange}
      />
      <DefaultTaskLocationWarningModal
        isOpen={isSameLocationWarningModalOpen}
        toggle={popupToggleSameLocationWarningModal}
        handleCreate={handleSave}
        isLoading={isLoading}
        creating={creating}
      />
      <PageTitle>{creating ? t('create_task') : t('edit_task')}</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push('/user/tasks')}
          subTitle={t('back_to_all_tasks')}
        />
      </ContentHeader>
      <Container padding="30px" gap="20px">
        <CommonText name title fontSize="16px">
          {t('add_task_details')}
        </CommonText>
        <InputSection>
          <InputWithLabelSection>
            <InputLabel>
              {t('task_name')} <RedStar>*</RedStar>
            </InputLabel>
            <InputField
              type="text"
              placeholder={t('type_task_name')}
              value={taskName}
              onChange={e => onTaskNameChange(e)}
              style={
                saveErrors &&
                saveErrors[`taskName`] && {
                  border: `1px solid #fe5969`,
                  backgroundColor: `rgba(252, 87, 104, 0.05)`,
                }
              }
            />
            {saveErrors && saveErrors['taskName'] && (
              <FormAlert>{saveErrors['taskName']}</FormAlert>
            )}
          </InputWithLabelSection>

          <InputWithLabelSection>
            <InputLabel>
              {t('office')} <RedStar>*</RedStar>
            </InputLabel>
            <Select
              isSearchable
              value={selectedOffice}
              options={officeOptions}
              placeholder={t('select_office')}
              isDisabled={officesShortList && officesShortList.length === 1}
              onChange={e => {
                onOfficeChange(e);
              }}
              styles={CreateProjectDropdown()}
              filterOption={filterOption}
            />
          </InputWithLabelSection>

          <AssigneeSection>
            <InputLabel>
              {t('assignee')}
              <RedStar>*</RedStar>
            </InputLabel>

            <AddMultipleAssigneeDropdown
              numberOfVisibleMembers={8}
              isLoading={memberShortListIsLoading}
              assigneesList={selectedAssignees}
              membersList={orgMembersList}
              handleAssign={handleAssignMember}
              handleRemove={handleRemoveMember}
            />

            {saveErrors && saveErrors['selectedAssignees'] && (
              <FormAlert>{saveErrors['selectedAssignees']}</FormAlert>
            )}
          </AssigneeSection>

          <div />

          {/* <InputWithLabelSection>
          <InputLabel>Requires Approval</InputLabel>
          <CommonFlex>
            <ToggleButton
              onClick={() => approvalToggle()}
              active={requiresApproval}
            >
              <ToggleButtonRound active={requiresApproval} />
            </ToggleButton>
            <InputLabel>{requiresApproval ? `Yes` : `No`}</InputLabel>
          </CommonFlex>
        </InputWithLabelSection> */}

          <InputWithLabelSection style={{ gridColumn: `1 / span 2` }}>
            <InputLabel>{t('description')}</InputLabel>
            <InputTextArea
              text="Description"
              cols="35"
              rows="7"
              value={taskDescription}
              placeholder={t('type_description_here')}
              onChange={e => onTaskDescriptionChange(e)}
              height="auto"
              style={
                saveErrors &&
                saveErrors[`taskDescription`] && {
                  border: `1px solid #fe5969`,
                  backgroundColor: `rgba(252, 87, 104, 0.05)`,
                }
              }
            ></InputTextArea>
            {saveErrors &&
              saveErrors['taskDescription'] &&
              saveErrors['taskDescription'] !== undefined && (
                <FormAlert>{saveErrors['taskDescription']}</FormAlert>
              )}
          </InputWithLabelSection>

          <InputWithLabelSection>
            <CommonGrid>
              <InputLabel>
                {t('location')} <RedStar>*</RedStar>
              </InputLabel>
              <ColoredText
                fontSize="12px"
                type="success"
                hover
                semibold
                style={{ cursor: `pointer` }}
                onClick={() => popupToggle()}
              >
                {t('add_new_location')}
              </ColoredText>
            </CommonGrid>
            <Select
              isSearchable
              value={selectedLocation}
              options={taskLocationOptions}
              placeholder={t('select_location')}
              onChange={e => {
                onLocationChange(e);
              }}
              styles={CreateProjectDropdown()}
              filterOption={filterOption}
            />
          </InputWithLabelSection>

          {/* <InputWithLabelSection>
          <CommonGrid>
            <InputLabel>
              Location Radius <RedStar>*</RedStar>
            </InputLabel>
            <ColoredText
              fontSize="12px"
              type="warning"
              hover
              semibold
              style={{ cursor: `pointer` }}
              onClick={() => setIsCustomRadius(true)}
            >
              Custom Radius
            </ColoredText>
          </CommonGrid>
          <CommonFlex justifyContent="space-between" gap="12px">
            {isCustomRadius ? (
              <CommonGrid
                columns="100px auto 20px"
                gap="5px"
                alignItem="center"
              >
                <InputField
                  value={radiusInput}
                  type="number"
                  placeholder="Type radius"
                  min={50}
                  onChange={(e) => {
                    setRadiusInput(parseInt(e.target.value));
                    if (saveErrors && saveErrors["radius"]) {
                      delete saveErrors["radius"];
                    }
                  }}
                  error={
                    saveErrors &&
                    saveErrors["radius"] &&
                    saveErrors["radius"] !== undefined
                  }
                />

                <CommonText>Metres</CommonText>
                <ClearSearchButton
                  onClick={() => {
                    setIsCustomRadius(false);
                    if (parseInt(radiusInput) === 50) {
                      setRadiusInput(50);
                    } else if (parseInt(radiusInput) === 200) {
                      setRadiusInput(200);
                    } else {
                      setRadiusInput(100);
                    }
                    if (saveErrors && saveErrors["radius"]) {
                      delete saveErrors["radius"];
                    }
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  <CommonImage src={crossWhite} alt="clear" />
                </ClearSearchButton>
              </CommonGrid>
            ) : (
              radiusOptions.map((option, i) => {
                return (
                  <RadiusButton
                    key={option}
                    active={radiusInput === option}
                    onClick={() => {
                      if (radiusInput !== option) {
                        setRadiusInput(option);
                        if (saveErrors && saveErrors["radius"]) {
                          delete saveErrors["radius"];
                        }
                      }
                    }}
                  >
                    {option} Metres
                  </RadiusButton>
                );
              })
            )}
          </CommonFlex>
        </InputWithLabelSection> */}

          <InputWithLabelSection>
            <InputLabel>
              {t('task_type')}
              <RedStar>*</RedStar>
            </InputLabel>
            <Select
              isSearchable
              value={selectedTaskType}
              options={statusOptions}
              placeholder={t('select_type')}
              onChange={e => {
                onTaskTypeChange(e);
              }}
              styles={CreateProjectDropdown()}
            />{' '}
            {saveErrors &&
              saveErrors['taskType'] &&
              saveErrors['taskType'] !== undefined && (
                <FormAlert>{saveErrors['taskType']}</FormAlert>
              )}
          </InputWithLabelSection>

          <InputWithLabelSection>
            <TimeRangeSection>
              <InputLabel>
                {t('start_date')}
                <RedStar>*</RedStar>
              </InputLabel>
              <InputLabel>
                {t('start_time')}
                <RedStar>*</RedStar>
              </InputLabel>
            </TimeRangeSection>
            <TimeRangeComponentNew
              initialTime={startTime}
              selectedDate={startDate}
              onDateChange={onStartDateChange}
              setTime={handleStartTime}
            />
            {saveErrors &&
              saveErrors['timeDifferenceStartTime'] &&
              saveErrors['timeDifferenceStartTime'] !== undefined && (
                <FormAlert>{saveErrors['timeDifferenceStartTime']}</FormAlert>
              )}
          </InputWithLabelSection>
          <CommonFlex direction="column" alignItems="start" gap="5px">
            <InputWithLabelSection>
              <TimeRangeSection>
                <InputLabel>
                  {t('end_date')}
                  <RedStar>*</RedStar>
                </InputLabel>
                <InputLabel>
                  {t('end_time')}
                  <RedStar>*</RedStar>
                </InputLabel>
              </TimeRangeSection>
              <TimeRangeComponentNew
                initialTime={endTime}
                selectedDate={endDate}
                onDateChange={onEndDateChange}
                setTime={handleEndTime}
                mode="end"
              />
              {saveErrors &&
                saveErrors['timeDifferenceEndTime'] &&
                saveErrors['timeDifferenceEndTime'] !== undefined && (
                  <FormAlert>
                    {t(saveErrors['timeDifferenceEndTime'])}
                  </FormAlert>
                )}
            </InputWithLabelSection>
            <TimeDuration
              startDate={startDate}
              endDate={endDate}
              startTime={startTime}
              endTime={endTime}
            />
          </CommonFlex>

          <PrimaryButton
            disabled={
              !taskName ||
              !selectedOffice ||
              selectedAssignees.length === 0 ||
              !selectedTaskType ||
              !selectedLocation ||
              !startTime ||
              !endTime
            }
            onClick={() => handleSave()}
          >
            {isLoading ? (
              <ButtonTextLoader
                loadingText={creating ? t('create_task') : t('edit_task')}
                fontSize="13px"
              />
            ) : creating ? (
              t('create_task')
            ) : (
              t('edit_task')
            )}
          </PrimaryButton>
          <div />
        </InputSection>
      </Container>
    </div>
  );
};

export default TaskForm;
