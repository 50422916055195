import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../../../assets/img/avatar_5.svg';
import ProjectDropdown from '../../../../components/CommonFilter/ProjectDropdown';
import AddSingleMemberDropdown from '../../../../components/CustomDropdown/AddSingleMemberDropdown';
import FilterDropDownStyle from '../../../../components/DropdownStyle/FilterDropDownStyle';
import Dropdown from '../../../../components/ReactSelectDropdown';
import { filterOption } from '../../../../components/ReactSelectDropdown/ReactSelectDropdown';
import DatePicker from '../../../../components/SingleDatePicker/SingleDatePicker';
import { SecondaryButton } from '../../../../styledComponents/buttons';
import {
  FilterLabel,
  InputWithLabelSection,
} from '../../../../styledComponents/common';
import { RouteMapFilterContainer } from './filterStyles';

const RouteMapFilter = props => {
  const { t } = useTranslation();
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [memberOptions, setMemberOptions] = useState([]);

  const [officeOptions, setOfficeOptions] = useState([]);

  const {
    getOrganizationMembersList,
    getOfficesShortList,

    organizationMembersList,
    officesShortList,

    selectedMember,
    setSelectedMember,

    selectedOffice,
    setSelectedOffice,

    selectedDate,
    setSelectedDate,

    getRouteMapLocations,
    getSingleEmployeeClockInOutList,
    getTaskCheckInOutList,

    lastAttendanceTimestamp,
  } = props;

  useEffect(() => {
    const role = localStorage.getItem('user_role');
    if (role === 'admin') {
      getOfficesShortList();
    } else {
      getOrganizationMembersList();
    }
  }, []);

  useEffect(() => {
    if (officesShortList && officesShortList.length > 0) {
      setSelectedOffice({
        label: officesShortList[0].name,
        value: officesShortList[0].id,
        ...officesShortList[0],
      });
      const list = [];
      officesShortList.forEach(office => {
        list.push({
          label: office.name,
          value: office.id,
          ...office,
        });
      });
      setOfficeOptions(list);
    } else if (officesShortList && officesShortList.length === 0) {
      setOfficeOptions([]);
    }
  }, [officesShortList]);

  useEffect(() => {
    if (selectedOffice && selectedOffice.id) {
      getOrganizationMembersList({ office_id: selectedOffice.id });
    }
  }, [selectedOffice]);

  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      selectMember(organizationMembersList[0]);
      setMemberOptions(organizationMembersList);
      // if (!selectedMember || (selectedMember && !selectedMember.id)) {
      //   setSelectedMember(organizationMembersList[0]);
      // }
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  // useEffect(() => {
  //   if (lastAttendanceTimestamp && selectedMember && selectedMember.id) {
  //     const payload = {
  //       start: moment(selectedDate).startOf('day').unix(),
  //       end: lastAttendanceTimestamp,
  //       employee: selectedMember.id,
  //     };
  //     getRouteMapLocations(payload);
  //   }
  // }, [lastAttendanceTimestamp]);

  const selectOffice = e => {
    // call api
    // const payload = {
    //   start: moment(selectedDate).startOf("day").unix(),
    //   end: moment(selectedDate).endOf("day").unix(),
    // };

    if (e && e.value) {
      getOrganizationMembersList({ office_id: e.id });
    }
    // if (selectedMember && selectedMember.id) {
    //   payload.employee = selectedMember.id;
    //   getRouteMapLocations(payload);
    // }
    setSelectedMember(null);

    setSelectedOffice(e);
  };

  const selectMember = member => {
    const payload = {
      start: moment(selectedDate).startOf('day').unix(),
      end: moment(selectedDate).endOf('day').unix(),
      employee: member.id,
    };
    // getRouteMapLocations(payload);
    getSingleEmployeeClockInOutList(payload);
    getTaskCheckInOutList(payload);

    setSelectedMember(member);
  };
  const clearSelectedMember = () => {
    // if (selectedOrganization && selectedOrganization.id) {
    //   const payload = {
    //     organization_id: selectedOrganization.id,
    //     date: moment(selectedDate).format("YYYY-MM-DD"),
    //   };
    //   if (selectedProject && selectedProject.value) {
    //     payload.project_id = selectedProject.value;
    //   }
    //   getRouteMapLocations(payload);
    // }
    setSelectedMember(null);
  };

  const selectDate = date => {
    const payload = {
      start: moment(date).startOf('day').unix(),
      end: moment(date).endOf('day').unix(),
    };
    if (selectedMember && selectedMember.id) {
      payload.employee = selectedMember.id;
    }
    // getRouteMapLocations(payload);
    getSingleEmployeeClockInOutList(payload);
    getTaskCheckInOutList(payload);

    setSelectedDate(date);
  };

  // const selectToday = () => {
  //   selectDate(moment());
  // };

  return (
    <RouteMapFilterContainer>
      {localStorage.getItem('user_role') === 'admin' && (
        <InputWithLabelSection>
          <FilterLabel>{t('office')}</FilterLabel>
          {/* <ProjectDropdown
            selectedOrganization={selectedOrganization}
            selectedProject={selectedProject}
            selectProject={selectProject}
            selectedMember={selectedMember}
            clearable
            style={FilterDropDownStyle({ height: "40px" })}
          /> */}
          <Dropdown
            isSearchable
            value={selectedOffice}
            placeholder="Select office..."
            onChange={e => selectOffice(e)}
            options={officeOptions}
            styles={FilterDropDownStyle({ height: '40px' })}
            filterOption={filterOption}
          />
        </InputWithLabelSection>
      )}

      <InputWithLabelSection>
        <FilterLabel>{t('date')}</FilterLabel>

        <DatePicker
          id="routeMapFilterDate"
          date={selectedDate}
          onDateChange={selectDate}
          dateDisplayFormat={'DD/MM/YYYY'}
          placeholder={'Select date...'}
          allowPreviousDates
        />
      </InputWithLabelSection>
      <InputWithLabelSection>
        <FilterLabel>{t('assignee')}</FilterLabel>
        <div style={{ width: `40px` }}>
          <AddSingleMemberDropdown
            assigneeManage={selectedMember}
            updateState={selectMember}
            membersList={memberOptions}
            clearAssignee={() => clearSelectedMember()}
          />
        </div>
      </InputWithLabelSection>
      {/* <InputWithLabelSection>
        <div style={{ height: "21px" }} />
        <SecondaryButton onClick={() => selectToday()}>Today</SecondaryButton>
      </InputWithLabelSection> */}
    </RouteMapFilterContainer>
  );
};

export default RouteMapFilter;
