import PasswordStrengthChecker from 'components/PasswordStrengthChecker/PasswordStrengthChecker';
import { GreenLabel } from 'custom_modules/ChangePassword/changePasswordStyle';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ShowPasswordIcon } from 'styledComponents/authLayout';
import { ColoredButtonWithIcon, WhiteButton } from 'styledComponents/buttons';
import {
  CommonFlex,
  CommonText,
  InputField,
  InputLabel,
  InputWithLabelSection,
  RedStar,
} from 'styledComponents/common';
import { password_validate } from 'utils/helper';

import hidePassword from '../../assets/img/common/password_hide.svg';
import viewPassword from '../../assets/img/common/password_show.svg';
import LockIcon from '../../assets/img/icons/lockIcon.svg';
import {
  ResetPasswordComponentContainer,
  ResetPasswordWrpper,
} from './loginStyles';

const NewUserLoginModal = ({
  isOpen,
  toggle,
  action,
  actionFunction,
  firstLoginPasswordChangeRequest,
  isLoading,
  auth,
  history,
}) => {
  console.log('🚀 ~ auth:', auth);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [saveErrors, setSaveErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const onPasswordChange = e => {
    let errors = { ...saveErrors };
    delete errors['password'];
    setSaveErrors(errors);
    setPassword(e.target.value);
  };
  const onConfirmPasswordChange = e => {
    let errors = { ...saveErrors };
    delete errors['confirmPassword'];
    setSaveErrors(errors);
    setConfirmPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!password) {
      formIsValid = false;
      saveErrors['password'] = 'Password Required';
    }

    setSaveErrors(saveErrors);
    return formIsValid;
  };
  useEffect(() => {
    if (confirmPassword === password && password !== '') {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }, [password, confirmPassword]);
  const handleFirstLoginPasswordChangeRequest = () => {
    if (checkError()) {
      let payload = {
        password,
      };
      firstLoginPasswordChangeRequest(payload);
    }
  };
  useEffect(() => {
    if (auth?.auth?.last_visited) {
      auth?.auth?.role === 'employee' || auth?.auth?.role === 'supervisor'
        ? history.push('/user/tasks')
        : history.push('/user/dashboard');
    }
    !auth?.auth && history.push('/auth/login');
    setSaveErrors(saveErrors);
    if (auth.redirect && auth?.auth?.last_visited) {
      auth?.auth?.role === 'employee' || auth?.auth?.role === 'supervisor'
        ? history.push('/user/tasks')
        : history.push('/user/dashboard');
    }
  }, [auth, history, auth.redirect]);

  return (
    <ResetPasswordComponentContainer>
      {/* <Modal
        isOpen={isOpen}
        toggle={toggle}
        style={{ height: `90vh` }}
        fullscreen={true}
        size="xl"
      > */}
      {/* <ModalBody style={{ padding: `20px`, height: `80vh` }}> */}
      <ResetPasswordWrpper>
        <CommonFlex direction="column">
          <img
            src={LockIcon}
            alt=""
            style={{
              height: '56px',
              width: '56px',
            }}
          />
          <CommonText
            color="#000000"
            fontWeight="600"
            fontSize="32px"
            style={{ paddingLeft: `15px` }}
          >
            {t('set_new_password')}
          </CommonText>
          <CommonText color="#000000" style={{ paddingLeft: `15px` }}>
            {t('must_be_different_to_previous_passwords')}
          </CommonText>
        </CommonFlex>
        <CommonFlex direction="column" gap="10px">
          <InputWithLabelSection
            style={{ position: `relative`, width: '100%' }}
          >
            <InputLabel fontWeight="500" fontSize="18px" color="#000000">
              {t('new_password')} <RedStar>*</RedStar>
            </InputLabel>
            <InputField
              type={showPassword ? 'text' : 'password'}
              placeholder={t('type_password')}
              borderColor="#000000"
              value={password}
              onChange={e => onPasswordChange(e)}
              style={
                saveErrors &&
                saveErrors[`password`] && {
                  border: `1px solid #fe5969`,
                  backgroundColor: `rgba(252, 87, 104, 0.05)`,
                }
              }
            />
            {saveErrors && saveErrors['password'] && (
              <FormAlert>{saveErrors['password']}</FormAlert>
            )}
            <ShowPasswordIcon
              style={{ top: `40px` }}
              src={showPassword ? hidePassword : viewPassword}
              alt=""
              onClick={e => toggleShowPassword(e)}
            />
            {password && password.length > 0 && (
              <PasswordStrengthChecker password={password} />
            )}
          </InputWithLabelSection>

          <InputWithLabelSection
            style={{ position: `relative`, width: '100%' }}
          >
            <InputLabel fontWeight="500" fontSize="18px" color="#000000">
              {t('confirm_password')} <RedStar>*</RedStar>
            </InputLabel>
            <InputField
              type={showPassword ? 'text' : 'password'}
              borderColor="#000000"
              placeholder={t('type_password_again')}
              value={confirmPassword}
              onChange={e => onConfirmPasswordChange(e)}
              style={
                saveErrors &&
                saveErrors[`confirmPassword`] && {
                  border: `1px solid #fe5969`,
                  backgroundColor: `rgba(252, 87, 104, 0.05)`,
                }
              }
            />
            {saveErrors && saveErrors['confirmPassword'] && (
              <FormAlert>{saveErrors['confirmPassword']}</FormAlert>
            )}
            <ShowPasswordIcon
              style={{ top: `40px` }}
              src={showPassword ? hidePassword : viewPassword}
              alt=""
              onClick={e => toggleShowPassword(e)}
            />
            {passwordMatch && <GreenLabel>{t('passwords_match')}!</GreenLabel>}
          </InputWithLabelSection>
        </CommonFlex>
        <ColoredButtonWithIcon
          width="100%"
          bgColor="#000000"
          padding={auth?.isLoading ? '0 15px' : '0 30px'}
          // type="submit"
          cursor={
            !password_validate(password) ||
            !password ||
            !confirmPassword ||
            !passwordMatch
              ? 'not-allowed'
              : 'pointer'
          }
          disabled={
            !password_validate(password) ||
            !password ||
            !confirmPassword ||
            !passwordMatch
          }
          isDisabled={
            !password_validate(password) ||
            !password ||
            !confirmPassword ||
            !passwordMatch
          }
          onClick={() => {
            if (
              !(
                !password_validate(password) ||
                !password ||
                !confirmPassword ||
                !passwordMatch
              )
            ) {
              console.log('ok');
              handleFirstLoginPasswordChangeRequest();
            }
          }}
        >
          {t('reset_password')}
        </ColoredButtonWithIcon>
      </ResetPasswordWrpper>
      {/* </ModalBody> */}
      {/* </Modal> */}
    </ResetPasswordComponentContainer>
  );
};

export default NewUserLoginModal;
