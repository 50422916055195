import RightSlidingPanel from 'components/RightSlidingPanel/RightSlidingPanel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar5 from '../../../assets/img/avatar_5.svg';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent';
import {
  CommonFlex,
  CommonText,
  Container,
  TableText,
} from '../../../styledComponents/common';
import {
  UserAddRequestTableContainer,
  UserAddRequestTableHeadContainer,
} from '../../../styledComponents/members';
import { TableItem } from '../../../styledComponents/teams';
import { CommImageNameComp } from '../CommonComponents';
import { AddUserRequestButton } from '../UpdateUserStyles';
import UserRequestApproveRejectModal from './UserRequestApproveRejectModal';
import CreateUserRequestForm from './createUserRequestForm';

const UsersTable = ({
  officesShortList,
  createdUserId,
  createRequestLoading,
  membersList,
  isLoading,
  createUserRequest,
  rejectRequestLoading,
  rejectUserRequest,
  rejectedUserId,
}) => {
  const { t } = useTranslation();
  const [selectedMember, setSelectedMember] = useState(null);

  const [role, setRole] = useState('');
  const [isUserApprovalModalOpen, setIsUserApprovalModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);

  const handleApproval = action => {
    setCurrentAction(action);
    setIsUserApprovalModalOpen(!isUserApprovalModalOpen);
  };

  const handleApprovalFunction = action => {
    if (currentAction === 'approve') {
      handleCreateUserRequest();
    }
  };

  const handleRejectUserRequest = user => {
    let payload = {};
    payload.user_id = user.id;
    rejectUserRequest(payload);
  };

  const handleCreateUserRequest = user => {
    console.log('🚀 ~ handleCreateUserRequest ~ user:', user);

    let payload = {
      first_name: user.first_name,
      last_name: user.last_name,
      office: user.office.id,
      email: user.email,
      role: user.role,
      user_code: user.user_code,
      phone: user.phone,
    };
    payload.user_id = user.id;

    createUserRequest(payload);
  };

  const toggleUserApprovalModal = () => {
    setIsUserApprovalModalOpen(!isUserApprovalModalOpen);
    currentAction && setCurrentAction(null);
  };

  const closeRightSlidingPanel = () => {
    setSelectedMember(null);
  };

  useEffect(() => {
    setRole(localStorage.getItem('user_role'));
  }, []);
  return (
    <Container style={{ paddingBottom: `20px` }}>
      <RightSlidingPanel
        isOpen={selectedMember}
        closePanel={() => closeRightSlidingPanel()}
        width="500px"
      >
        <CreateUserRequestForm
          userDetails={selectedMember}
          officesShortList={officesShortList}
          createdUserId={createdUserId}
          rejectedUserId={rejectedUserId}
          closePanel={() => closeRightSlidingPanel()}
          createRequestLoading={createRequestLoading}
          rejectRequestLoading={rejectRequestLoading}
          createUserRequest={createUserRequest}
          handleRejectUserRequest={handleRejectUserRequest}
        />
      </RightSlidingPanel>
      {/* <UserRequestApproveRejectModal
        isOpen={isUserApprovalModalOpen}
        toggle={toggleUserApprovalModal}
        action={currentAction}
        actionFunction={() => {
          handleApprovalFunction();
        }}
        isLoading={isLoading}
      /> */}
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <UserAddRequestTableHeadContainer
            style={{ borderBottom: `1px solid #C2CCE1` }}
          >
            <TableItem>
              <TableText fontSize={'13px'}>{t('member_name')}</TableText>
            </TableItem>
            <TableItem>
              <TableText fontSize={'13px'}>{t('user_id')}</TableText>
            </TableItem>
            <TableItem>
              <TableText fontSize={'13px'}>{t('email')}</TableText>
            </TableItem>
            <TableItem>
              <TableText fontSize={'13px'}>{t('role')}</TableText>
            </TableItem>
            <TableItem>
              <TableText fontSize={'13px'}>{t('contact_number')}</TableText>
            </TableItem>
            <TableItem>
              <TableText fontSize={'13px'}>{t('office')}</TableText>
            </TableItem>
          </UserAddRequestTableHeadContainer>
          {membersList && membersList.length > 0 ? (
            membersList.map((member, index) => (
              <UserAddRequestTableContainer key={index}>
                <TableItem>
                  <div onClick={() => setSelectedMember(member)}>
                    <CommImageNameComp
                      imgSource={member.avatar ? member.avatar : Avatar5}
                      name={`${member.first_name} ${member.last_name}`}
                      size="36px"
                    />
                  </div>
                </TableItem>
                <TableItem>
                  <CommonText fontSize="13px">
                    {member.user_code || `-`}
                  </CommonText>
                </TableItem>
                <TableItem>
                  <CommonText fontSize="13px">{member.email || `-`}</CommonText>
                </TableItem>
                <TableItem>
                  <CommonText fontSize="13px">
                    {member.role ? member.role : `-`}
                  </CommonText>
                </TableItem>
                <TableItem>
                  <CommonText fontSize="13px">
                    {member.phone ? member.phone : `-`}
                  </CommonText>
                </TableItem>
                <TableItem>
                  <CommonText fontSize="13px">
                    {member.office && member.office.id
                      ? member.office.name
                      : `-`}
                  </CommonText>
                </TableItem>
                {role === 'admin' && !localStorage.getItem('viewer_role') && (
                  <TableItem>
                    <CommonFlex>
                      <AddUserRequestButton
                        type="details"
                        onClick={() => setSelectedMember(member)}
                      >
                        {t('see_details')}
                      </AddUserRequestButton>
                      <AddUserRequestButton
                        type="reject"
                        onClick={() => {
                          handleApproval('reject');
                          handleRejectUserRequest(member);
                        }}
                      >
                        {t('reject')}
                      </AddUserRequestButton>{' '}
                      <AddUserRequestButton
                        type="approve"
                        onClick={() => {
                          handleApproval('approve');
                          handleCreateUserRequest(member);
                        }}
                      >
                        {t('approve')}
                      </AddUserRequestButton>
                    </CommonFlex>
                  </TableItem>
                )}
              </UserAddRequestTableContainer>
            ))
          ) : (
            <NoDataComponent
              title={t('no_members_found')}
              padding="5vh 0"
              imageHeight="140px"
            />
          )}
        </>
      )}
    </Container>
  );
};

export default UsersTable;
