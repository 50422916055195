import {
  CardTitleAndSubtitleContainer,
  WeeklyActivityTitleContainer,
} from 'components/DashboardCards/cardStyles';
import TaskSummaryCard from 'components/DashboardCards/taskSummaryCard';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DashboardCardTitle } from 'styledComponents/dashboard';

import EmployeeActivityCard from '../../components/DashboardCards/EmployeeActivityCard';
import LowPerformanceCard from '../../components/DashboardCards/LowPerformanceCard';
import TaskProgressOverview from '../../components/DashboardCards/TaskProgressOverview';
import TopPerformanceCard from '../../components/DashboardCards/TopPerformanceCard';
import TopUsersActivityCard from '../../components/DashboardCards/TopUsersActivityCard';
import TopUsersTimeCard from '../../components/DashboardCards/TopUsersTimeCard';
import TotalCompletedTask from '../../components/DashboardCards/TotalCompletedTask';
import TotalIncompleteTask from '../../components/DashboardCards/TotalIncompleteTask';
import TotalLateTask from '../../components/DashboardCards/TotalLateTask';
import TotalMissedTask from '../../components/DashboardCards/TotalMissedTask';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import { ColoredText, PageTitle } from '../../styledComponents/common';
import {
  BillingTooltip,
  BillingTooltipText,
} from '../../styledComponents/createProject';
import {
  DashboardCardContainer,
  DashboardCardsContainer,
  DashboardPageContent,
  DashboardPageHeader,
  DashboardPerformanceReportContainer,
  DateRangeContainer,
  DateRangeTooltip,
  SmallCardContainer,
  TitleAndModeSelectorDiv,
} from '../Dashboard/dashboardStyles';
import DashboardModeSelector from './DashboardModeSelector';

const getTime = time => {
  let timeInSeconds = parseInt(time);
  let minutes = Math.floor(timeInSeconds / 60) % 60;
  let hours = Math.floor(timeInSeconds / 3600);

  return `${hours}h ${minutes}m`;
};

const DateRangeSection = ({ startDate, endDate, year }) => {
  return (
    <DateRangeContainer>
      <ColoredText type="blue" fontSize="12px">
        {startDate} - {endDate}, {year}
      </ColoredText>
      <DateRangeTooltip>
        ?
        <BillingTooltip top="32px" left="-230px">
          <BillingTooltipText>
            This is the Organization’s weekly data that starts every Monday and
            ends next Sunday.
          </BillingTooltipText>
        </BillingTooltip>
      </DateRangeTooltip>
    </DateRangeContainer>
  );
};
// Please note that today's data is not included here - this text has been removed

const Dashboard = props => {
  // const dashboardData = {
  //   offline_employees: 1220,
  //   online_employees: 1350,
  //   task_progress_overview: [
  //     {
  //       office: 'Albait',
  //       complete: 120,
  //       partially_complete: 45,
  //       missed: 7,
  //     },
  //     {
  //       office: 'Albait Guest',
  //       complete: 34,
  //       partially_complete: 2,
  //       missed: 0,
  //     },
  //   ],

  //   performance_comparison: {
  //     today: {
  //       complete: 34,
  //       partially_complete: 1,
  //       missed: 1,
  //       late: 8,
  //     },
  //     yesterday: {
  //       complete: 27,
  //       partially_complete: 10,
  //       missed: 4,
  //       late: 2,
  //     },
  //   },
  //   late_count_comparison: {
  //     today: 1396,
  //     yesterday: 1396,
  //   },
  // };

  const {
    location,
    history,
    getDashboardStatus,
    getDashboardTaskProgress,
    getDashboardPerformance,
    getDashboardLateCount,
    getDashboardTopLowPerformers,
    getDashboardUserAnalytics,
    // dashboardData,
    employeeStatusIsloading,
    employee_status,
    taskProgressIsLoading,
    task_progress_overview,
    performanceComparisonIsLoading,
    performance_comparison,
    lateCountIsloading,
    late_count_comparison,
    topLowPerformersIsLoading,
    top_low_performers,
    task_Summary,
    taskSummaryIsLoading,
    getDashboardTaskSummary,
  } = props;

  const userRole = localStorage.getItem('user_role');
  useEffect(() => {
    if (userRole === 'admin' || userRole === 'gm' || userRole === 'agm') {
      const payload = {
        date: moment(new Date()),
      };
      getDashboardStatus();
      // getDashboardLateCount();
      // getDashboardTopLowPerformers();
      getDashboardPerformance();
    }
    getDashboardTaskProgress();
    getDashboardTaskSummary();
  }, []);

  // useEffect(() => {

  // }, [employee_status])

  // useEffect(() => {
  // }, [task_progress_overview]);

  // useEffect(() => {
  //   first

  //   return () => {
  //     second
  //   }
  // }, [performance_comparison])

  // useEffect(() => {
  //   first

  //   return () => {
  //     second
  //   }
  // }, [late_count_comparison])

  // useEffect(() => {
  //   first

  //   return () => {
  //     second
  //   }
  // }, [top_low_performers])

  return (
    <div className="content">
      <DashboardPageHeader>
        <TitleAndModeSelectorDiv>
          <PageTitle style={{ marginBottom: '0' }}>{t('Dashboard')}</PageTitle>
        </TitleAndModeSelectorDiv>
      </DashboardPageHeader>
      <DashboardPageContent marginTop="20px">
        {/* <DashboardPerformanceReportContainer
          marginTop={'20px'}
          padding={'15px 20px 20px'}
          laptopColumns={'1fr'}
          gutter={'2%'}
        >
          <TopPerformanceCard />
          <LowPerformanceCard />
        </DashboardPerformanceReportContainer> */}
        {/* <DashboardCardContainer
          columns="1fr 1fr 1fr 1fr"
          tabletColumns="1fr 1fr"
          mobileColumns="1fr"
          marginTop="20px"
        >
          {performanceComparisonIsLoading ? (
            <>
              <SmallCardContainer>
                <ComponentCircleLoader padding="5vh" />
              </SmallCardContainer>
              <SmallCardContainer>
                <ComponentCircleLoader padding="5vh" />
              </SmallCardContainer>
              <SmallCardContainer>
                <ComponentCircleLoader padding="5vh" />
              </SmallCardContainer>
            </>
          ) : (
            performance_comparison &&
            Object.keys(performance_comparison).length > 0 &&
            performance_comparison.today &&
            performance_comparison.yesterday && (
              <>
                {performance_comparison.today.completed !== undefined &&
                performance_comparison.yesterday.completed !== undefined ? (
                  <TotalCompletedTask
                    completed_today={performance_comparison.today.completed}
                    completed_yesterday={
                      performance_comparison.yesterday.completed
                    }
                  />
                ) : null}
                {performance_comparison.today.partially_complete !==
                  undefined &&
                performance_comparison.yesterday.partially_complete !==
                  undefined ? (
                  <TotalIncompleteTask
                    incomplete_today={
                      performance_comparison.today.partially_complete
                    }
                    incomplete_yesterday={
                      performance_comparison.yesterday.partially_complete
                    }
                  />
                ) : null}
                {performance_comparison.today.missed !== undefined &&
                performance_comparison.yesterday.missed !== undefined ? (
                  <TotalMissedTask
                    missed_today={performance_comparison.today.missed}
                    missed_yesterday={performance_comparison.yesterday.missed}
                  />
                ) : null}
              </>
            )
          )}

          <TotalLateTask late_today="-" late_yesterday="-" />
        </DashboardCardContainer> */}
        <DashboardCardsContainer
          columns="1fr"
          laptopColumns="1fr"
          tabletColumns="1fr"
          marginTop="20px"
          background="white"
          padding="20px"
          columnGap="0px"
        >
          <WeeklyActivityTitleContainer margin="0 0 10px 0" padding="0">
            <CardTitleAndSubtitleContainer>
              <DashboardCardTitle>
                {t('overall_information')}
              </DashboardCardTitle>
            </CardTitleAndSubtitleContainer>
          </WeeklyActivityTitleContainer>
          <DashboardCardContainer
            columns="1fr 1fr 1fr 1fr"
            laptopColumns="1fr 1fr 1fr"
            tabletColumns="1fr 1fr"
            mobileColumns="1fr"
          >
            {taskSummaryIsLoading || employeeStatusIsloading ? (
              <>
                <SmallCardContainer>
                  <ComponentCircleLoader padding="5vh" />
                </SmallCardContainer>
                <SmallCardContainer>
                  <ComponentCircleLoader padding="5vh" />
                </SmallCardContainer>
                <SmallCardContainer>
                  <ComponentCircleLoader padding="5vh" />
                </SmallCardContainer>
                <SmallCardContainer>
                  <ComponentCircleLoader padding="5vh" />
                </SmallCardContainer>
              </>
            ) : (
              <>
                {employee_status &&
                  userRole !== 'supervisor' &&
                  userRole !== 'employee' && (
                    <>
                      <TaskSummaryCard
                        history={history}
                        title={'total_employees'}
                        value={
                          employee_status.online_employees +
                          employee_status.offline_employees
                        }
                      />
                      <TaskSummaryCard
                        history={history}
                        title={'working_today'}
                        value={employee_status.online_employees}
                      />
                      <TaskSummaryCard
                        history={history}
                        title={'not_working_today'}
                        value={employee_status.offline_employees}
                      />
                    </>
                  )}
                {task_Summary && (
                  <>
                    <TaskSummaryCard
                      history={history}
                      title={'all_tasks'}
                      value={task_Summary.total}
                    />
                    <TaskSummaryCard
                      history={history}
                      title={'to_do'}
                      value={task_Summary.to_do}
                    />
                    <TaskSummaryCard
                      history={history}
                      title={'in_progress'}
                      value={task_Summary.in_progress}
                    />
                    <TaskSummaryCard
                      history={history}
                      title={'partially_complete'}
                      value={task_Summary.partially_complete}
                    />
                    <TaskSummaryCard
                      history={history}
                      title={'totally_missed'}
                      value={task_Summary.totally_missed}
                    />
                    <TaskSummaryCard
                      history={history}
                      title={'complete'}
                      value={task_Summary.complete}
                    />
                    <TaskSummaryCard
                      history={history}
                      title={'pending_approval'}
                      value={task_Summary.pending_approval}
                    />
                    <TaskSummaryCard
                      history={history}
                      title={'approved'}
                      value={task_Summary.approved}
                    />
                    <TaskSummaryCard
                      history={history}
                      title={'rejected'}
                      value={task_Summary.rejected}
                    />
                  </>
                )}
              </>
            )}
          </DashboardCardContainer>
        </DashboardCardsContainer>
        {userRole !== 'supervisor' && userRole !== 'employee' && (
          <DashboardCardContainer
            columns="1fr"
            laptopColumns="1fr"
            tabletColumns="1fr"
            marginTop="20px"
          >
            {/* <EmployeeActivityCard
            employee_status={employee_status}
            isLoading={employeeStatusIsloading}
          /> */}
            <TaskProgressOverview
              task_progress_overview={task_progress_overview}
              isLoading={taskProgressIsLoading}
              history={history}
            />
          </DashboardCardContainer>
        )}
      </DashboardPageContent>
    </div>
  );
};

export default Dashboard;
