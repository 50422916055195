//Effects
import { all, put, takeLatest } from 'redux-saga/effects';

//Constants
import {
  CREATE_DAILY_TIMESHEETS_LIST,
  CREATE_DAILY_TIMESHEETS_LIST_FAILURE,
  CREATE_DAILY_TIMESHEETS_LIST_SUCCESS,
  DELETE_DAILY_TIMESHEETS_LIST,
  DELETE_DAILY_TIMESHEETS_LIST_FAILURE,
  DELETE_DAILY_TIMESHEETS_LIST_SUCCESS,
  GET_DAILY_TIMESHEETS_LIST,
  GET_DAILY_TIMESHEETS_LIST_FAILURE,
  GET_DAILY_TIMESHEETS_LIST_SUCCESS,
  GET_OTHER_TIMESHEETS_LIST,
  GET_OTHER_TIMESHEETS_LIST_FAILURE,
  GET_OTHER_TIMESHEETS_LIST_SUCCESS,
  GET_WEEKLY_TIMESHEET_DETAILS,
  GET_WEEKLY_TIMESHEET_DETAILS_FAILURE,
  GET_WEEKLY_TIMESHEET_DETAILS_SUCCESS,
  UPDATE_DAILY_TIMESHEETS_LIST,
  UPDATE_DAILY_TIMESHEETS_LIST_FAILURE,
  UPDATE_DAILY_TIMESHEETS_LIST_SUCCESS,
} from '../../modules/constants';
//Service
import { defaultApi } from '../../utils/axiosApi';

//Handle daily timesheet request
export function* dailyTimesheetRequest({ payload }) {
  const { start_date, end_date, user_id, office_id } = payload;
  try {
    let url = `albait/timesheets/daily/?day=${start_date}${
      user_id ? `&employee=${user_id}` : ``
    }${office_id ? `&office_id=${office_id}` : ``}`;
    const response = yield defaultApi(url, 'GET', payload);
    yield put({
      type: GET_DAILY_TIMESHEETS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DAILY_TIMESHEETS_LIST_FAILURE,
      payload: err,
    });
  }
}

//Handle other timesheets request except daily
export function* otherTimesheetRequest({ payload }) {
  const { start_date, end_date, user_id, office_id } = payload;
  try {
    let url = `albait/timesheets/?from=${start_date}&to=${end_date}${
      user_id ? `&employee=${user_id}` : ``
    }${office_id ? `&office_id=${office_id}` : ``}`;
    let method = 'GET';
    const response = yield defaultApi(url, method, payload);
    yield put({
      type: GET_OTHER_TIMESHEETS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_OTHER_TIMESHEETS_LIST_FAILURE,
      payload: err,
    });
  }
}
export function* weeklyTimesheetDetailsRequest({ payload }) {
  const {
    organization_id,
    start_date,
    end_date,
    project_id,
    task_id,
    user_id,
  } = payload;
  try {
    let url = `${organization_id}/timesheets/weekly-timesheet-details/?start_date=${start_date}&end_date=${end_date}${
      project_id ? `&project_id=${project_id}` : ``
    }${task_id ? `&task_id=${task_id}` : ``}${
      user_id ? `&user_id=${user_id}` : ``
    }`;
    let method = 'GET';
    const response = yield defaultApi(url, method, payload);
    yield put({
      type: GET_WEEKLY_TIMESHEET_DETAILS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_WEEKLY_TIMESHEET_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* addDailyTimesheetRequest({ payload }) {
  const { organization_id, ...details } = payload;
  try {
    let url = `${organization_id}/timesheets/timesheets-manual/`;
    let method = 'POST';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: CREATE_DAILY_TIMESHEETS_LIST_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: CREATE_DAILY_TIMESHEETS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* updateDailyTimesheetRequest({ payload }) {
  try {
    const { id, ...data } = payload;
    let url = `manual_timesheet_update/${id}/`;
    const response = yield defaultApi(url, 'POST', data);
    yield put({
      type: UPDATE_DAILY_TIMESHEETS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_DAILY_TIMESHEETS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* deleteDailyTimesheetRequest({ payload }) {
  try {
    const { organization_id, timesheet_id, fromPage, ...data } = payload;
    let url = `${organization_id}/timesheets/timesheets-manual/${timesheet_id}/delete/`;
    const response = yield defaultApi(url, 'POST', data);
    let duration = null;
    if (data.start && data.end) {
      duration = data.end - data.start;
    }
    yield put({
      type: DELETE_DAILY_TIMESHEETS_LIST_SUCCESS,
      payload: {
        data: response,
        duration,
        fromPage,
        userId: data.user_id,
        date: data.date,
      },
    });
  } catch (err) {
    yield put({
      type: DELETE_DAILY_TIMESHEETS_LIST_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_DAILY_TIMESHEETS_LIST, dailyTimesheetRequest),
    takeLatest(GET_OTHER_TIMESHEETS_LIST, otherTimesheetRequest),
    takeLatest(GET_WEEKLY_TIMESHEET_DETAILS, weeklyTimesheetDetailsRequest),
    takeLatest(CREATE_DAILY_TIMESHEETS_LIST, addDailyTimesheetRequest),
    takeLatest(UPDATE_DAILY_TIMESHEETS_LIST, updateDailyTimesheetRequest),
    takeLatest(DELETE_DAILY_TIMESHEETS_LIST, deleteDailyTimesheetRequest),
  ]);
}
