import { connect } from 'react-redux';

import {
  addProjectAssignee,
  deleteProjectAssignee,
  getProjectShortList,
} from '../../Projects/projectsActions';
import { updateMemberwiseTimesheetApprovalSettings } from '../../Settings/TimesheetSettings/timesheetSettingsActions';
import {
  addTeamMember,
  getTeamShortList,
  removeTeamMember,
} from '../../Teams/teamsActions';
// Actions
import {
  clearMemberDetailsData,
  getMemberDetails,
  removeMember,
  updateMember,
} from '../updateUserActions';
import UserDetails from './UserDetails';

// Store props to map with current state
const mapStateToProps = state => ({
  isLoading: state.userUpdate.isLoading,
  paymentConfigurationLoading: state.userUpdate.paymentConfigurationLoading,
  updateMemberLoading: state.userUpdate.updateMemberLoading,
  deleteMemberLoading: state.userUpdate.deleteMemberLoading,
  teamListIsLoading: state.team.shortListIsLoading,
  teamMemberIsLoading: state.team.teamMemberIsLoading,
  projectListIsLoading: state.project.shortListIsLoading,
  projectAssigneeIsLoading: state.project.projectAssigneeIsLoading,
  selectedOrganization: state.organization.selectedOrganization,
  memberDetails: state.userUpdate.memberDetails,
  teamList: state.team.teamsShortList,
  projectList: state.project.projectShortList,
  state: state,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getMemberDetails: details => dispatch(getMemberDetails({ ...details })),
  clearMemberDetailsData: () => dispatch(clearMemberDetailsData()),
  getTeamShortList: details => dispatch(getTeamShortList({ ...details })),
  addTeam: details => dispatch(addTeamMember({ ...details })),
  getProjectShortList: details => dispatch(getProjectShortList({ ...details })),
  assignProject: details => dispatch(addProjectAssignee({ ...details })),
  updateMember: details => dispatch(updateMember({ ...details })),
  removeMember: details => dispatch(removeMember({ ...details })),

  // settings
  updateMemberwiseTimesheetApprovalSettings: details =>
    dispatch(updateMemberwiseTimesheetApprovalSettings({ ...details })),
  //project
  deleteProjectAssignee: details =>
    dispatch(deleteProjectAssignee({ ...details })),
  // team
  removeTeamMember: details => dispatch(removeTeamMember({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
