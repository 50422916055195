import moment from 'moment/moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../../assets/img/avatar_5.svg';
import phoneIcon from '../../../assets/img/icons/call.svg';
import plusIcon from '../../../assets/img/icons/plus_white.svg';
import tick from '../../../assets/img/icons/tick-white-bold.svg';
import { CardText } from '../../../components/DashboardCards/cardStyles';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent';
import { ListUpperSection } from '../../../styledComponents/ProjectsNew';
import { ColoredButton, WhiteButton } from '../../../styledComponents/buttons';
import {
  CommonImage,
  CommonText,
  Container,
  InputField,
  InputLabel,
  InputWithLabelSection,
  LabelWithTextSection,
} from '../../../styledComponents/common';
import {
  ButtonSection,
  ReviewSection,
} from '../../../styledComponents/taskApproval';
import { toHHMMSS } from '../../../utils/helper';
import {
  CardBlock,
  GreenCircle,
  InfoSectionCard,
  LateTag,
} from '../../FieldServices/RouteMapNew/routeMapStyles';
import { CommImageNameComp } from '../../Members/CommonComponents';
import TaskEvidence from '../TaskDetails/taskEvidence';
import AudioPlayer from './AudioPlayer';
import SearchBox from './SearchBox';
import {
  CallSection,
  EmployeeInfoBlock,
  EvidenceThumbnail,
  LabelTextBlock,
  NoteSection,
  StatusBar,
  TaskInfoSection,
} from './taskApprovalStyle';

const ApprovalTasksList = props => {
  const { t, i18n } = useTranslation();

  const [supervisorNote, setSupervisorNote] = React.useState('');

  const {
    handleClearSearch,
    handleSearchByName,
    onSearchTermChange,
    searchTerm,
    isLoading,
    tasksList,
    popupOpen,
    setPopupOpen,
    setEvidenceImage,
    updateTaskStatusRequest,
    selectedTab,
  } = props;

  const onSupervisorNoteChange = e => {
    const { value } = e.target;
    setSupervisorNote(value);
  };

  const popupToggle = image => {
    setEvidenceImage(image);
    setPopupOpen(!popupOpen);
  };

  return (
    <>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : tasksList && tasksList.length > 0 ? (
        tasksList.map(task => (
          <Container padding="30px" gap="20px" key={task.id}>
            <ListUpperSection style={{ gridTemplateColumns: `1fr auto` }}>
              <CommonText name title fontSize="16px">
                {task.name}
              </CommonText>
            </ListUpperSection>
            <TaskInfoSection>
              {task.status === 'complete' ||
              task.status === 'partially complete' ? (
                <StatusBar
                  status={
                    task.status === 'complete'
                      ? 'success'
                      : task.status === 'partially complete'
                      ? 'warning'
                      : 'error'
                  }
                >
                  {task.status === 'complete'
                    ? t('complete')
                    : task.status === 'partially complete'
                    ? t('partially_complete')
                    : '-'}
                </StatusBar>
              ) : null}

              <LabelTextBlock>
                <InputLabel>{t('task_code')}</InputLabel>
                <CommonText name>{task.task_code}</CommonText>
              </LabelTextBlock>
              <LabelTextBlock>
                <InputLabel>{t('office')}</InputLabel>
                <CommonText name>{task?.office && task.office.name}</CommonText>
              </LabelTextBlock>
              {task.task_type && task.task_type.id && (
                <LabelTextBlock>
                  <InputLabel>{t('task_type')}</InputLabel>
                  <CommonText name>
                    {i18n.language && i18n.language === 'en'
                      ? task.task_type.name
                      : task.task_type.arabic_name}
                  </CommonText>
                </LabelTextBlock>
              )}
            </TaskInfoSection>
            <CommonText>{task.description}</CommonText>
            <EmployeeInfoBlock>
              <CommImageNameComp
                imgSource={
                  (task.assignees.length > 0 && task.assignees[0].avatar) ||
                  avatar5
                }
                name={
                  task.assignees.length > 0 &&
                  `${task.assignees[0].first_name} ${task.assignees[0].last_name}`.trim()
                }
                size="50px"
              />
              <CallSection>
                <img src={plusIcon} alt="" width="100%" />
                <CommonImage src={phoneIcon} alt="" size="18px" />
                <CommonText name style={{ whiteSpace: `nowrap` }}>
                  {task.assignees.length > 0 && task.assignees[0].phone}
                </CommonText>
              </CallSection>
            </EmployeeInfoBlock>

            <InfoSectionCard style={{ maxWidth: `650px` }}>
              <CardBlock>
                <CardText name fontSize="13px" title nowrap>
                  {task.required_start_date &&
                    moment(task.required_start_date).format('h:mm A')}{' '}
                  -{' '}
                  {task.required_end_date &&
                    moment(task.required_end_date).format('h:mm A')}
                </CardText>
                <CardText fontSize="12px" label title>
                  {t('start_end')}
                </CardText>
              </CardBlock>
              <CardBlock>
                <CardText name fontSize="13px" title nowrap>
                  {task.checkinout &&
                  task.checkinout.length > 0 &&
                  task.checkinout.filter(i => i.event === 'checkin').length > 0
                    ? task.checkinout
                        .filter(i => i.event === 'checkin')
                        .map((item, i) => {
                          if (i === 0) {
                            return (
                              <React.Fragment key={i}>
                                {moment.unix(item.timestamp).format('h:mm a')}
                                {moment
                                  .unix(item.timestamp)
                                  .isAfter(moment(task.required_start_date)) ? (
                                  <LateTag>{t('late')}</LateTag>
                                ) : (
                                  <GreenCircle>
                                    <img src={tick} alt="" width="100%" />
                                  </GreenCircle>
                                )}
                              </React.Fragment>
                            );
                          }
                          return null;
                        })
                    : `-`}
                </CardText>
                <CardText fontSize="12px" label title>
                  {t('checked_in')}
                </CardText>
              </CardBlock>
              <CardBlock>
                <CardText name fontSize="13px" title nowrap>
                  {task.checkinout &&
                  task.checkinout.length > 1 &&
                  task.checkinout.filter(i => i.event === 'checkout').length > 0
                    ? task.checkinout
                        .filter(i => i.event === 'checkout')
                        .map((item, i) => {
                          if (i === 0) {
                            return (
                              <React.Fragment key={i}>
                                {moment.unix(item.timestamp).format('h:mm a')}
                                {moment
                                  .unix(item.timestamp)
                                  .isAfter(moment(task.required_end_date)) ? (
                                  <LateTag>{t('late')}</LateTag>
                                ) : (
                                  <GreenCircle>
                                    <img src={tick} alt="" width="100%" />
                                  </GreenCircle>
                                )}
                              </React.Fragment>
                            );
                          }
                          return null;
                        })
                    : `-`}
                </CardText>
                <CardText fontSize="12px" label title>
                  {t('checked_out')}
                </CardText>
              </CardBlock>
              <CardBlock>
                <CardText name fontSize="13px" title nowrap>
                  {task.checkinout &&
                  task.checkinout.length > 1 &&
                  task.checkinout.filter(i => i.event === 'checkin').length >
                    0 &&
                  task.checkinout.filter(i => i.event === 'checkout').length > 0
                    ? toHHMMSS(
                        task.checkinout.filter(i => i.event === 'checkout')[0]
                          .timestamp -
                          task.checkinout.filter(i => i.event === 'checkin')[0]
                            .timestamp,
                      )
                    : `-`}
                </CardText>
                <CardText fontSize="12px" label title>
                  {t('worked')}
                </CardText>
              </CardBlock>
            </InfoSectionCard>
            <TaskEvidence evidences={task?.taskevidences} />
            {/* <CommonText name title fontSize="16px">
              {t('evidence')}
            </CommonText>
            <EvidenceThumbnail
              onClick={() =>
                popupToggle(
                  task.taskevidences.length > 0 && task.taskevidences[0].image,
                )
              }
            >
              {task.taskevidences.length > 0 && task.taskevidences[0].image ? (
                <img
                  src={task.taskevidences[0].image}
                  alt=""
                  style={{
                    maxWidth: '200px',
                    maxHeight: '250px',
                    borderRadius: 10,
                  }}
                />
              ) : (
                ''
              )}
            </EvidenceThumbnail>
            <EvidenceThumbnail>
              {task.taskevidences.length > 0 && task.taskevidences[0].audio ? (
                <AudioPlayer audio={task.taskevidences[0].audio} />
              ) : (
                ''
              )}
            </EvidenceThumbnail> */}
            {task.employee_note && (
              <LabelWithTextSection>
                <CommonText $label fontSize="13px">
                  {t('employee_note')}
                </CommonText>
                <CommonText name fontSize="15px">
                  {task.employee_note}
                </CommonText>
              </LabelWithTextSection>
            )}
            {task.supervisor_note && (
              <LabelWithTextSection>
                <CommonText $label fontSize="13px">
                  {t('supervisor_note')}
                </CommonText>
                <CommonText name fontSize="15px">
                  {task.supervisor_note}
                </CommonText>
              </LabelWithTextSection>
            )}

            {localStorage.getItem('user_role') !== 'employee' &&
            !localStorage.getItem('viewer_role') &&
            selectedTab === 'submitted' &&
            task.assignees &&
            task.assignees.length > 0 &&
            task.assignees[0].id !== localStorage.getItem('user_id') ? (
              <>
                <NoteSection>
                  <CommonText name title fontSize="16px">
                    {t('supervisor_note')}
                  </CommonText>
                </NoteSection>

                <ReviewSection>
                  <InputField
                    type="text"
                    name="supervisor_note"
                    placeholder={t('type_your_message_here')}
                    value={supervisorNote}
                    onChange={e => onSupervisorNoteChange(e)}
                  />
                  {/* <SearchBox
                    searchTermName=""
                    searchInput={searchTerm}
                    onInputChange={onSearchTermChange}
                    handleSearch={handleSearchByName}
                    handleClearSearch={handleClearSearch}
                  /> */}
                  <ButtonSection>
                    <WhiteButton
                      type="delete"
                      borderColor="red"
                      onClick={() =>
                        updateTaskStatusRequest(
                          task.id,
                          task.approval,
                          'rejected',
                          supervisorNote,
                        )
                      }
                    >
                      {t('reject')}
                    </WhiteButton>
                    <ColoredButton
                      onClick={() =>
                        updateTaskStatusRequest(
                          task.id,
                          task.approval,
                          'approved',
                          supervisorNote,
                        )
                      }
                      type="black"
                      height="40px"
                      width="max-content"
                    >
                      {t('approve')}
                    </ColoredButton>
                  </ButtonSection>
                </ReviewSection>
              </>
            ) : null}
          </Container>
        ))
      ) : (
        <NoDataComponent
          title={t('no_tasks_found')}
          padding="5vh 0"
          imageHeight="140px"
        />
      )}
    </>
  );
};

export default ApprovalTasksList;
