import { useEffect, useState } from "react";
import { t } from "i18next";
import { Modal, ModalBody } from "reactstrap";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import { ColoredButton, WhiteButton } from "../../styledComponents/buttons";
import { CommonText, CardTitle} from "../../styledComponents/common";
import { ModalButtonSection } from "../../styledComponents/members";

const SelfieWarningModal = ({
  isOpen,
  toggle,
  handleSave,
  isLoading,
  member,
}) => {

  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText name margin="20px 0 auto 0">{member?.first_name} {member?.last_name} ({member?.user_code})</CommonText>
        <CardTitle style={{ paddingLeft: `15px`, marginTop: `30px` }}>
          {member?.clockin_selfie
            ? t("disable_selfie_warning")
            : t("enable_selfie_warning")}
        </CardTitle>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            {t("cancel")}
          </WhiteButton>
          <ColoredButton
            type="blue"
            padding={isLoading ? "0 15px" : "0 30px"}
            onClick={() => handleSave(!member?.clockin_selfie)}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText={t("yes")} fontSize="13px" />
            ) : (
              t("yes")
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default SelfieWarningModal;
