import RightSlidingPanel from 'components/RightSlidingPanel/RightSlidingPanel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WhiteButton } from 'styledComponents/buttons';

import Avatar5 from '../../../assets/img/avatar_5.svg';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent';
import {
  CommonFlex,
  CommonText,
  TableText,
} from '../../../styledComponents/common';
import {
  UserDeleteRequestTableContainer,
  UserDeleteRequestTableHeadContainer,
} from '../../../styledComponents/members';
import { TableItem } from '../../../styledComponents/teams';
import { CommImageNameComp } from '../CommonComponents';
import {
  AddUserRequestButton,
  UpdateRequestContainer,
} from '../UpdateUserStyles';
import UserRequestApproveRejectModal from './UserRequestApproveRejectModal';
import UpdateUserRequestSidePanelComponent from './updateUserRequestSidePanelComponent';

const UsersTable = ({
  isLoading,
  membersList,
  handleDeleteRequest,
  deletedUserId,
  isDeleting,
  rejectRequestLoading,
  rejectUserRequest,
  rejectedUserId,
}) => {
  const { t } = useTranslation();
  const [selectedMember, setSelectedMember] = useState(null);

  const [role, setRole] = useState('');
  const [isUserApprovalModalOpen, setIsUserApprovalModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);

  const handleApproval = action => {
    setCurrentAction(action);
    setIsUserApprovalModalOpen(!isUserApprovalModalOpen);
  };
  const handleDeleteRequestClick = () => {
    console.log(
      '🚀 ~ handleDeleteRequestClick ~ selectedMember:',
      selectedMember,
    );
    handleDeleteRequest(selectedMember);
  };

  const handleRejectUserRequest = user => {
    let payload = {};
    payload.user_id = user.id;
    rejectUserRequest(payload);
  };
  const toggle = () => {
    setIsUserApprovalModalOpen(!isUserApprovalModalOpen);
    currentAction && setCurrentAction(null);
  };

  useEffect(() => {
    setRole(localStorage.getItem('user_role'));
  }, []);
  return (
    <UpdateRequestContainer style={{ paddingBottom: `20px` }}>
      <UserRequestApproveRejectModal
        isOpen={isUserApprovalModalOpen}
        toggle={toggle}
        action={currentAction}
        actionFunction={() => handleDeleteRequestClick()}
        isLoading={isLoading}
      />
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <UserDeleteRequestTableHeadContainer
            style={{ borderBottom: `1px solid #C2CCE1` }}
          >
            <TableItem>
              <TableText fontSize={'13px'}>{t('member_name')}</TableText>
            </TableItem>
            <TableItem>
              <TableText fontSize={'13px'}>{t('user_id')}</TableText>
            </TableItem>
            <TableItem>
              <TableText fontSize={'13px'}>{t('email')}</TableText>
            </TableItem>
            <TableItem>
              <TableText fontSize={'13px'}>{t('role')}</TableText>
            </TableItem>
            <TableItem>
              <TableText fontSize={'13px'}>{t('contact_number')}</TableText>
            </TableItem>
            <TableItem>
              <TableText fontSize={'13px'}>{t('office')}</TableText>
            </TableItem>
          </UserDeleteRequestTableHeadContainer>
          {membersList && membersList.length > 0 ? (
            membersList.map((member, index) => (
              <UserDeleteRequestTableContainer key={index}>
                <TableItem>
                  <div
                    onClick={() =>
                      // props.history.push(`/user/user-details?id=${member.id}`)
                      setSelectedMember(member)
                    }
                  >
                    <CommImageNameComp
                      imgSource={member.avatar ? member.avatar : Avatar5}
                      name={`${member.first_name} ${member.last_name}`}
                      size="36px"
                    />
                  </div>
                </TableItem>
                <TableItem>
                  <CommonText fontSize="13px">
                    {member.user_code || `-`}
                  </CommonText>
                </TableItem>
                <TableItem>
                  <CommonText fontSize="13px">{member.email || `-`}</CommonText>
                </TableItem>
                <TableItem>
                  <CommonText fontSize="13px">
                    {member.role ? member.role : `-`}
                  </CommonText>
                </TableItem>
                <TableItem>
                  <CommonText fontSize="13px">
                    {member.phone ? member.phone : `-`}
                  </CommonText>
                </TableItem>
                <TableItem>
                  <CommonText fontSize="13px">
                    {member.office && member.office.id
                      ? member.office.name
                      : `-`}
                  </CommonText>
                </TableItem>
                {role === 'admin' && !localStorage.getItem('viewer_role') && (
                  <TableItem>
                    <CommonFlex>
                      <WhiteButton
                        type="cancel"
                        padding="10px 15px"
                        onClick={() => handleRejectUserRequest(member)}
                      >
                        {t('reject')}
                      </WhiteButton>
                      <AddUserRequestButton
                        type="reject"
                        onClick={() => handleDeleteRequest(member)}
                      >
                        {t('delete')}
                      </AddUserRequestButton>
                    </CommonFlex>
                  </TableItem>
                )}
              </UserDeleteRequestTableContainer>
            ))
          ) : (
            <NoDataComponent
              title={t('no_members_found')}
              padding="5vh 0"
              imageHeight="140px"
            />
          )}
        </>
      )}
    </UpdateRequestContainer>
  );
};

export default UsersTable;
