import {
  DASHBOARD_TOP_LOW_PERFORMERS,
  DASHBOARD_TOP_LOW_PERFORMERS_FAILURE,
  DASHBOARD_TOP_LOW_PERFORMERS_SUCCESS,
  GET_DASHBOARD_LATE_COUNT,
  GET_DASHBOARD_LATE_COUNT_FAILURE,
  GET_DASHBOARD_LATE_COUNT_SUCCESS,
  GET_DASHBOARD_PERFORMANCE,
  GET_DASHBOARD_PERFORMANCE_FAILURE,
  GET_DASHBOARD_PERFORMANCE_SUCCESS, //new apis
  GET_DASHBOARD_STATUS,
  GET_DASHBOARD_STATUS_FAILURE,
  GET_DASHBOARD_STATUS_SUCCESS,
  GET_DASHBOARD_TASK_PROGRESS,
  GET_DASHBOARD_TASK_PROGRESS_FAILURE,
  GET_DASHBOARD_TASK_PROGRESS_SUCCESS,
  GET_DASHBOARD_TASK_SUMMARY,
  GET_DASHBOARD_TASK_SUMMARY_FAILURE,
  GET_DASHBOARD_TASK_SUMMARY_SUCCESS,
  GET_DASHBOARD_USER_ANALYTICS,
  GET_DASHBOARD_USER_ANALYTICS_FAILURE,
  GET_DASHBOARD_USER_ANALYTICS_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
} from '../../modules/constants';

// ------------------------------------
// Action handler methods
// ------------------------------------

//New Apis
export const handleDashboardStatus = (state, action) => {
  return {
    ...state,
    employeeStatusIsloading: true,
  };
};
export const handleDashboardStatusSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      employeeStatusIsloading: false,
      employee_status: data.data,
    };
  }
  return {
    ...state,
    employeeStatusIsloading: false,
  };
};
export const handleDashboardStatusFailure = (state, action) => {
  return {
    ...state,
    employeeStatusIsloading: false,
    employee_status: null,
  };
};

export const handleDashboardTaskProgress = (state, action) => {
  return {
    ...state,
    taskProgressIsLoading: true,
  };
};
export const handleDashboardTaskProgressSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      taskProgressIsLoading: false,
      task_progress_overview: data.data.task_progress_overview,
    };
  }
  return {
    ...state,
    taskProgressIsLoading: false,
  };
};
export const handleDashboardTaskProgressFailure = (state, action) => {
  return {
    ...state,
    taskProgressIsLoading: false,
    task_progress_overview: null,
  };
};

export const handleDashboardTaskSummary = (state, action) => {
  return {
    ...state,
    taskSummaryIsLoading: true,
  };
};
export const handleDashboardTaskSummarySuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      taskSummaryIsLoading: false,
      task_Summary: data.data,
    };
  }
  return {
    ...state,
    taskSummaryIsLoading: false,
  };
};
export const handleDashboardTaskSummaryFailure = (state, action) => {
  return {
    ...state,
    taskSummaryIsLoading: false,
    task_Summary: null,
  };
};

export const handleDashboardTopLowPerformers = (state, action) => {
  return {
    ...state,
    topLowPerformersIsLoading: true,
  };
};
export const handleDashboardTopLowPerformersSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      topLowPerformersIsLoading: false,
      top_low_performers: data.data,
    };
  }
  return {
    ...state,
    topLowPerformersIsLoading: false,
  };
};
export const handleDashboardTopLowPerformersFailure = (state, action) => {
  return {
    ...state,
    topLowPerformersIsLoading: false,
    top_low_performers: null,
  };
};

export const handleDashboardLateCount = (state, action) => {
  return {
    ...state,
    lateCountIsloading: true,
  };
};
export const handleDashboardLateCountSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      lateCountIsloading: false,
      late_count_comparison: data.data,
    };
  }
  return {
    ...state,
    lateCountIsloading: false,
  };
};
export const handleDashboardLateCountFailure = (state, action) => {
  return {
    ...state,
    lateCountIsloading: false,
    late_count_comparison: null,
  };
};

export const handleDashboardPerformance = (state, action) => {
  return {
    ...state,
    performanceComparisonIsLoading: true,
  };
};
export const handleDashboardPerformanceSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      performance_comparison: data.data,
      performanceComparisonIsLoading: false,
    };
  }
  return {
    ...state,
    performanceComparisonIsLoading: false,
  };
};
export const handleDashboardPerformanceFailure = (state, action) => {
  return {
    ...state,
    performanceComparisonIsLoading: false,
    performance_comparison: null,
  };
};

export const handleDashboardUserAnalyticsRequest = (state, action) => {
  return {
    ...state,
    userAnalyticsIsLoading: true,
  };
};
export const handleDashboardUserAnalyticsRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      userAnalytics: data.data,
      userAnalyticsIsLoading: false,
    };
  }
  return {
    ...state,
    userAnalyticsIsLoading: false,
  };
};
export const handleDashboardUserAnalyticsRequestFailure = (state, action) => {
  return {
    ...state,
    userAnalyticsIsLoading: false,
    userAnalytics: null,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  //new apis
  [GET_DASHBOARD_STATUS]: handleDashboardStatus,
  [GET_DASHBOARD_STATUS_SUCCESS]: handleDashboardStatusSuccess,
  [GET_DASHBOARD_STATUS_FAILURE]: handleDashboardStatusFailure,

  [GET_DASHBOARD_TASK_PROGRESS]: handleDashboardTaskProgress,
  [GET_DASHBOARD_TASK_PROGRESS_SUCCESS]: handleDashboardTaskProgressSuccess,
  [GET_DASHBOARD_TASK_PROGRESS_FAILURE]: handleDashboardTaskProgressFailure,

  [GET_DASHBOARD_TASK_SUMMARY]: handleDashboardTaskSummary,
  [GET_DASHBOARD_TASK_SUMMARY_SUCCESS]: handleDashboardTaskSummarySuccess,
  [GET_DASHBOARD_TASK_SUMMARY_FAILURE]: handleDashboardTaskSummaryFailure,

  [DASHBOARD_TOP_LOW_PERFORMERS]: handleDashboardTopLowPerformers,
  [DASHBOARD_TOP_LOW_PERFORMERS_SUCCESS]:
    handleDashboardTopLowPerformersSuccess,
  [DASHBOARD_TOP_LOW_PERFORMERS_FAILURE]:
    handleDashboardTopLowPerformersFailure,

  [GET_DASHBOARD_LATE_COUNT]: handleDashboardLateCount,
  [GET_DASHBOARD_LATE_COUNT_SUCCESS]: handleDashboardLateCountSuccess,
  [GET_DASHBOARD_LATE_COUNT_FAILURE]: handleDashboardLateCountFailure,

  [GET_DASHBOARD_PERFORMANCE]: handleDashboardPerformance,
  [GET_DASHBOARD_PERFORMANCE_SUCCESS]: handleDashboardPerformanceSuccess,
  [GET_DASHBOARD_PERFORMANCE_FAILURE]: handleDashboardPerformanceFailure,

  [GET_DASHBOARD_USER_ANALYTICS]: handleDashboardUserAnalyticsRequest,
  [GET_DASHBOARD_USER_ANALYTICS_SUCCESS]:
    handleDashboardUserAnalyticsRequestSuccess,
  [GET_DASHBOARD_USER_ANALYTICS_FAILURE]:
    handleDashboardUserAnalyticsRequestFailure,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  //New Apis
  employeeStatusIsloading: false,
  employee_status: null,
  taskProgressIsLoading: false,
  task_progress_overview: [],
  performanceComparisonIsLoading: false,
  performance_comparison: [],
  lateCountIsloading: false,
  late_count_comparison: [],
  topLowPerformersIsLoading: false,
  top_low_performers: null,
};

export default function authReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
