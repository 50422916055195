import { connect } from 'react-redux';

import { getOfficesShortList } from '../../Offices/officesActions';
// Actions
import {
  getUserUpdateRequests,
  rejectUserRequest,
  removeMember,
  updateMember,
  updateUserRequest,
} from '../updateUserActions';
import UpdateRequest from './UpdateRequest';

// Store props to map with current state
const mapStateToProps = state => ({
  isLoading: state.userUpdate.isLoading,
  rejectedUserId: state.userUpdate.rejectedUserId,
  rejectRequestLoading: state.userUpdate.rejectRequestLoading,
  updateRequestLoading: state.userUpdate.updateRequestLoading,
  deleteMemberLoading: state.userUpdate.deleteMemberLoading,
  selectedOrganization: state.organization.selectedOrganization,
  userUpdateRequests: state.userUpdate.userUpdateRequests,
  updatedData: state.userUpdate.updatedData,
  invitedMembersList: state.userUpdate.invitedMembersList,
  officesShortList: state.office.officesShortList,
  // pagination
  totalMembersCount: state.userUpdate.totalMembersCount,
  pageSize: state.userUpdate.pageSize,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getUserRequests: details => dispatch(getUserRequests({ ...details })),
  getUserUpdateRequests: details =>
    dispatch(getUserUpdateRequests({ ...details })),
  removeMember: details => dispatch(removeMember({ ...details })),
  getOfficesShortList: details => dispatch(getOfficesShortList({ ...details })),
  rejectUserRequest: details => dispatch(rejectUserRequest({ ...details })),

  updateUserRequest: details => dispatch(updateUserRequest({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(UpdateRequest);
