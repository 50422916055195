import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { WhiteButton } from 'styledComponents/buttons';
import { CommonFlex, CommonText } from 'styledComponents/common';

import { UpdateUserRequestSidePanelComponentContainer } from '../../../styledComponents/members';
import { AddUserRequestButton } from '../UpdateUserStyles';
import UpdateRequestUserData from './updateRequestUserData';

const UpdateUserRequestSidePanelComponent = ({
  selectedMember,
  toggle,
  handleUpdateUser,
  handleRejectMember,
}) => {
  const [newData, setNewData] = useState({});
  const [oldData, setOldData] = useState({});

  useEffect(() => {
    if (selectedMember) {
      const { new_data, old_data } = selectedMember;
      setNewData(new_data);
      setOldData(old_data);
    }
  }, [selectedMember]);

  return (
    <UpdateUserRequestSidePanelComponentContainer>
      <CommonText color="#000000" fontWeight="700" fontSize={'16px'}>
        {t('changelog')}
      </CommonText>
      <UpdateRequestUserData
        title="new_data"
        type="new"
        newData={newData}
        oldData={oldData}
      />
      <UpdateRequestUserData
        title="old_data"
        type="old"
        newData={newData}
        oldData={oldData}
      />
      <CommonFlex margin="auto">
        <WhiteButton
          type="cancel"
          padding="0 30px"
          onClick={() => handleRejectMember()}
        >
          {t('reject')}
        </WhiteButton>
        <AddUserRequestButton
          type="approve"
          width="fit-content"
          margin="auto"
          onClick={() => handleUpdateUser(newData)}
        >
          {t('update_changes')}
        </AddUserRequestButton>
      </CommonFlex>
    </UpdateUserRequestSidePanelComponentContainer>
  );
};

export default UpdateUserRequestSidePanelComponent;
