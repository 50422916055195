import { useState, useEffect } from "react";
import EvidenceModal from "./EvidenceModal";
import {
  PageTitle,
  ContentHeader,
  CommonGrid,
  HeaderContainer,
  CommonText,
  Container,
  ColoredText,
  CommonFlex,
  FormAlert,
  AssignListText,
  InputLabel,
} from "../../../styledComponents/common";
import {
  ColoredButtonWithIcon,
  ButtonIcon,
  PrimaryButton,
  WhiteButton,
  ColoredButton,
} from "../../../styledComponents/buttons";
import {
  CardBlock,
  InfoSectionCard,
  UserActivityContainer,
  TimelineContainer,
  TimelineInfoContainer,
  GreenCircle,
  TimelineBorder,
  TimelineTextSection,
  LateTag,
} from "../../FieldServices/RouteMapNew/routeMapStyles";
import { checkUrl, checkHtml } from "../../../utils/helper";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import { CommImageNameComp } from "../../Members/CommonComponents";
import { CardText } from "../../../components/DashboardCards/cardStyles";
import { ListUpperSection } from "../../../styledComponents/ProjectsNew";
import {
  TaskInfoSection,
  LabelTextBlock,
  StatusBar,
  EmployeeInfoBlock,
  CallSection,
  EvidenceThumbnail,
  NoteSection,
} from "./taskDetailsStyles";

import plusIcon from "../../../assets/img/icons/plus_white.svg";
import tick from "../../../assets/img/icons/tick-white-bold.svg";
import evidence from "../../../assets/img/screenshot_1.svg";

const TaskDetails = (props) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  return (
    <div className="content">
      <EvidenceModal
        isOpen={popupOpen}
        toggle={popupToggle}
        evidence={evidence}
      />
      <PageTitle>Task Details</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => props.history.push("/user/tasks")}
          subTitle="Back to all tasks"
        />
      </ContentHeader>
      <Container padding="30px" gap="20px">
        <ListUpperSection style={{ gridTemplateColumns: `1fr auto` }}>
          <CommonText name title fontSize="16px">
            Task: My Task
          </CommonText>
          <WhiteButton>Edit Task</WhiteButton>
        </ListUpperSection>
        <TaskInfoSection>
          <StatusBar status="warning">Completed</StatusBar>
          <LabelTextBlock>
            <InputLabel>Task Code:</InputLabel>
            <CommonText name>ABG-001</CommonText>
          </LabelTextBlock>
          <LabelTextBlock>
            <InputLabel>Office:</InputLabel>
            <CommonText name>Al Bait Guest</CommonText>
          </LabelTextBlock>
          <LabelTextBlock>
            <InputLabel>Task Type:</InputLabel>
            <CommonText name>Moderate</CommonText>
          </LabelTextBlock>
        </TaskInfoSection>
        <CommonText>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </CommonText>
        <EmployeeInfoBlock>
          <CommImageNameComp
            imgSource={`https://app.apploye.com/static/media/avatar_5.fdbc2821.svg`}
            name={`Saleet al-Rafiq`}
            size="60px"
          />
          <CallSection>
            <img src={plusIcon} alt="" width="100%" />
            <CommonText name style={{ whiteSpace: `nowrap` }}>
              56 427 4456
            </CommonText>
          </CallSection>
        </EmployeeInfoBlock>

        <InfoSectionCard style={{ maxWidth: `530px` }}>
          <CardBlock>
            <CardText name fontSize="13px" title nowrap>
              3 PM - 4 PM
            </CardText>
            <CardText fontSize="12px" label title>
              Start - End
            </CardText>
          </CardBlock>
          <CardBlock>
            <CardText name fontSize="13px" title nowrap>
              3:11 PM
              <LateTag>Late</LateTag>
            </CardText>
            <CardText fontSize="12px" label title>
              Checked In
            </CardText>
          </CardBlock>
          <CardBlock>
            <CardText name fontSize="13px" title nowrap>
              3:59 PM
              <GreenCircle>
                <img src={tick} alt="" width="100%" />
              </GreenCircle>
            </CardText>
            <CardText fontSize="12px" label title>
              Checked Out
            </CardText>
          </CardBlock>
          <CardBlock>
            <CardText name fontSize="13px" title nowrap>
              48 Min
            </CardText>
            <CardText fontSize="12px" label title>
              Worked
            </CardText>
          </CardBlock>
        </InfoSectionCard>
        <div>
          <CommonText name title fontSize="16px">
            Location
          </CommonText>
          <LabelTextBlock style={{ marginTop: `10px` }}>
            <InputLabel>Location Address:</InputLabel>
            <CommonText name>
              CR9F+C9F, Al Hajlah, Mecca 24231, Saudi Arabia
            </CommonText>
          </LabelTextBlock>
          <LabelTextBlock style={{ marginTop: `10px` }}>
            <InputLabel>Radius:</InputLabel>
            <CommonText name>100 Meters</CommonText>
          </LabelTextBlock>
        </div>
        <CommonText name title fontSize="16px">
          Evidence
        </CommonText>
        <EvidenceThumbnail onClick={()=> popupToggle()}>
          <img src={evidence} alt="" width="100%" />
        </EvidenceThumbnail>
        <NoteSection>
          <CommonText name title fontSize="16px">
            Note
          </CommonText>
          <CommonText>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </CommonText>
        </NoteSection>
      </Container>
    </div>
  );
};

export default TaskDetails;
