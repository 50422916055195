import { connect } from "react-redux";

// Component
import TimesheetMonthly from "./TimesheetMonthly";
// Actions
import {
  getOtherTimesheets,
  clearTimesheetList,
} from "../timesheetActions";
import { getOrganizationMembersList } from "../../Projects/projectsActions";
import { getOfficesShortList } from "../../Offices/officesActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.timesheet.isLoading,
  timesheetsList: state.timesheet.list,
  orgMembersList: state.project.orgMembersList,
  officesShortList: state.office.officesShortList,
  profileData: state.profile.profileData,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOtherTimesheets: (payload) => dispatch(getOtherTimesheets(payload)),
  getOfficesShortList: (payload) => dispatch(getOfficesShortList(payload)),
  getOrganizationMembersList: (payload) => dispatch(getOrganizationMembersList(payload)),
  clearTimesheetList: () => dispatch(clearTimesheetList()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TimesheetMonthly);
