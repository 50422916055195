import { Modal, ModalBody } from 'reactstrap';

import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import { ColoredButton, WhiteButton } from '../../styledComponents/buttons';
import { CommonText } from '../../styledComponents/common';
import { ModalButtonSection } from '../../styledComponents/members';
import { t } from 'i18next';

const MemberDeleteModal = ({ isOpen, toggle, handleDelete, isLoading }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `15px` }}>
          Are you sure you want to remove this member from your organization?
          All data related to this member will be deleted.
        </CommonText>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            {t('cancel')}
          </WhiteButton>
          <ColoredButton
            type="delete"
            padding={isLoading ? '0 15px' : '0 30px'}
            onClick={() => handleDelete()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Deleting" fontSize="13px" />
            ) : (
              'Delete'
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default MemberDeleteModal;
