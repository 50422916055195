import { useLoadScript } from '@react-google-maps/api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import crossWhite from '../../assets/img/icons/remove_assignee.svg';
import BackButtonComponent from '../../components/BackButtonComponent/BackButtonComponent';
import AddSingleMemberDropdown from '../../components/CustomDropdown/AddSingleMemberDropdown';
import { CardText } from '../../components/DashboardCards/cardStyles';
import CreateProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import Select from '../../components/ReactSelectDropdown';
import { ClearSearchButton } from '../../components/Search/searchStyles';
import DatePicker from '../../components/SingleDatePicker/SingleDatePicker';
import useAutocompleteService from '../../customHooks/usePlacesAutocompleteService';
import { ListUpperSection } from '../../styledComponents/ProjectsNew';
import {
  ButtonIcon,
  ColoredButton,
  ColoredButtonWithIcon,
  PrimaryButton,
  RadiusButton,
  WhiteButton,
} from '../../styledComponents/buttons';
import {
  AssignListText,
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  Container,
  ContentHeader,
  FormAlert,
  HeaderContainer,
  InputField,
  InputLabel,
  InputWithLabelSection,
  PageTitle,
  RedStar,
} from '../../styledComponents/common';
import { checkHtml, checkUrl } from '../../utils/helper';
import { DataAndMapContainer } from '../FieldServices/JobSites/jobSitesStyles';
import { MapAndAlertContainer } from '../FieldServices/RouteMap/routeMapStyles';
import { CommImageNameComp } from '../Members/CommonComponents';
import TimeRangeComponent from '../Timesheet/Daily/TimeRangeComponent';
import AddOffice from './AddOffice';
import DeleteOfficeModal from './DeleteOfficeModal';
import OfficeDetails from './OfficeDetails';
import OfficeList from './OfficesList';
import OfficesMap from './OfficesMap';
import {
  InfoMapSection,
  InfoSection,
  Tabsection,
  TitleTag,
} from './officesStyles';

const mapLibraries = ['places'];
const mapIds = [process.env.REACT_APP_JOB_SITES_MAP_ID];

const Offices = props => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState('list');
  //search
  const [searchTerm, setSearchTerm] = useState('');
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState('');
  // delete
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteOfficeId, setDeleteOfficeId] = useState(null);

  const [selectedOffice, setSelectedOffice] = useState(null);

  // details states
  const [singleAddressMarker, setSingleAddressMarker] = useState(null);
  const [addressInput, setAddressInput] = useState('');
  const [radiusInput, setRadiusInput] = useState(100);

  // map info
  const [isMapInfoVisible, setIsMapInfoVisible] = useState(true);

  // map states
  const [map, setMap] = useState(null);

  const [userCurrentLocation, setUserCurrentLocation] = useState(null);
  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const { isLoaded, loadError } = useLoadScript({
    id: 'offices-map-script-loader',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY,
    libraries: mapLibraries,
    mapIds,
  });

  const {
    geocoderService,
    getPlacePredictions,
    placePredictions,
    setPlacePredictions,
    isPredictionsLoading,
    refreshSessionToken,
  } = useAutocompleteService({ map, debounceTimeout: 500 });

  const {
    getOrganizationMembersList,
    orgMembersList,
    isLoading,
    getOfficesList,
    officesList,
    officeDetails,
    setOfficeDetails,
    history,
    clearOfficeCreatedUpdated,
  } = props;

  useEffect(() => {
    getOfficesList({
      page: null,
      office_name: null,
    });
  }, []);

  useEffect(() => {
    if (currentPage && currentPage === 'list') {
      clearOfficeCreatedUpdated();
    }
  }, [currentPage]);

  useEffect(() => {
    if (officesList && officesList.length > 0 && currentPage === 'list') {
      // console.log(officesList);
      // setSelectedOffice(officesList[0]);
      if (map) {
        handleFitBounds(officesList);
      }
    }
  }, [officesList, map]);

  const handleFitBounds = list => {
    if (map) {
      if (list.length === 1) {
        const location = {
          lat: parseFloat(list[0].latitude),
          lng: parseFloat(list[0].longitude),
        };
        handleMapPanAndZoomToSingleLocation(location);
      } else {
        const bounds = new window.google.maps.LatLngBounds();
        list.forEach(place => {
          const position = new window.google.maps.LatLng(
            parseFloat(place.latitude),
            parseFloat(place.longitude),
          );
          bounds.extend(position);
        });
        map.fitBounds(bounds, 20);
      }
    }
  };

  const handleGetGeocode = place => {
    if (geocoderService && place && place.place_id && map) {
      setAddressInput(place.description);
      geocoderService
        .geocode({ placeId: place.place_id })
        .then(({ results }) => {
          handleSetAddressMarker(results[0].geometry.location);
        })
        .catch(e => {
          console.log(e);
        });
      setPlacePredictions([]);
    }
    refreshSessionToken();
  };
  const handleReverseGeocode = coord => {
    if (geocoderService && coord) {
      setPlacePredictions([]);
      geocoderService
        .geocode({ location: coord })
        .then(results => {
          if (results.results && results.results.length) {
            setAddressInput(results.results[0].formatted_address);
          } else {
            setAddressInput(`${coord.lat()}, ${coord.lng()}`);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  // const handleSearchByName = () => {
  //   const payload = {
  //     page: null,
  //   };
  //   if (searchTerm && searchTerm.trim()) {
  //     payload.office_name = searchTerm.toLowerCase().trim();
  //     setCurrentSearchedTerm(searchTerm);
  //   } else {
  //     setCurrentSearchedTerm('');
  //   }
  //   getOfficesList(payload);
  //   setCurrentPageNumber(1);
  // };
  // const handleClearSearch = () => {
  //   if (currentSearchedTerm) {
  //     const payload = {
  //       page: null,
  //     };
  //     getOfficesList(payload);
  //     setCurrentPageNumber(1);
  //   }
  //   setTimeout(() => {
  //     setSearchTerm('');
  //     setCurrentSearchedTerm('');
  //   }, 100);
  // };

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleChangePage = (page, officeData) => {
    setCurrentPage(page);
    if (page === 'details') {
      setAddressInput('Mecca Saudi Arabia');
      handleSetAddressMarker({
        lat: parseFloat(21.4240968),
        lng: parseFloat(39.81733639999999),
      });
      if (officeData && officeData.id) {
        setOfficeDetails(officeData);
      } else {
        // setSingleAddressMarker(null);
      }
    } else {
      if (officesList && officesList.length > 0) {
        setSelectedOffice(null);
        if (map) {
          handleFitBounds(officesList);
        }
      }
    }
  };

  const handleSelectOffice = office => {
    if (office && office.id) {
      setSelectedOffice(office);
      if (map) {
        map.panTo({
          lat: parseFloat(office.latitude),
          lng: parseFloat(office.longitude),
        });
        map.setZoom(17);
      }
    }
  };

  const handleSetAddressFromMap = location => {
    if (location && location.latLng && currentPage === 'details') {
      handleSetAddressMarker(location.latLng);
      handleReverseGeocode(location.latLng);
    }
  };
  const handleSetAddressMarker = location => {
    if (location) {
      setSingleAddressMarker(location);

      handleMapPanAndZoomToSingleLocation(location);
    }
  };
  const handleMapPanAndZoomToSingleLocation = location => {
    if (map) {
      map.panTo(location);
      map.setZoom(17);
    }
  };

  const toggleDeleteModal = officeId => {
    // if (!isDeleteModalVisible) {
    //   setDeleteOfficeId(officeId);
    // }
    setIsDeleteModalVisible(!isDeleteModalVisible);
  };

  return (
    <div className="content">
      {/* <DeleteOfficeModal
        isOpen={isDeleteModalVisible}
        toggle={() => setIsDeleteModalVisible(!isDeleteModalVisible)}
        // handleDelete={handleDeleteOffice}
        isLoading={false}
      /> */}

      <PageTitle>{t('offices')}</PageTitle>
      {currentPage === 'details' && (
        <ContentHeader>
          <BackButtonComponent
            onClick={() => handleChangePage('list')}
            subTitle={t('back_to_all_offices')}
          />
        </ContentHeader>
      )}
      <Container padding="0">
        <DataAndMapContainer columns="35% 65%">
          {currentPage === 'list' ? (
            <OfficeList
              handleChangePage={handleChangePage}
              isLoading={isLoading}
              officesList={officesList}
              selectedOffice={selectedOffice}
              selectOffice={handleSelectOffice}
              toggleDeleteModal={toggleDeleteModal}
              searchTerm={searchTerm}
              onSearchTermChange={onSearchTermChange}
              // handleSearchByName={handleSearchByName}
              // handleClearSearch={handleClearSearch}
            />
          ) : currentPage === 'details' ? (
            // <AddOffice />
            <OfficeDetails
              handleChangePage={handleChangePage}
              officeDetails={officeDetails}
              addressMarker={singleAddressMarker}
              setAddressMarker={handleSetAddressMarker}
              addressInput={addressInput}
              setAddressInput={setAddressInput}
              radiusInput={radiusInput}
              setRadiusInput={setRadiusInput}
              // autocomplete service
              getGeocode={handleGetGeocode}
              getPlacePredictions={getPlacePredictions}
              placePredictions={placePredictions}
              isPredictionsLoading={isPredictionsLoading}
              toggleDeleteModal={toggleDeleteModal}
            />
          ) : null}
          {loadError ? (
            <div>Map cannot be loaded right now! Please try again later</div>
          ) : isLoaded ? (
            <MapAndAlertContainer>
              <OfficesMap
                currentPage={currentPage}
                sitesList={officesList}
                activeSite={selectedOffice}
                selectSite={handleSelectOffice}
                singleAddressMarker={singleAddressMarker}
                setAddressFromMap={handleSetAddressFromMap}
                setMap={setMap}
                radius={radiusInput}
              />
              {/* {isMapInfoVisible && currentPage && currentPage === "details" ? (
                <MapBottomInfo>
                  <MapInfoText>
                    You can search or double click on map to add jobsite. Drag
                    the marker to change jobsite location.
                  </MapInfoText>
                  <InfoCrossButton onClick={() => setIsMapInfoVisible(false)}>
                    <CommonImage src={crossWhite} alt="close" size="10px" />
                  </InfoCrossButton>
                </MapBottomInfo>
              ) : null} */}
            </MapAndAlertContainer>
          ) : (
            <ComponentCircleLoader />
          )}
        </DataAndMapContainer>
      </Container>
    </div>
  );
};

export default Offices;
