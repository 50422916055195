import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
  useLoadScript, // useJsApiLoader,
} from '@react-google-maps/api';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import avatar5 from '../../assets/img/avatar_5.svg';
import call from '../../assets/img/icons/call.svg';
import { CommonFlex, CommonText } from '../../styledComponents/common';

const MarkerInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  width: 200px;
  height: max-content;
`;

const ImageAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const PhoneNumberContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const LastSeenContainer = styled.div`
  display: flex;
  align-items: center;
`;

const options = {
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  disableDoubleClickZoom: true,
  gestureHandling: 'cooperative',
  mapId: process.env.REACT_APP_JOB_SITES_MAP_ID,
};

const containerStyle = {
  width: '100%',
  height: '85vh',
  borderLeft: '1px solid #e4e7eb',
  borderRadius: '10px',
};

const center = {
  lat: 21.424090674479555,
  lng: 39.822141261515604,
};

const circleOptions = {
  strokeColor: '#6F7AFF',
  strokeWeight: 2,
  fillColor: '#6F7AFF',
  fillOpacity: 0.2,
};

function Map({ userList, isAwayUsers }) {
  const { t } = useTranslation();

  const [markers, setMarkers] = useState([]);
  const [activeMarkerId, setActiveMarkerId] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    id: 'live-location-map',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY,
    mapIds: [process.env.REACT_APP_JOB_SITES_MAP_ID],
  });

  const [map, setMap] = useState(null);

  useMemo(() => {
    if (map) {
      if (userList && userList.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        userList.forEach(user => {
          bounds.extend(
            new window.google.maps.LatLng(
              parseFloat(user.latest_location.lat),
              parseFloat(user.latest_location.long),
            ),
          );
        });
        map.fitBounds(bounds);
      }
    }
  }, [userList]);

  useEffect(() => {
    // populate markers
    const markers = userList.map(user => {
      const marker = {
        id: user?.employee?.id,
        location: {
          lat: parseFloat(user?.latest_location?.lat),
          lng: parseFloat(user?.latest_location?.long),
        },
        icon: user.avatar ? { url: user.avatar } : avatar5,
        name: `${user?.employee?.first_name} ${user?.employee?.last_name}`,
        phone: user?.employee?.phone,
        office: user?.employee?.office,
        lastSeen: (user?.latest_location?.timestamp ?? 0) * 1000,
      };
      return marker;
    });
    setMarkers(markers);
  }, [userList]);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (loadError) {
    return <div>Map cannot be loaded right now! Please try again later</div>;
  }
  if (!isLoaded) return null;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      options={{
        ...options,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM,
        },
      }}
      center={center}
      zoom={8}
      onLoad={map => setMap(map)}
      onUnmount={onUnmount}
    >
      <MarkerClusterer
        options={{
          averageCenter: true,
          minimumClusterSize: 5,
          imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
          zoomOnClick: true,
          gridSize: 75,
          imageExtension: 'png',
          imageSizes: [53, 56, 66, 78, 90],
        }}
      >
        {clusterer =>
          markers.map((marker, index) => {
            return (
              <Marker
                key={marker.id}
                icon={marker.icon}
                position={marker.location}
                clusterer={clusterer}
                onClick={() => setActiveMarkerId(marker.id)}
              >
                {activeMarkerId !== marker.id ? null : (
                  <InfoWindow
                    position={marker.location}
                    onCloseClick={() => setActiveMarkerId(null)}
                  >
                    <MarkerInfoContainer>
                      <ImageAndNameContainer>
                        <img
                          src={marker.icon.url || avatar5}
                          alt="avatar"
                          style={{
                            width: '30px',
                            height: '30px',
                            marginRight: '10px',
                          }}
                        />
                        <div>{marker.name}</div>
                      </ImageAndNameContainer>
                      {marker.office && (
                        <CommonFlex gap="12px">
                          <CommonText $label>{t('office')}</CommonText>
                          <CommonText name>{marker.office}</CommonText>
                        </CommonFlex>
                      )}

                      <PhoneNumberContainer>
                        <img
                          src={call}
                          alt="call"
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '10px',
                          }}
                        />
                        <div>{marker.phone || 'Not found'}</div>
                      </PhoneNumberContainer>
                      {isAwayUsers && (
                        <LastSeenContainer>
                          <div>Last seen: </div>
                          <div style={{ marginLeft: '5px' }}>
                            {moment(marker.lastSeen).format('Do MMM, HH:mm')}
                          </div>
                        </LastSeenContainer>
                      )}
                    </MarkerInfoContainer>
                  </InfoWindow>
                )}
              </Marker>
            );
          })
        }
      </MarkerClusterer>
      {/* {markers.map(marker => (
        <Marker
          key={marker.id}
          icon={marker.icon}
          position={marker.location}
          onClick={() => setActiveMarkerId(marker.id)}
        >
          {activeMarkerId !== marker.id ? null : (
            <InfoWindow
              position={marker.location}
              onCloseClick={() => setActiveMarkerId(null)}
            >
              <MarkerInfoContainer>
                <ImageAndNameContainer>
                  <img
                    src={marker.icon.url || avatar5}
                    alt="avatar"
                    style={{
                      width: '30px',
                      height: '30px',
                      marginRight: '10px',
                    }}
                  />
                  <div>{marker.name}</div>
                </ImageAndNameContainer>
                <PhoneNumberContainer>
                  <img
                    src={call}
                    alt="call"
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '10px',
                    }}
                  />
                  <div>{marker.phone || 'Not found'}</div>
                </PhoneNumberContainer>
                {isAwayUsers && (
                  <LastSeenContainer>
                    <div>Last seen: </div>
                    <div style={{ marginLeft: '5px' }}>
                      {moment(marker.lastSeen).format('Do MMM, HH:mm')}
                    </div>
                  </LastSeenContainer>
                )}
              </MarkerInfoContainer>
            </InfoWindow>
          )}
        </Marker>
      ))} */}
    </GoogleMap>
  );
}

export default Map;
