import moment from 'moment';
import { Fragment, useEffect, useRef, useState } from 'react';

import deleteWhite from '../../../assets/img/icons/delete_white.svg';
import idleTimeIcon from '../../../assets/img/icons/idle-time.svg';
import Info from '../../../assets/img/icons/info.svg';
import Plus from '../../../assets/img/icons/plus_white.svg';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent';
import CommonTooltip from '../../../components/Tooltip/CommonTooltip';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
  WhiteButton,
} from '../../../styledComponents/buttons';
import {
  CommonGrid,
  CommonText,
  Container,
  PageTitle,
} from '../../../styledComponents/common';
import { ActivityTextSection } from '../../../styledComponents/members';
import {
  CalendarContainer,
  DeleteButton,
  InfoButton,
  TimeSlot,
  TimeSlotCard,
  TimeSlotInnerCard,
  TimeSlotSmallInfo,
  TimeText,
  TimesheetTooltipContainer,
  UpperTotalHour,
} from '../../../styledComponents/timesheet';
import { toHHMMSS } from '../../../utils/helper';
import TimesheetFilter from '../TimesheetFilter';
import DeleteTimeModal from './DeleteTimeModal';
import { IdleTimeDetailsPopup, IdleTimeSlot } from './IdleTimeComponents';
import ManualTimeModal from './ManualTimeModal';
import { getDuration, getDurationInMinutes, getHours } from './helperFunctions';
import { timeList } from './timeList';

const TimesheetDetailsPopup = ({
  index,
  data,
  startPosition,
  getDuration,
  toggleDeleteTimeModal,
  setInfoPopup,
  toggleIdleTimePopup,
}) => {
  const [hover, setHover] = useState(false);
  const popUpRef = useRef(null);

  //outside click function
  useOutsideClickHandler(popUpRef);

  function useOutsideClickHandler(popUpRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (popUpRef.current && !popUpRef.current.contains(event.target)) {
          setInfoPopup(-1);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [popUpRef]);
  }

  const onHoverChange = value => {
    setHover(value);
  };
  return (
    <TimeSlotCard
      index={index}
      top={data.top - 85}
      height={75}
      startPosition={startPosition}
      style={{
        left: '200px',
        zIndex: '5',
      }}
      ref={popUpRef}
    >
      <TimeSlotInnerCard
        onMouseEnter={() => onHoverChange(true)}
        onMouseLeave={() => onHoverChange(false)}
      >
        <ActivityTextSection></ActivityTextSection>
        <ActivityTextSection>
          <CommonText name fontSize="14px" fontWeight="600">
            {data.start_time.format('h:mm A')}
          </CommonText>
          <CommonText fontSize="12px" fontWeight="600">
            Start Time
          </CommonText>
        </ActivityTextSection>
        <ActivityTextSection>
          <CommonText name fontSize="14px" fontWeight="600">
            {data.end_time ? data.end_time.format('h:mm A') : 'not clocked out'}
          </CommonText>
          <CommonText fontSize="12px" fontWeight="600">
            End Time
          </CommonText>
        </ActivityTextSection>
        <ActivityTextSection>
          <CommonText name fontSize="14px" fontWeight="600">
            {data.duration !== null
              ? getDuration(data.duration)
              : `Inaplicable`}
          </CommonText>
          <CommonText fontSize="12px" fontWeight="600">
            Total Time
          </CommonText>
        </ActivityTextSection>
      </TimeSlotInnerCard>
    </TimeSlotCard>
  );
};

const Daily = props => {
  const [selectedDate, setSelectedDate] = useState(moment(new Date()));
  const [selectedOffice, setSelectedOffice] = useState({
    label: 'All Offices',
    value: null,
  });
  const [selectedMember, setSelectedMember] = useState('');
  const [timesheetList, setTimesheetList] = useState([]);
  const [durations, setDurations] = useState([]);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [currentTimesheet, setCurrentTimesheet] = useState(null);
  const [infoPopup, setInfoPopup] = useState(-1);

  const {
    isLoading,
    getDailyTimesheets,
    timesheet,
    getOfficesShortList,
    officesShortList,
    getOrganizationMembersList,
    orgMembersList,
  } = props;

  useEffect(() => {
    const startDate = moment(selectedDate).startOf('day').unix();
    const endDate = moment(selectedDate).endOf('day').unix();
    let payload = {
      start_date: startDate,
      end_date: endDate,
    };
    setSelectedMember('');
    getDailyTimesheets(payload);
  }, []);

  useEffect(() => {
    if (timesheet && timesheet.length > 0) {
      let list = [];
      let timeDurations = [];
      timesheet.map(time => {
        let timeSlot = {};

        let compareTime = moment('00:00', 'HH:mm');

        let startTime = moment(time.start_timestamp).format('HH:mm');
        let slotDuration = null;
        if (time.end_timestamp) {
          slotDuration = moment(time.end_timestamp).diff(
            moment(time.start_timestamp),
            'seconds',
          );
        }

        timeSlot.id = time.user_id;
        timeSlot.start_time = moment(time.start_timestamp);
        timeSlot.end_time = time.end_timestamp
          ? moment(time.end_timestamp)
          : null;
        timeSlot.duration = slotDuration;

        let startTimeDuration = moment.utc(
          moment(startTime, 'HH:mm').diff(moment(compareTime)),
        );
        let startTimeHour = parseInt(
          moment.duration(startTimeDuration).asHours(),
        );
        let startTimeMinutes = parseInt(
          moment.duration(startTimeDuration).asMinutes() % 60,
        );

        timeSlot.top = parseInt((startTimeHour + startTimeMinutes / 60) * 75);

        let hours = '';
        let minutes = '';
        if (slotDuration) {
          hours = parseInt(toHHMMSS(slotDuration).split(':')[0]);
          minutes = parseInt(toHHMMSS(slotDuration).split(':')[1]);
          if (parseInt((hours + minutes / 60) * 75) === 0) {
            timeSlot.height = 1;
          } else {
            timeSlot.height = parseInt((hours + minutes / 60) * 75);
          }
        }
        list.push(timeSlot);
      });
      setTimesheetList(list);
      setDurations(timeDurations);
    } else if (timesheet && timesheet.length === 0) {
      setTimesheetList([]);
      setDurations([]);
    }
  }, [timesheet]);

  const selectOffice = e => {
    const payload = {
      start_date: moment(selectedDate).startOf('day').unix(),
      end_date: moment(selectedDate).endOf('day').unix(),
    };
    if (e && e.value) {
      payload.office_id = e.value;
    }
    setSelectedMember('');
    getDailyTimesheets(payload);
    setSelectedOffice(e);
  };

  const selectMember = person => {
    if (person.id) {
      const payload = {
        start_date: moment(selectedDate).startOf('day').unix(),
        end_date: moment(selectedDate).endOf('day').unix(),
        user_id: person.id,
      };
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }
      getDailyTimesheets(payload);
      setSelectedMember(person);
    }
  };

  const clearMember = () => {
    const payload = {
      start_date: moment(selectedDate).startOf('day').unix(),
      end_date: moment(selectedDate).endOf('day').unix(),
    };
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    getDailyTimesheets(payload);
    setSelectedMember('');
  };

  const handleDateChange = date => {
    if (date) {
      const payload = {
        start_date: moment(date).startOf('day').unix(),
        end_date: moment(date).endOf('day').unix(),
      };
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      getDailyTimesheets(payload);
    }
    setSelectedDate(date);
  };

  const toggleInfoPopup = index => {
    setInfoPopup(index);
  };

  return (
    <div className="content">
      <CommonGrid alignItem="center">
        <PageTitle>Daily Timesheet</PageTitle>
      </CommonGrid>
      <TimesheetFilter
        page="daily"
        getOrganizationMembersList={getOrganizationMembersList}
        organizationMembersList={orgMembersList}
        officesShortList={officesShortList}
        getOfficesShortList={getOfficesShortList}
        selectedOffice={selectedOffice}
        selectOffice={selectOffice}
        selectedMember={selectedMember}
        selectMember={selectMember}
        clearMember={clearMember}
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
      />
      <Container style={{ padding: `30px` }}>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {timesheetList && timesheetList.length > 0 && (
              <>
                <CalendarContainer>
                  <div></div>
                  <CommonGrid
                    justifyContent="start"
                    gap="0 40px"
                    columns="auto auto auto"
                  >
                    <ActivityTextSection>
                      <CommonText style={{ textAlign: `center` }}>
                        {moment(selectedDate).format('ddd')}
                      </CommonText>
                      <WhiteButton
                        cursor="auto"
                        height="45px"
                        width="45px"
                        padding="0"
                        borderColor="#20bead"
                        hoverBorderColor="#20bead"
                      >
                        {moment(selectedDate).format('DD')}
                      </WhiteButton>
                    </ActivityTextSection>
                    <UpperTotalHour>
                      <CommonText $label>Total Hours</CommonText>
                      <CommonText title>
                        {durations && durations.length > 0
                          ? getHours(
                              toHHMMSS(
                                // durations
                                //   .slice(1)
                                //   .reduce(
                                //     (prev, cur) =>
                                //       moment.duration(cur).add(prev),
                                //     moment.duration(durations[0])
                                //   )
                                //   .asMilliseconds() / 1000
                                durations
                                  .slice(1)
                                  .reduce(
                                    (prev, cur) => cur + prev,
                                    durations[0],
                                  ),
                              ),
                            )
                          : '0 hour 00 minute'}
                      </CommonText>
                    </UpperTotalHour>
                  </CommonGrid>
                </CalendarContainer>
                <CalendarContainer style={{ marginTop: `40px` }}>
                  {timeList.map(
                    (time, index) =>
                      timesheetList &&
                      timesheetList.length > 0 &&
                      index > timesheetList[0].start_time.hours() - 1 && (
                        <Fragment key={index}>
                          <CommonText margin="0">{time.time}</CommonText>
                          <TimeSlot
                            index={index}
                            startPosition={timesheetList[0].start_time.hours()}
                          ></TimeSlot>
                        </Fragment>
                      ),
                  )}
                  {timesheetList &&
                    timesheetList.length > 0 &&
                    timesheetList.map((card, index) => (
                      <Fragment key={index}>
                        {infoPopup === index && (
                          <TimesheetDetailsPopup
                            index={index}
                            data={card}
                            startPosition={timesheetList[0].start_time.hours()}
                            getDuration={getDuration}
                            setInfoPopup={setInfoPopup}
                          />
                        )}
                        <TimeSlotCard
                          key={index}
                          top={card.top}
                          height={card.height}
                          index={index}
                          startPosition={timesheetList[0].start_time.hours()}
                          small={
                            getDurationInMinutes(card.duration) < 20
                              ? true
                              : false
                          }
                        >
                          {getDurationInMinutes(card.duration) < 20 ? (
                            <TimeSlotSmallInfo
                              index={index}
                              top={parseInt(card.height / 2)}
                              onClick={() => toggleInfoPopup(index)}
                            >
                              <img src={Info} alt="" />
                            </TimeSlotSmallInfo>
                          ) : (
                            <TimeSlotInnerCard
                            // onMouseEnter={() => onHoverChange(index)}
                            // onMouseLeave={() => onHoverChange(-1)}
                            >
                              {/* <ActivityTextSection>
                                <CommonText name title>
                                  {card.project.name}
                                </CommonText>
                                {getDurationInMinutes(card.duration) > 40 && (
                                  <CommonText fontSize="12px" title>
                                    {card.task ? card.task.name : 'No task'}
                                  </CommonText>
                                )}
                              </ActivityTextSection> */}
                              <ActivityTextSection>
                                <CommonText name title>
                                  {card.start_time.format('h:mm A')}
                                </CommonText>
                                {getDurationInMinutes(card.duration) > 40 && (
                                  <CommonText fontSize="12px" title>
                                    Start Time
                                  </CommonText>
                                )}
                              </ActivityTextSection>
                              <ActivityTextSection>
                                <CommonText name title>
                                  {card.end_time
                                    ? card.end_time.format('h:mm A')
                                    : 'not clocked out'}
                                </CommonText>
                                {getDurationInMinutes(card.duration) > 40 && (
                                  <CommonText fontSize="12px" title>
                                    End Time
                                  </CommonText>
                                )}
                              </ActivityTextSection>
                              <ActivityTextSection>
                                <CommonText name title>
                                  {card.duration !== null
                                    ? getDuration(card.duration)
                                    : `Inaplicable`}
                                </CommonText>
                                {getDurationInMinutes(card.duration) > 40 && (
                                  <CommonText fontSize="12px" title>
                                    Total Time
                                  </CommonText>
                                )}
                              </ActivityTextSection>
                              {getDurationInMinutes(card.duration) <= 40 &&
                                getDurationInMinutes(card.duration) >= 20 && (
                                  <InfoButton
                                    index={index}
                                    onClick={() => toggleInfoPopup(index)}
                                  >
                                    <img src={Info} alt="" />
                                    <TimesheetTooltipContainer>
                                      <CommonTooltip
                                        tooltipText="Details"
                                        left="-50px"
                                        top="-46px"
                                        width="85px"
                                        height="25px"
                                      />
                                    </TimesheetTooltipContainer>
                                  </InfoButton>
                                )}
                              )
                            </TimeSlotInnerCard>
                          )}
                        </TimeSlotCard>
                      </Fragment>
                    ))}
                </CalendarContainer>
              </>
            )}
            {timesheetList && timesheetList.length === 0 && (
              <NoDataComponent title="There is no timesheet for this date!" />
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Daily;
