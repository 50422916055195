import { all, put, takeLatest } from "redux-saga/effects";

import {
  GET_CLOCK_IN_OUT_LIST,
  GET_CLOCK_IN_OUT_LIST_FAILURE,
  GET_CLOCK_IN_OUT_LIST_SUCCESS,
} from "../../modules/constants";
import { defaultApi } from "../../utils/axiosApi";

export function* getClockInOutList({ payload }) {
  const {
    start_timestamp,
    end_timestamp,
    name,
    page,
    office,
    employee_id,
  } = payload;
  try {
    const url = `albait/attendaces/?from=${start_timestamp}&to=${end_timestamp}${
      name ? `&name=${name}` : ``
    }${page ? `&page=${page}` : ``}${office ? `&office=${office}` : ``}${
      employee_id ? `&employee_id=${employee_id}` : ``
    }&platform=web`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_CLOCK_IN_OUT_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_CLOCK_IN_OUT_LIST_FAILURE,
      payload: err,
    });
  }
}

export default function* clockInOutSagas() {
  yield all([takeLatest(GET_CLOCK_IN_OUT_LIST, getClockInOutList)]);
}
