import moment from "moment-timezone";
import ReactDateRangePicker from "components/ReactDateRangePicker/ReactDateRangePicker";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import excelIcon from "../../assets/img/icons/excel-green.svg";
import CreateProjectDropdown from "../../components/DropdownStyle/CreateProjectDropdown";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import BottomPagination from "../../components/Pagination/BottomPagination/BottomPagination";
import TopPagination from "../../components/Pagination/TopPagination/TopPagination";
import Select from "../../components/ReactSelectDropdown";
import { filterOption } from "../../components/ReactSelectDropdown/ReactSelectDropdown";
import AddSingleMemberDropdown from '../../components/CustomDropdown/AddSingleMemberDropdown';
import SearchWithButton from "../../components/Search/SearchWithButton";
import DatePicker from "../../components/SingleDatePicker/SingleDatePicker";
import {
  ButtonIcon,
  ColoredButton,
  PrimaryButton,
  WhiteButtonWithIcon,
} from "../../styledComponents/buttons";
import {
  CommonFlex,
  FilterLabel,
  HeaderContainer,
  InputField,
  InputWithLabelSection,
  PageTitle,
} from "../../styledComponents/common";
import { TabLeftGrid } from "../../styledComponents/members";
import { exportToExcel } from "../../utils/exportToExcel";
import ClockInOutTable from "./ClockInOutTable";

const ClockInOut = (props) => {
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedDates, setSelectedDates] = useState({
    startDate: moment(new Date()),
    endDate: moment(new Date()),
  });
  const selectLastMonth = () => {
    setSelectedDates({
      startDate: moment().subtract(29, "days"),
      endDate: moment(),
    });
  };
  const selectLastWeek = () => {
    setSelectedDates({
      startDate: moment().subtract(7, "days"),
      endDate: moment(),
    });
  };
  const onDateApply = () => {
    onDateSelect(selectedDates);
  };

  const [selectedOffice, setSelectedOffice] = useState(null);
  const [officeOptions, setOfficeOptions] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState("");
  const [attendanceList, setAttendanceList] = useState([]);

  const [reportLoading, setReportLoading] = useState(false);
  const [allReportLoading, setAllReportLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [memberOptions, setMemberOptions] = useState([]);

  const { t } = useTranslation();

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    history,
    isLoading,
    getClockInOutList,
    clockInOutList,

    getOfficesShortList,
    officesShortList,

    getOrganizationMembersList,
    memberShortListIsLoading,
    organizationMembersList,

    // pagination
    clockInOutListCount,
    clockInOutPageSize,
  } = props;

  useEffect(() => {
    setCurrentPageNumber(1);
    getOfficesShortList();
    // getData(selectedDates);
  }, []);

  useEffect(() => {
    if (officesShortList && officesShortList.length > 0) {
      selectOffice({
        label: officesShortList[0].name,
        value: officesShortList[0].id,
      });
      let newArr = [];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      setOfficeOptions(newArr);
    }
  }, [officesShortList]);

  useEffect(() => {
    if (selectedOffice && selectedOffice.value) {
      getOrganizationMembersList({ office_id: selectedOffice.value });
    }
  }, [selectedOffice]);

  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  useEffect(() => {
    if (clockInOutList && clockInOutList.length > 0) {
      // console.log(clockInOutList);
      const newAttendances = [];
      clockInOutList.forEach((element) => {
        if (element.attendances && element.attendances.length > 1) {
          let newList = element.attendances.sort(
            (a, b) => a.timestamp - b.timestamp
          );

          let i = 0;
          while (i < newList.length) {
            const data = {
              employee: element.employee,
              attendance: {},
            };
            let iteratorValue = 1;
            if (newList[i].type === "clock_in") {
              data.attendance.clockIn = newList[i];
              if (newList[i + 1] && newList[i + 1].type === "clock_out") {
                data.attendance.clockOut = newList[i + 1];
                iteratorValue = 2;
              }
            } else if (newList[i].type === "clock_out") {
              data.attendance.clockOut = newList[i];
              data.attendance.clockIn = null;
            }

            newAttendances.push(data);
            i = i + iteratorValue;
          }
        } else if (element.attendances && element.attendances.length === 1) {
          const data = {
            employee: element.employee,
            attendance: {},
          };
          if (element.attendances[0].type === "clock_in") {
            data.attendance.clockIn = element.attendances[0];
            data.attendance.clockOut = null;
          } else if (element.attendances[0].type === "clock_out") {
            data.attendance.clockIn = null;
            data.attendance.clockOut = element.attendances[0];
          }
          newAttendances.push(data);
        }
      });
      setAttendanceList(newAttendances);
    }
  }, [clockInOutList]);

  const handleSearchByName = () => {
    let name = "";
    if (searchTerm && searchTerm.trim()) {
      name = searchTerm.toLocaleLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm("");
    }
    getData(selectedDates, name);
  };
  const handleClearSearch = () => {
    if (currentSearchedTerm) {
      getData(selectedDates);
    }
    setTimeout(() => {
      setSearchTerm("");
      setCurrentSearchedTerm("");
    }, 100);
  };

  const handlePageChange = (page) => {
    let name = null;
    if (currentSearchedTerm) {
      name = currentSearchedTerm.toLocaleLowerCase().trim();
    }
    getData(selectedDates, name, page);
    setCurrentPageNumber(page);
  };

  const getData = (dates, name, page, office_id, employee_id) => {
    const payload = {
      start_timestamp: moment(dates.startDate).startOf("day").unix(),
      end_timestamp: moment(dates.endDate).endOf("day").unix(),
    };
    if (name) {
      payload.name = name;
    }
    if (page) {
      payload.page = page;
    }
    if (office_id) {
      payload.office = office_id;
    }
    if(employee_id) {
      payload.employee_id = employee_id;
    }
    // get call
    getClockInOutList(payload);
  };

  const selectOffice = (e) => {
    getData(selectedDates, null, null, e.value);
    setSelectedOffice(e);
    setSelectedMember(null);
  };

  const onDateSelect = (date) => {
    getData(date, null, null, selectedOffice?.value, selectedMember?.id);
  };

  const selectMember = member => {
    getData(selectedDates, null, null, selectedOffice.value, member.id);
    setSelectedMember(member);
  };

  const clearSelectedMember = () => {
    setSelectedMember(null);
    getData(selectedDates, null, null, selectedOffice?.value);
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleExportToExcel = async () => {
    const url = `albait/export/attendances/?required_start_date=${moment(
      selectedDates.startDate
    ).unix()}&required_end_date=${moment(selectedDates.endDate).unix()}${
      selectedOffice?.value ? `&office=${selectedOffice.value}` : ""
    }`;

    setReportLoading(true);
    const response = await exportToExcel(url, null, true);
    setReportLoading(false);
  };

  const handleExportAllOfficesReportToExcel = useDebouncedCallback(
    async () => {
      const baseUrl = `albait/export/attendances/?required_start_date=${moment(
        selectedDates.startDate
      ).unix()}&required_end_date=${moment(selectedDates.endDate).unix()}`;

      if (officesShortList && officesShortList.length > 0) {
        setAllReportLoading(true);
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        const promises = [];
        officesShortList.forEach(async (office, i) => {
          const promise = (async (index) => {
            await delay(300 * index);
            const url = `${baseUrl}&office=${office.id}`;
            await exportToExcel(url, `office${office.name}.xlsx`, false);
          })(i);

          promises.push(promise);
        });

        await Promise.all(promises);

        setAllReportLoading(false);
      }
    },
    60000,
    { leading: true, trailing: false }
  );

  return (
    <div className="content">
      <PageTitle>{t("attendance")}</PageTitle>
      <HeaderContainer>
        <TabLeftGrid columns="240px auto auto">
          {/* <SearchWithButton
            itemName="Member"
            searchTermName={t("member_name")}
            searchInput={searchTerm}
            onInputChange={onSearchTermChange}
            handleSearch={handleSearchByName}
            handleClearSearch={handleClearSearch}
          /> */}
          {localStorage.getItem("user_role") === "admin" && (
            <InputWithLabelSection>
              <FilterLabel>{t("office")}</FilterLabel>

              <Select
                isSearchable
                value={selectedOffice}
                options={officeOptions}
                placeholder="Select Office..."
                onChange={(e) => {
                  selectOffice(e);
                }}
                styles={CreateProjectDropdown()}
                // clearable
                filterOption={filterOption}
              />
            </InputWithLabelSection>
          )}{" "}
          {/* <InputWithLabelSection>
            <FilterLabel>{t('date')}</FilterLabel>
            <DatePicker
              id="clockInOutDate"
              date={selectedDate}
              onDateChange={onDateSelect}
              dateDisplayFormat="DD/MM/YYYY"
              placeholder="Select date..."
              disableFutureDates
            />
          </InputWithLabelSection> */}
          <InputWithLabelSection>
            <FilterLabel>{t("date_range")}</FilterLabel>
            {/* <DatePicker
          id="createIssuedDate"
          date={selectedDate}
          onDateChange={handleDateChange}
          dateDisplayFormat={'DD/MM/YYYY'}
          placeholder={'Select date'}
          allowPreviousDates
        /> */}
            <ReactDateRangePicker
              startDate={
                selectedDates.startDate ? selectedDates.startDate : null
              }
              startDateId="custom-date-range-start-date"
              endDate={selectedDates.endDate ? selectedDates.endDate : null}
              endDateId="custom-date-range-start-date"
              onDatesChange={setSelectedDates}
              dateDisplayFormat="DD/MM/YYYY"
              onDateSelect={onDateApply}
              selectLastWeek={selectLastWeek}
              selectLastMonth={selectLastMonth}
            />
          </InputWithLabelSection>
          {localStorage.getItem("user_role") === "admin" && (
            <InputWithLabelSection>
              <FilterLabel>{t("assignee")}</FilterLabel>
              <div style={{ width: `40px` }}>
                <AddSingleMemberDropdown
                  assigneeManage={selectedMember}
                  updateState={selectMember}
                  membersList={memberOptions}
                  clearAssignee={() => clearSelectedMember()}
                />
              </div>
            </InputWithLabelSection>
          )}
        </TabLeftGrid>
        <CommonFlex gap="12px">
          {localStorage.getItem("user_role") === "admin" &&
          !localStorage.getItem("viewer_role") ? (
            <ColoredButton
              type="blue"
              onClick={() => handleExportAllOfficesReportToExcel()}
              padding={allReportLoading ? "0 45px" : "0 25px"}
            >
              {allReportLoading ? (
                <ButtonTextLoader color="#fff" loadingText=" " size={6} />
              ) : (
                <>{t("generate_all_report")}</>
              )}
            </ColoredButton>
          ) : null}
          {localStorage.getItem("user_role") !== "employee" ||
          localStorage.getItem("user_role") !== "supervisor" ? (
            <WhiteButtonWithIcon
              onClick={() => handleExportToExcel()}
              color="#19AC65"
              borderColor="#19AC65"
              hoverBorderColor="#129652"
              padding={reportLoading ? "0 45px" : "0 25px"}
            >
              {reportLoading ? (
                <ButtonTextLoader color="#19AC65" loadingText=" " size={6} />
              ) : (
                <>
                  <ButtonIcon src={excelIcon} size="26px" />
                  {t("export_report")}
                </>
              )}
            </WhiteButtonWithIcon>
          ) : null}
        </CommonFlex>
      </HeaderContainer>
      {clockInOutListCount && clockInOutListCount > 0 && clockInOutPageSize ? (
        <TopPagination
          itemName="members"
          totalCount={clockInOutListCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={clockInOutPageSize}
        />
      ) : null}
      <ClockInOutTable clockInOutList={clockInOutList} isLoading={isLoading} />
      {clockInOutListCount && clockInOutListCount > 0 && clockInOutPageSize ? (
        <BottomPagination
          totalCount={clockInOutListCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={clockInOutPageSize}
        />
      ) : null}
    </div>
  );
};

export default ClockInOut;
