import styled from "styled-components";
import { Container, CommonImage } from "../../styledComponents/common";

export const AttendanceTableContainer = styled(Container)`
  padding-bottom: 20px;
  @media (max-width: 1355px) {
    max-width: 100vw;
    overflow: auto;
  }
`;

export const ClockInOutTableHeader = styled.div`
  display: grid;
  grid-template-columns: 210px 120px 130px 100px 100px 100px 100px 100px;
  grid-column-gap: 15px;
  justify-content: start;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  @media (max-width: 1400px) {
    grid-template-columns:
      minmax(160px, 220px) 100px minmax(80px, 110px) 85px 80px 80px minmax(
        80px,
        100px
      )
      minmax(70px, 90px);
  }
`;

export const ClockInOutTableRow = styled.div`
  display: grid;
  grid-template-columns: 210px 120px 130px 100px 100px 100px 100px 100px;
  grid-column-gap: 15px;
  justify-content: start;
  padding: 0 20px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  @media (max-width: 1400px) {
    grid-template-columns:
      minmax(160px, 220px) 100px minmax(80px, 110px) 85px 80px 80px minmax(
        80px,
        100px
      )
      minmax(70px, 90px);
  }
`;
export const ClockInOutTableHeaderNew = styled.div`
  display: grid;
  grid-template-columns: 200px 300px;
  grid-column-gap: 20px;
  justify-content: start;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  /* @media (max-width: 1250px) {
    grid-template-columns: 250px 200px 200px 150px;
    grid-column-gap: 10px;
  } */
`;

export const SelfieImage = styled(CommonImage)`
  height: 60px;
  margin: -10px 0;
  cursor: pointer;
  &:hover {
    transform: scale(1.5);
    transition: transform 0.1s linear;
  }
`;
