import { connect } from 'react-redux';

import { firstLoginPasswordChangeRequest } from './loginActions';
import NewUserLogin from './newUserLogin';

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  firstLoginPasswordChangeRequest: details =>
    dispatch(firstLoginPasswordChangeRequest({ ...details })),
  loginRedirectReset: () => dispatch(loginRedirectReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUserLogin);
