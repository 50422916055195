import CreateProjectDropdown from 'components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from 'components/Loaders/ButtonTextLoader';
import ReactSelectDropdown from 'components/ReactSelectDropdown';
import { filterOption } from 'components/ReactSelectDropdown/ReactSelectDropdown';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { PrimaryButton, WhiteButtonWithIcon } from 'styledComponents/buttons';
import {
  CommonFlex,
  CommonText,
  InputField,
  InputLabel,
  InputWithLabelSection,
  RedStar,
} from 'styledComponents/common';
import { InputSection } from 'styledComponents/createProject';
import {
  UpdateRequestChangeLogTableContainer,
  UpdateUserRequestSidePanelComponentContainer,
  UpperTitleButtonSection,
} from 'styledComponents/members';
import { checkHtml, checkUrl, validateEmail } from 'utils/helper';

import { AddUserRequestButton } from '../UpdateUserStyles';

const CreateUserRequestForm = ({
  userDetails,
  officesShortList,
  createdUserId,
  createRequestLoading,
  closePanel,
  createUserRequest,
  rejectedUserId,
  rejectRequestLoading,
  handleRejectUserRequest,
}) => {
  console.log('🚀 ~ userDetails:', userDetails);
  const [memberId, setMemberId] = useState(null);
  const [selectedOffice, setSelectedOffice] = useState('');
  const [officeOptions, setOfficeOptions] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const roleList = [
    {
      label: 'Admin',
      value: 'admin',
    },
    {
      label: 'GM',
      value: 'gm',
    },
    {
      label: 'AGM',
      value: 'agm',
    },
    {
      label: 'Supervisor',
      value: 'supervisor',
    },
    {
      label: 'Employee',
      value: 'employee',
    },
  ];
  const [roleOptions, setRoleOptions] = useState([]);
  const [userCode, setUserCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [saveErrors, setSaveErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    let newOptions = [];
    let isMatched = false;
    roleList &&
      roleList.map((role, i) => {
        if (localStorage.getItem('user_role') === 'admin' || isMatched) {
          newOptions.push(role);
        }
        if (role.value === localStorage.getItem('user_role')) {
          isMatched = true;
        }
      });
    setRoleOptions(newOptions);
  }, []);

  useEffect(() => {
    if (userDetails) {
      // console.log(userDetails);
      userDetails.first_name && setFirstName(userDetails.first_name);
      userDetails.last_name && setLastName(userDetails.last_name);
      userDetails.office &&
        userDetails.office.id &&
        setSelectedOffice({
          label: userDetails.office.name,
          value: userDetails.office.id,
        });

      userDetails.email && setEmail(userDetails.email);
      userDetails.user_code && setUserCode(userDetails.user_code);
      userDetails.role &&
        roleOptions &&
        roleOptions.length > 1 &&
        roleOptions.map(role => {
          role.value === userDetails.role && setSelectedRole(role);
        });
    }
  }, [userDetails]);

  useEffect(() => {
    if (officesShortList && officesShortList.length === 1) {
      setSelectedOffice({
        label: officesShortList[0].name,
        value: officesShortList[0].id,
      });
    } else if (officesShortList && officesShortList.length > 0) {
      let newArr = [];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      setOfficeOptions(newArr);
    }
  }, [officesShortList]);

  useEffect(() => {
    if (roleOptions && roleOptions.length === 1) {
      setSelectedRole(roleOptions[0]);
    }
  }, [roleOptions]);

  useEffect(() => {
    // console.log(createdMemberInfo);
    if (createdUserId === userDetails?.id && isSubmitted) {
      closePanel();
    }
  }, [createdUserId]);

  useEffect(() => {
    // console.log(createdMemberInfo);
    if (rejectedUserId === userDetails?.id && isSubmitted) {
      closePanel();
    }
  }, [rejectedUserId]);

  const onOfficeChange = e => {
    setSelectedOffice(e);
  };

  const onFirstNameChange = e => {
    let errors = { ...saveErrors };
    delete errors['firstName'];
    setSaveErrors(errors);
    setFirstName(e.target.value);
  };

  const onLastNameChange = e => {
    let errors = { ...saveErrors };
    delete errors['lastName'];
    setSaveErrors(errors);
    setLastName(e.target.value);
  };

  const onEmailChange = e => {
    let errors = { ...saveErrors };
    delete errors['email'];
    setSaveErrors(errors);
    setEmail(e.target.value);
  };

  const onRoleChange = e => {
    setSelectedRole(e);
  };

  const onUserCodeChange = e => {
    let errors = { ...saveErrors };
    delete errors['userCode'];
    setSaveErrors(errors);
    setUserCode(e.target.value);
  };

  const handleSave = () => {
    // console.log('called');
    console.log(checkError());
    if (checkError()) {
      let payload = {
        first_name: firstName,
        last_name: lastName,
        office: selectedOffice.value,
        email: email.trim(),
        role: selectedRole.value,
        user_code: userCode,
        phone: phoneNumber,
      };
      payload.user_id = userDetails.id;
      console.log('🚀 ~ handleSave ~ payload:', payload);

      createUserRequest(payload);
    }
    setIsSubmitted(true);
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!firstName.trim()) {
      formIsValid = false;
      saveErrors['firstName'] = 'First name is required';
    } else if (checkUrl(firstName)) {
      formIsValid = false;
      saveErrors['firstName'] = 'First name can not contain url.';
    } else if (checkHtml(firstName)) {
      formIsValid = false;
      saveErrors['firstName'] = 'Invalid First name';
    } else if (firstName.length > 80) {
      formIsValid = false;
      saveErrors['firstName'] = 'Maximum length 80 characters';
    }

    if (checkUrl(lastName)) {
      formIsValid = false;
      saveErrors['lastName'] = 'Last name can not contain url.';
    } else if (checkHtml(lastName)) {
      formIsValid = false;
      saveErrors['lastName'] = 'Invalid Last name';
    } else if (lastName.length > 80) {
      formIsValid = false;
      saveErrors['lastName'] = 'Maximum length 80 characters';
    }

    if (!selectedOffice.value.trim()) {
      formIsValid = false;
      saveErrors['office'] = 'Office must be selected.';
    }

    if (!selectedRole.value.trim()) {
      formIsValid = false;
      saveErrors['role'] = 'Role must be selected.';
    }

    if (!email.trim()) {
      formIsValid = false;
      saveErrors['email'] = 'email is required';
    } else if (!validateEmail(email.trim())) {
      formIsValid = false;
      saveErrors['email'] = 'Please enter a valid email address';
    }

    if (!userCode.trim()) {
      formIsValid = false;
      saveErrors['userCode'] = 'User ID is required';
    } else if (checkUrl(userCode)) {
      formIsValid = false;
      saveErrors['userCode'] = 'User Code can not contain url.';
    } else if (checkHtml(userCode)) {
      formIsValid = false;
      saveErrors['userCode'] = 'Invalid User Code';
    } else if (userCode.length > 80) {
      formIsValid = false;
      saveErrors['userCode'] = 'Maximum length 80 characters';
    }
    // console.log(saveErrors);
    // console.log(formIsValid);
    setSaveErrors(saveErrors);
    return formIsValid;
  };
  return (
    <UpdateUserRequestSidePanelComponentContainer>
      <CommonFlex direction="column" alignItems="left" width="80%">
        <CommonText color="#000000" fontWeight="600" fontSize={'16px'}>
          {t('update_member_details')}
        </CommonText>
      </CommonFlex>
      <InputSection gap="15px" width="80%">
        <InputWithLabelSection>
          <InputLabel>
            {t('first_name')} <RedStar>*</RedStar>
          </InputLabel>
          <InputField
            type="text"
            placeholder={t('type_first_name')}
            value={firstName}
            onChange={e => onFirstNameChange(e)}
            style={
              saveErrors &&
              saveErrors[`firstName`] && {
                border: `1px solid #fe5969`,
                backgroundColor: `rgba(252, 87, 104, 0.05)`,
              }
            }
          />
          {saveErrors && saveErrors['firstName'] && (
            <FormAlert>{saveErrors['firstName']}</FormAlert>
          )}
        </InputWithLabelSection>

        <InputWithLabelSection>
          <InputLabel>{t('last_name')}</InputLabel>
          <InputField
            type="text"
            placeholder={t('type_last_name')}
            value={lastName}
            onChange={e => onLastNameChange(e)}
            style={
              saveErrors &&
              saveErrors[`lastName`] && {
                border: `1px solid #fe5969`,
                backgroundColor: `rgba(252, 87, 104, 0.05)`,
              }
            }
          />
          {saveErrors && saveErrors['lastName'] && (
            <FormAlert>{saveErrors['lastName']}</FormAlert>
          )}
        </InputWithLabelSection>

        <InputWithLabelSection>
          <InputLabel>
            {t('office')} <RedStar>*</RedStar>
          </InputLabel>
          <ReactSelectDropdown
            isSearchable
            value={selectedOffice}
            options={officeOptions}
            placeholder={t('select_office')}
            isDisabled={officesShortList && officesShortList.length === 1}
            onChange={e => {
              onOfficeChange(e);
            }}
            styles={CreateProjectDropdown()}
            filterOption={filterOption}
          />
          {saveErrors && saveErrors['office'] && (
            <FormAlert>{saveErrors['office']}</FormAlert>
          )}
        </InputWithLabelSection>

        <InputWithLabelSection>
          <InputLabel>
            {t('email')} <RedStar>*</RedStar>
          </InputLabel>
          <InputField
            type="email"
            placeholder={t('type_email')}
            value={email}
            onChange={e => onEmailChange(e)}
            style={
              saveErrors &&
              saveErrors[`email`] && {
                border: `1px solid #fe5969`,
                backgroundColor: `rgba(252, 87, 104, 0.05)`,
              }
            }
          />
          {saveErrors && saveErrors['email'] && (
            <FormAlert>{saveErrors['email']}</FormAlert>
          )}
        </InputWithLabelSection>

        <InputWithLabelSection>
          <InputLabel>
            {t('role')} <RedStar>*</RedStar>
          </InputLabel>
          <ReactSelectDropdown
            isSearchable
            value={selectedRole}
            options={roleOptions}
            placeholder={t('select_role')}
            isDisabled={roleOptions && roleOptions.length === 1}
            onChange={e => {
              onRoleChange(e);
            }}
            styles={CreateProjectDropdown()}
          />
          {saveErrors && saveErrors['role'] && (
            <FormAlert>{saveErrors['role']}</FormAlert>
          )}
        </InputWithLabelSection>

        <InputWithLabelSection>
          <InputLabel>
            {t('user_id')} <RedStar>*</RedStar>
          </InputLabel>
          <InputField
            type="text"
            placeholder={t('type_user_id')}
            value={userCode}
            onChange={e => onUserCodeChange(e)}
            style={
              saveErrors &&
              saveErrors[`userCode`] && {
                border: `1px solid #fe5969`,
                backgroundColor: `rgba(252, 87, 104, 0.05)`,
              }
            }
          />
          {saveErrors && saveErrors['userCode'] && (
            <FormAlert>{saveErrors['userCode']}</FormAlert>
          )}
        </InputWithLabelSection>

        <InputWithLabelSection>
          <InputLabel>{t('phone_number')}</InputLabel>
          <PhoneInput
            enableSearch
            country={'sa'}
            value={phoneNumber}
            onChange={phone => setPhoneNumber(phone)}
            inputClass="phone-number-input"
            buttonClass="phone-number-flag-dropdown"
          />
          {saveErrors && saveErrors['phoneNumber'] && (
            <FormAlert>{saveErrors['phoneNumber']}</FormAlert>
          )}
        </InputWithLabelSection>
      </InputSection>
      <UpperTitleButtonSection gap="10px" padding="0">
        <AddUserRequestButton
          type="reject"
          onClick={() => handleRejectUserRequest(userDetails)}
        >
          {rejectRequestLoading ? (
            <ButtonTextLoader loadingText={t('reject')} fontSize="13px" />
          ) : (
            t('reject')
          )}
        </AddUserRequestButton>{' '}
        <AddUserRequestButton
          disabled={
            !firstName ||
            !selectedOffice ||
            !email ||
            !selectedRole ||
            !userCode
          }
          type="approve"
          onClick={() =>
            !(
              !firstName ||
              !selectedOffice ||
              !email ||
              !selectedRole ||
              !userCode
            ) && handleSave()
          }
        >
          {createRequestLoading ? (
            <ButtonTextLoader loadingText={t('approve')} fontSize="13px" />
          ) : (
            t('approve')
          )}
        </AddUserRequestButton>
      </UpperTitleButtonSection>
    </UpdateUserRequestSidePanelComponentContainer>
  );
};

export default CreateUserRequestForm;
