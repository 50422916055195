import { connect } from "react-redux";

// Component
import TaskDetails from "./TaskDetails";
import {
  getOrganizationMembersList,
} from "../../Projects/projectsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationMembersList: (params) =>
    dispatch(getOrganizationMembersList(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
