import { t } from 'i18next';
import { Modal, ModalBody } from 'reactstrap';

import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import { ColoredButton, WhiteButton } from '../../../styledComponents/buttons';
import { CommonText } from '../../../styledComponents/common';
import { ModalButtonSection } from '../../../styledComponents/members';

const UserRequestApproveRejectModal = ({
  isOpen,
  toggle,
  action,
  actionFunction,
  isLoading,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `15px` }}>
          Are you sure you want to {t(action)} the user?
        </CommonText>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            {t('cancel')}
          </WhiteButton>
          <ColoredButton
            type={action === 'reject' ? 'delete' : 'blue'}
            padding={isLoading ? '0 15px' : '0 30px'}
            onClick={() => actionFunction()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText={t(action)} fontSize="13px" />
            ) : (
              t(action)
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default UserRequestApproveRejectModal;
