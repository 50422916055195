import { connect } from 'react-redux';

import { getOfficesShortList } from '../../Offices/officesActions';
import {
  deleteUserRequest,
  getUserRequests,
  rejectUserRequest,
} from '../updateUserActions';
import DeleteRequest from './DeleteRequest';

// Store props to map with current state
const mapStateToProps = state => ({
  isLoading: state.userUpdate.isLoading,
  isDeleting: state.userUpdate.isDeleting,
  rejectedUserId: state.userUpdate.rejectedUserId,
  rejectRequestLoading: state.userUpdate.rejectRequestLoading,
  deletedUserId: state.userUpdate.deletedUserId,
  deleteInvitationLoading: state.userUpdate.deleteInvitationLoading,
  deleteMemberLoading: state.userUpdate.deleteMemberLoading,
  selectedOrganization: state.organization.selectedOrganization,
  membersList: state.userUpdate.members,
  updatedData: state.userUpdate.updatedData,
  invitedMembersList: state.userUpdate.invitedMembersList,
  officesShortList: state.office.officesShortList,
  // pagination
  totalMembersCount: state.userUpdate.totalMembersCount,
  pageSize: state.userUpdate.pageSize,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getUserRequests: details => dispatch(getUserRequests({ ...details })),
  getOfficesShortList: details => dispatch(getOfficesShortList({ ...details })),
  deleteUserRequest: details => dispatch(deleteUserRequest({ ...details })),
  rejectUserRequest: details => dispatch(rejectUserRequest({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(DeleteRequest);
